import { useQuery } from "@tanstack/react-query"
import { GameLoader } from "components/GameLoader"
import MobileFriendlyModal from "components/MobileFriendlyModal"
import { useQueryFetcher, useSelectedCustomer } from "hooks"
import { useModalStore } from "hooks/store"
import { FormattedMessage } from "react-intl"
import { qFetchInterpretationPlot } from "utils/queries"

export const ForecastInterpretationModal = () => {
  const forecastDetailsModal = useModalStore(state => state.modals.forecastDetails)
  const hide = useModalStore(state => state.hide)
  const { fetch } = useQueryFetcher()
  const customer = useSelectedCustomer()

  const filter = {
    customer,
    historyId: forecastDetailsModal?.data?.history_id,
    itemId: forecastDetailsModal?.rowData?.item_id
  }

  const { data, status, isFetching, error } = useQuery({
    queryKey: ['forecast-interpretation', filter],
    queryFn: async () => {
      try {
        const data = await qFetchInterpretationPlot(fetch, filter)
        return data
      } catch (ex) {
        return Promise.reject(ex)
      }
    },
    enabled: forecastDetailsModal != null,
    retry: false,
    staleTime: 300000
  })

  const renderContent = () => {
    if (!forecastDetailsModal) return null
    return (
      <div className="h-[500px]">
        {(isFetching || status === 'error') && <GameLoader
          message={<FormattedMessage id='forecastInterpretation.loading' defaultMessage='Please wait while we collect the forecast details...' />}
          error={status === 'error' ? (
            error.includes('concurrency') ? 
              <FormattedMessage 
                id='forecastInterpretation.capacityError' 
                defaultMessage='The service is currently at full capacity. Please try again later.' 
              /> : 
              <FormattedMessage 
                id='forecastInterpretation.error' 
                defaultMessage='Forecast details could not be generated.' 
              />
          ) : null}
        />}
        {(!isFetching && data) && <iframe
          srcDoc={data}
          className="w-full h-full border-none"
          sandbox="allow-scripts allow-same-origin"
        />}
      </div>
    )
  }

  return <MobileFriendlyModal
    size='xxl'
    visible={forecastDetailsModal != null}
    onCancel={() => hide('forecastDetails')}
    title={<FormattedMessage
      id='forecastInterpretation.title'
      defaultMessage='Forecast Details: {location} | {item}'
      values={{ location: forecastDetailsModal?.locationName, item: forecastDetailsModal?.itemName }}
    />}
    noFooter
  >
    {renderContent()}
  </MobileFriendlyModal>
}
