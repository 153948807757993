import { Json } from 'components/Json'
import { NoData } from 'components/NoData'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from 'components/Button'
import { saveAs } from 'file-saver'
import { format } from 'date-fns'
import s from './ScriptResult.module.scss'
import messages from 'routes/operator/backendScripts/messages'
import labelMessages from 'components/labelMessages'

/**
 * Component to display script execution results
 */
export const ScriptResult = ({ task, scriptName, allowDownload = false }) => {
  const intl = useIntl()

  // Handle download of script results
  const handleDownload = () => {
    let ext, content, blob
    const type = task.content_type

    if (task.encoding === 'base64') {
      ext = 'bin'
      const decodedData = atob(task.result)

      const byteNumbers = new Array(decodedData.length)
      for (let i = 0; i < decodedData.length; i++) {
        byteNumbers[i] = decodedData.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      blob = new Blob([byteArray], { type })
    } else {
      switch (task.content_type) {
        case 'text/csv':
          ext = 'csv'
          content = task.result
          break
        case 'application/json':
          ext = 'json'
          content = JSON.stringify(task.result, null, 2)
          break
        default:
          ext = 'txt'
          content = task.result
          break
      }
      blob = new Blob([content], { type })
    }

    saveAs(blob, `${scriptName || 'script'}_${format(new Date(), "yyyy-MM-dd'_'HH-mm-ss")}.${ext}`)
  }

  const tryParseJSON = (jsonString) => {
    try {
      if (typeof jsonString === 'string' && (
        (jsonString.startsWith('{') && jsonString.endsWith('}')) ||
        (jsonString.startsWith('[') && jsonString.endsWith(']'))
      )) {
        return JSON.parse(jsonString)
      }
    } catch (e) {}
    return null
  }

  // Render the content of the script result
  const renderContent = () => {
    if (!task) return null

    if (task.result) {
      const length = JSON.stringify(task.result).length

      const parsedJSON = typeof task.result === 'string' ? tryParseJSON(task.result) : null

      return (
        <div className={s.resultContainer}>
          {task.encoding === 'base64'
            ? (
              <p className={s.infoText}>{intl.formatMessage(messages.binaryData)}</p>
              )
            : task.status !== 'success' || length < 1000
              ? (
                  typeof task.result === 'string'
                    ? parsedJSON
                        ? (
                          <Json>{parsedJSON}</Json>
                          )
                        : (
                          <textarea className={s.textArea} value={task.result} readOnly />
                          )
                    : (
                      <Json>{task.result}</Json>
                      )
                )
              : (
                <p className={s.infoText}>
                  <FormattedMessage {...messages.resultTooLarge} values={{ length: length - 2 }} />
                </p>
                )}

          {allowDownload && (
            <div className={s.downloadContainer}>
              <Button
                icon='Download'
                type='neutral'
                size='s'
                onClick={handleDownload}
                title={intl.formatMessage(labelMessages.download)}
              />
            </div>
          )}
        </div>
      )
    }

    return <NoData size='s' />
  }

  return renderContent()
}

export default ScriptResult
