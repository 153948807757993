import { DifferenceIndicator } from 'components/DifferenceIndicator'
import s from './CellRenderer.module.scss'
import { percentFormatter } from '../../../utils/formatter'
import { RadixPopover } from 'components/Primitives'
import { DeltaTooltip } from 'components/Report/Partials/DeltaTooltip'
import { getFormatterForReturnType } from 'components/Report/utils'
import { useIntl } from 'react-intl'
import globalMessages from 'components/globalMessages'
import classNames from 'classnames'
import { useRegionFormatPreset } from 'hooks/useRegionFormatPreset'

export const NumberWithComparisonValueRenderer = (props) => {
  const intl = useIntl()
  const regionPreset = useRegionFormatPreset()
  const { value, valueFormatted, data, colDef } = props
  if (value == null) return null

  const comparisonValue = colDef.field.startsWith('pivot')
    ? data[`${colDef.colId}_compare`]
    : data[`${colDef.field}_compare`]
  const diff = value - comparisonValue
  const realDelta = colDef.returnType === 'percent'
    ? diff
    : comparisonValue === 0
      ? null
      : Math.abs(diff / comparisonValue) * Math.sign(diff)
  const formatter = getFormatterForReturnType(colDef.returnType)

  // When we have data.compKey, we pass this as prevRangeParams since the data point is representing a single date
  const tooltipContent = (
    <DeltaTooltip
      difference={formatter({
        value: diff,
        colDef: {
          ...colDef,
          regionPreset
        }
      })}
      prevRangeParams={data.compKey ? [{ field: 'date', operator: 'range', value: [data.compKey, data.compKey] }] : colDef.prevRangeParams}
      prevValue={formatter({
        value: comparisonValue,
        colDef: {
          ...colDef,
          regionPreset,
          minimize: intl.formatMessage(globalMessages.millionSymbol)
        }
      })}
      isFormatted
      targetComparison={colDef.targetComparison}
      v2
    />
  )

  const inner = (
    <div className={classNames(s.flexRightCell, s.monoCell)}>
      {valueFormatted}
      {comparisonValue != null && realDelta != null && (
        <DifferenceIndicator
          className='ml-2'
          difference={realDelta}
          inverted={colDef.inverted}
          size='xs'
          formatter={(props) => percentFormatter({ ...props, colDef: { ...props.colDef, regionPreset } })}
        />
      )}
    </div>
  )
  return (
    <div className='w-full'>
      {comparisonValue != null && realDelta != null
        ? (
          <RadixPopover showOnHover triggerClassName='w-full' side='top' title={tooltipContent} data-cy='tooltip-to-hover'>
            {inner}
          </RadixPopover>
          )
        : inner}
    </div>
  )
}
