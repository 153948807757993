import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { useNotificationStore } from 'hooks/store/useNotificationStore'
import { FormattedMessage } from 'react-intl'
import { Notification } from './Notification'
import { NoData } from 'components/NoData'
import { useModalStore } from 'hooks/store'
import titleMessages from 'components/titleMessages'

export const NotificationDialog = () => {
  const notifications = useNotificationStore(state => state.notifications)
  const markAllAsRead = useNotificationStore(state => state.markAllAsRead)
  const clearNotifications = useNotificationStore(state => state.clearNotifications)
  const hide = useModalStore(state => state.hide)
  const notificationModal = useModalStore(state => state.modals.notifications)

  const handleMarkAllAsRead = () => {
    markAllAsRead()
  }

  return (
    <MobileFriendlyModal
      size='l'
      visible={notificationModal != null}
      onCancel={() => hide('notifications')}
      title={<FormattedMessage {...titleMessages.notifications} />}
      footer={{
        submitOptions: [
          {
            label: <FormattedMessage id='notifications.markAllAsRead' defaultMessage='Mark all as read' />,
            type: 'neutral',
            action: 'markAllAsRead',
            disabled: notifications.length === 0
          },
          {
            label: <FormattedMessage id='notifications.clearAll' defaultMessage='Clear all' />,
            type: 'danger',
            action: 'clearAll',
            disabled: notifications.length === 0
          }
        ],
        hideCancel: true
      }}
      onPrimary={(e, action) => {
        if (action === 'markAllAsRead') {
          handleMarkAllAsRead()
        } else if (action === 'clearAll') {
          clearNotifications()
        }
      }}
    >
      {notifications.length > 0
        ? (
          <>
            {notifications.map(notification => (
              <Notification key={notification.id} notification={notification} />
            ))}
          </>
          )
        : (
          <div className='py-8 text-center'>
            <NoData size='s' message={<FormattedMessage id='notifications.empty' defaultMessage='No notifications' />} />
          </div>
          )}
    </MobileFriendlyModal>
  )
}
