import { defineMessages } from 'react-intl'

export default defineMessages({
  templates: {
    id: 'chartConfigurator.templates',
    defaultMessage: 'Templates'
  },
  addReport: {
    id: 'chartConfigurator.addReport',
    defaultMessage: 'Add report'
  },
  report: {
    id: 'chartConfigurator.report',
    defaultMessage: 'Report'
  },
  saveReport: {
    id: 'chartConfigurator.saveReport',
    defaultMessage: 'Save report'
  },
  selectTemplate: {
    id: 'chartConfigurator.selectTemplate',
    defaultMessage: 'Select template'
  },
  templatesForReports: {
    id: 'chartConfigurator.templatesForReports',
    defaultMessage: 'Templates for reports'
  },
  chooseTemplate: {
    id: 'chartConfigurator.chooseTemplate',
    defaultMessage: 'Answer us the following questions so we can search for the best templates:'
  },
  showTemplates: {
    id: 'chartConfigurator.showTemplates',
    defaultMessage: 'Show templates'
  },
  howBreakdown: {
    id: 'chartConfigurator.howBreakdown',
    defaultMessage: 'Optional: Which properties do you want to group the metrics by?'
  },
  breakdownBy: {
    id: 'chartConfigurator.breakdownBy',
    defaultMessage: 'Break down by'
  },
  whatMetrics: {
    id: 'chartConfigurator.whatMetrics',
    defaultMessage: 'What metrics do you want to display?'
  },
  metric: {
    id: 'chartConfigurator.metric',
    defaultMessage: 'Metric'
  },
  metrics: {
    id: 'chartConfigurator.metrics',
    defaultMessage: 'Metrics'
  },
  whichVisualization: {
    id: 'chartConfigurator.whichVisualization',
    defaultMessage: 'How should the report be displayed?'
  },
  customisedReport: {
    id: 'chartConfigurator.customisedReport',
    defaultMessage: 'Customised report'
  },
  buildReport: {
    id: 'chartConfigurator.buildReport',
    defaultMessage: 'Here, you can build your own report with all your <i>Delicious</i> Data.'
  },
  createReport: {
    id: 'chartConfigurator.createReport',
    defaultMessage: 'Create your own report'
  },
  reportFilter: {
    id: 'chartConfigurator.reportFilter',
    defaultMessage: 'Report filter'
  },
  'summarized-area-with-comparison': {
    id: 'chartConfigurator.summarized-area-with-comparison',
    defaultMessage: 'Mini chart'
  },
  line: {
    id: 'chartConfigurator.line',
    defaultMessage: 'Line chart'
  },
  bar: {
    id: 'chartConfigurator.bar',
    defaultMessage: 'Bar chart'
  },
  pie: {
    id: 'chartConfigurator.pie',
    defaultMessage: 'Pie chart'
  },
  table: {
    id: 'chartConfigurator.table',
    defaultMessage: 'Table'
  },
  list: {
    id: 'chartConfigurator.list',
    defaultMessage: 'List'
  },
  text: {
    id: 'chartConfigurator.text',
    defaultMessage: 'Text'
  },
  reportType: {
    id: 'chartConfigurator.reportType',
    defaultMessage: 'Report type'
  },
  valuesLeft: {
    id: 'chartConfigurator.valuesLeft',
    defaultMessage: 'Metrics left y-axis'
  },
  valuesRight: {
    id: 'chartConfigurator.valuesRight',
    defaultMessage: 'Metrics right y-axis'
  },
  typeError: {
    id: 'chartConfigurator.typeError',
    defaultMessage: 'Choose the report type!'
  },
  valueError: {
    id: 'chartConfigurator.valueError',
    defaultMessage: 'Select the metric for the {chart}'
  },
  valuesError: {
    id: 'chartConfigurator.valuesError',
    defaultMessage: 'Select at least one metric for the {chart}'
  },
  breakdownError: {
    id: 'chartConfigurator.breakdownError',
    defaultMessage: 'Select how we should break down the {chart}'
  },
  filtersInfo: {
    id: 'chartConfigurator.filtersInfo',
    defaultMessage: 'The following dashboard filters also impact the results of the report:'
  },
  disallowedPercent: {
    id: 'chartConfigurator.disallowedPercent',
    defaultMessage: 'Disqualified: Percentage values disallowed'
  },
  disallowedGrouping: {
    id: 'chartConfigurator.disallowedGrouping',
    defaultMessage: 'Disqualified: Cannot be broken down by {grouping}'
  },
  disallowedAxis: {
    id: 'chartConfigurator.disallowedAxis',
    defaultMessage: 'Disqualified: Only one unit is permitted per axis'
  },
  disallowedReportType: {
    id: 'chartConfigurator.disallowedReportType',
    defaultMessage: 'Disqualified: Unsupported by report type'
  },
  rows: {
    id: 'chartConfigurator.rows',
    defaultMessage: 'Rows'
  },
  columns: {
    id: 'chartConfigurator.columns',
    defaultMessage: 'Columns'
  }
})
