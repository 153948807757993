import { defineMessages } from 'react-intl'

export default defineMessages({
  execute: {
    id: 'backendScripts.execute',
    defaultMessage: 'Execute'
  },
  arguments: {
    id: 'backendScripts.arguments',
    defaultMessage: 'Script Arguments'
  },
  scriptResult: {
    id: 'backendScripts.scriptResult',
    defaultMessage: 'Script Result'
  },
  showResult: {
    id: 'backendScripts.showResult',
    defaultMessage: 'Show Result'
  },
  showError: {
    id: 'backendScripts.showError',
    defaultMessage: 'Show Error'
  },
  downloadAsFile: {
    id: 'backendScripts.downloadAsFile',
    defaultMessage: 'Download as file'
  },
  resultTooLarge: {
    id: 'backendScripts.resultTooLarge',
    defaultMessage: 'The result is too large to be displayed. It has {length} characters. Download it as a file to see the result.'
  },
  binaryData: {
    id: 'backendScripts.binaryData',
    defaultMessage: 'The result is binary data and can\t be displayed. Download it as a file.'
  }
})
