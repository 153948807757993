import classNames from 'classnames'
import { Text } from '../Text'
import s from './Badge.module.scss'
import { Icon } from '../Icon'
import { find } from 'lodash'
import { ACCENT_COLORS_LIST } from 'constants/index'
import { pickTextColorBasedOnBgColor } from 'utils'
import { Tooltip } from '../Tooltip'

const TYPES = [
  'fbSuccess',
  'fbError',
  'fbWarning',
  'fbInfo',
  'accentNeutral',
  'accentCornflower',
  'accentGerbera',
  'accentMarigold',
  'accentNarcissus',
  'accentGras',
  'accentSpringstar',
  'accentLilac',
  'accentLavender',
  'accentPeachrose',
  'accentHydrangea',
  'accentRose'
]

const TYPE_ENUM = {
  fbSuccess: TYPES[0],
  fbError: TYPES[1],
  fbWarning: TYPES[2],
  fbInfo: TYPES[3],
  accentNeutral: TYPES[4],
  accentCornflower: TYPES[5],
  accentGerbera: TYPES[6],
  accentMarigold: TYPES[7],
  accentNarcissus: TYPES[8],
  accentGras: TYPES[9],
  accentSpringstar: TYPES[10],
  accentLilac: TYPES[11],
  accentLavender: TYPES[12],
  accentPeachrose: TYPES[13],
  accentHydrangea: TYPES[14],
  accentRose: TYPES[15]
}

const SIZES = ['xs', 's', 'm', 'l']

const getLabel = (badgeSize, isBold, children) => {
  switch (badgeSize) {
    case 'l':
      return isBold ? <Text className={s.text} type='headline' size='m'>{children}</Text> : <Text className={s.text} type='body' size='m'>{children}</Text>
    case 'm':
      return isBold ? <Text className={s.text} type='headline' size='s'>{children}</Text> : <Text className={s.text} type='body' size='s'>{children}</Text>
    case 's':
      return isBold ? <Text className={s.text} type='headline' size='xs'>{children}</Text> : <Text className={s.text} type='body' size='s'>{children}</Text>
    case 'xs':
      return isBold ? <Text className={s.text} type='headline' size='xs'>{children}</Text> : <Text className={s.text} type='body' size='xs'>{children}</Text>
  }
}

const getIconSize = (badgeSize) => {
  switch (badgeSize) {
    case 'l':
    case 'm':
    case 's':
      return 'xs'
    case 'xs':
      return 'xxs'
  }
}

export const Badge = ({ children, childrenAsComponent, type = 'accentNeutral', size = 'm', iconPosition = 'left', outline, bold, icon, className, id, hex, tooltip, light, tooltipSide, withAppendix, wrapped, ...props }) => {
  if (!hex && !TYPES.includes(type)) {
    throw new Error(`Invalid type: ${type}`)
  }
  if (!SIZES.includes(size)) {
    throw new Error(`Invalid size: ${size}`)
  }

  const renderIcon = () => {
    return <Icon className={s.icon} size={getIconSize(size)} name={icon} />
  }

  let fontColor
  if (hex) {
    const accentColor = find(ACCENT_COLORS_LIST, { bg: hex })
    fontColor = accentColor ? accentColor.fg : pickTextColorBasedOnBgColor(hex)
  }

  const Component = (
    <div
      data-type='badge'
      data-badge-type={type}
      data-id={id}
      style={hex ? { backgroundColor: hex, color: fontColor } : undefined}
      className={classNames(s.root, s[size], withAppendix ? 'rounded-tl rounded-bl' : 'rounded', hex ? null : s[type], {
        [s.outline]: outline,
        [s.reversed]: iconPosition === 'right',
        [s.textOnly]: !icon,
        [s.withIcon]: icon != null,
        [s.light]: light
      }, className)}
      {...props}
    >
      {icon && renderIcon()}
      {childrenAsComponent ? children : getLabel(size, bold, children)}
    </div>
  )

  return tooltip
    ? <Tooltip side={tooltipSide || 'right'} title={tooltip}>{Component}</Tooltip>
    : wrapped ? <div className='inline-block'>{Component}</div> : Component
}

Badge.TYPES = TYPE_ENUM
