import { useDebounceEffect } from 'ahooks'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { ForecastInterpretationModal } from 'components/ForecastInterpretationModal'
import globalMessages from 'components/globalMessages'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import { useIntl } from 'react-intl'

export const ModalContainer = () => {
  const intl = useIntl()
  const confirmationDialog = useGeneralStore((state) => state.confirmationDialog)
  const setConfirmationDialog = useGeneralStore((state) => state.setConfirmationDialog)
  const showConfirmationDialog = useGeneralStore((state) => state.showConfirmationDialog)
  const setShowConfirmationDialog = useGeneralStore((state) => state.setShowConfirmationDialog)

  useDebounceEffect(() => {
    if (!showConfirmationDialog) {
      setConfirmationDialog(null)
    }
  }, [showConfirmationDialog], { wait: 1000 })

  return (<>
  <ConfirmationDialog
      visible={showConfirmationDialog}
      noUndoHint={confirmationDialog ? confirmationDialog.noUndoHint : false}
      title={confirmationDialog ? confirmationDialog.title : ''}
      onConfirm={confirmationDialog ? confirmationDialog.onConfirm : () => setConfirmationDialog(null)}
      onCancel={() => setShowConfirmationDialog(false)}
      confirmButtonText={intl.formatMessage(globalMessages.yes)}
    >
      {confirmationDialog && confirmationDialog.children}
    </ConfirmationDialog>
    <ForecastInterpretationModal />
  </>
  )
}
