import classNames from 'classnames'
import { isEmpty } from 'utils'
import s from './Button.module.scss'
import { PulseLoader } from 'react-spinners'
import { Text } from 'components/Primitives/Text'
import { Icon, Tooltip } from 'components/Primitives'
import React from 'react'

const getLabelSize = (buttonSize, type) => {
  switch (buttonSize) {
    case 'xl':
      return 'l'
    case 'l':
      return type === 'tertiary' ? 'l' : 'm'
    case 'm':
      return 'm'
    case 's':
      return 's'
    case 'xs':
      return 'xs'
  }
}

const getIconSize = (buttonSize, iconOnly, type) => {
  if (type === 'tertiary') {
    switch (buttonSize) {
      case 'xl':
      case 'l':
        return 'l'
      case 'm':
        return 's'
      case 's':
        return 'xs'
      case 'xs':
        return 'xxs'
    }
  }

  if (iconOnly) {
    switch (buttonSize) {
      case 'xl':
      case 'l':
        return 'l'
      case 'm':
        return 'm'
      case 's':
        return 's'
      case 'xs':
        return 'xxs'
    }
  }
  switch (buttonSize) {
    case 'xl':
    case 'l':
    case 'm':
      return 'm'
    case 's':
      return 's'
    case 'xs':
      return 'xxs'
  }
}

/**
 * This button implements the button styles from Figma Design System.
 * Icon only variant is not yet finished.
 * See: https://www.figma.com/file/HMuWPTtnOHOPMiUYHdw77K/%F0%9F%8E%A8-DD-Design-System?node-id=2235%3A8768&t=RDFfVrA1m4rCn7x9-0
 **/
export const Button = React.forwardRef(({ size = 'm', type, children, onClick, icon, iconPosition = 'left', className, pure, disabled, loading, formSubmit, minWidth, title, titlePlacement, fullWidth, whiteHoverBg, labelTagName, labelClassName, ...rest }, forwardedRef) => {
  const iconOnly = isEmpty(children)
  // TODO: Make primary/secondary as props and type can be default or danger

  const renderIcon = () => (
    <Icon
      size={getIconSize(size, iconOnly, type)}
      name={icon} className={classNames(s.icon)}
    />
  )

  const button = (
    <button
      type={formSubmit ? 'submit' : 'button'}
      className={classNames(
        s.root,
        {
          'min-w-11.5': minWidth,
          'w-full justify-center': fullWidth,
          [s.pure]: type !== 'tertiary' && pure,
          [s.outlined]: type !== 'tertiary' && !pure,
          [s.primary]: type === 'primary' || !type,
          [s.secondary]: type === 'secondary',
          [s.neutral]: type === 'neutral',
          [s.tertiary]: type === 'tertiary',
          [s.danger]: type === 'danger',
          [s.link]: type === 'link',
          [s.dangerSecondary]: type === 'danger-secondary',
          [s.dangerLink]: type === 'danger-link',
          [s.disabled]: disabled,
          [s.loading]: loading,
          [s.iconOnly]: iconOnly,
          [s.withText]: type !== 'tertiary' && !iconOnly,
          [s.reversed]: iconPosition === 'right',
          [s.withIcon]: icon != null,
          [s.textOnly]: !icon,
          [s.whiteHoverBg]: whiteHoverBg
        },
        s[size],
        className
      )}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled || loading}
      data-icon={icon}
      {...rest}
      ref={forwardedRef}
    >
      {loading && !iconOnly && type !== 'tertiary' && <div className={s.buttonLoader}><PulseLoader size={8} color='#fff' loading speedMultiplier={0.5} /></div>}
      {icon && renderIcon()}
      <Text tagName={labelTagName} className={classNames(s.text, labelClassName)} type='buttonLabel' size={getLabelSize(size)} color='white'>{children}</Text>
    </button>
  )

  return title
    ? <Tooltip triggerAsChild title={title} side={titlePlacement}>{button}</Tooltip>
    : button
})
