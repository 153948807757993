import { defineMessages } from 'react-intl'

export default defineMessages({
  cancel: {
    id: 'global.cancel',
    defaultMessage: 'Cancel'
  },
  ok: {
    id: 'global.ok',
    defaultMessage: 'OK'
  },
  remove: {
    id: 'global.remove',
    defaultMessage: 'Delete'
  },
  saveChanges: {
    id: 'global.saveChanges',
    defaultMessage: 'Save changes'
  },
  edit: {
    id: 'global.edit',
    defaultMessage: 'Edit'
  },
  duplicate: {
    id: 'global.duplicate',
    defaultMessage: 'Duplicate'
  },
  delete: {
    id: 'global.delete',
    defaultMessage: 'Delete'
  },
  save: {
    id: 'global.save',
    defaultMessage: 'Save'
  },
  reset: {
    id: 'global.reset',
    defaultMessage: 'Reset'
  },
  resetAll: {
    id: 'global.resetAll',
    defaultMessage: 'Reset All'
  },
  resetAllFilters: {
    id: 'global.resetAllFilters',
    defaultMessage: 'Reset all filters'
  },
  resetSelection: {
    id: 'global.resetSelection',
    defaultMessage: 'Reset selection'
  },
  continue: {
    id: 'global.continue',
    defaultMessage: 'Continue'
  },
  today: {
    id: 'global.today',
    defaultMessage: 'Today'
  },
  tomorrow: {
    id: 'global.tomorrow',
    defaultMessage: 'Tomorrow'
  },
  dayAfterTomorrow: {
    id: 'global.dayAfterTomorrow',
    defaultMessage: 'Day after tomorrow'
  },
  yesterday: {
    id: 'global.yesterday',
    defaultMessage: 'Yesterday'
  },
  defaultError: {
    id: 'global.defaultError',
    defaultMessage: 'Something went wrong. Please try again.'
  },
  back: {
    id: 'global.back',
    defaultMessage: 'Back'
  },
  logout: {
    id: 'global.logout',
    defaultMessage: 'Logout'
  },
  weekNumber: {
    id: 'global.weekNumber',
    defaultMessage: 'WN'
  },
  bulkEdit: {
    id: 'global.bulkEdit',
    defaultMessage: 'Bulk Editing'
  },
  bulkEditCount: {
    id: 'global.bulkEditCount',
    defaultMessage: 'Bulk Editing ({count} selected)'
  },
  keepExistingValue: {
    id: 'global.keepExistingValue',
    defaultMessage: 'Keep existing value'
  },
  valueDeleted: {
    id: 'global.valueDeleted',
    defaultMessage: 'Existing value is deleted'
  },
  step: {
    id: 'global.step',
    defaultMessage: 'Step'
  },
  selectionCount: {
    id: 'global.selectionCount',
    defaultMessage: '{count} selected'
  },
  accessSafetySettings: {
    id: 'global.accessSafetySettings',
    defaultMessage: 'Safety Settings'
  },
  accessFoodwaste: {
    id: 'userform.accessFoodwaste',
    defaultMessage: 'Food Waste Monitoring'
  },
  accessOfferingView: {
    id: 'userform.accessOfferingView',
    defaultMessage: 'Offering View'
  },
  accessRights: {
    id: 'global.accessRights',
    defaultMessage: 'Access Rights'
  },
  roleUser: {
    id: 'userform.roleUser',
    defaultMessage: 'User'
  },
  roleAdmin: {
    id: 'userform.roleAdmin',
    defaultMessage: 'Admin'
  },
  roleOperator: {
    id: 'userform.roleOperator',
    defaultMessage: 'Operator'
  },
  yes: {
    id: 'global.yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'global.no',
    defaultMessage: 'No'
  },
  close: {
    id: 'global.close',
    defaultMessage: 'Close'
  },
  active: {
    id: 'global.active',
    defaultMessage: 'Active'
  },
  inactive: {
    id: 'global.inactive',
    defaultMessage: 'Inactive'
  },
  open: {
    id: 'global.open',
    defaultMessage: 'Open'
  },
  done: {
    id: 'global.done',
    defaultMessage: 'Done'
  },
  sessionRevoked: {
    id: 'global.sessionRevoked',
    defaultMessage: 'Successfully logged out of the device.'
  },
  allSessionsRevoked: {
    id: 'global.allSessionsRevoked',
    defaultMessage: 'You have been logged out from all other devices.'
  },
  actionStarted: {
    id: 'global.actionStarted',
    defaultMessage: 'Action started. Please wait...'
  },
  actionSucceededDefault: {
    id: 'global.actionSucceededDefault',
    defaultMessage: 'Action succeeded.'
  },
  actionSucceeded: {
    id: 'global.actionSucceeded',
    defaultMessage: 'Action succeeded. Message: {message}'
  },
  actionFailed: {
    id: 'global.actionFailed',
    defaultMessage: 'Action failed with status {status}. Message: {message}'
  },
  exportFailed: {
    id: 'global.exportFailed',
    defaultMessage: 'Exporting failed. Please try again.'
  },
  changesSaved: {
    id: 'global.changesSaved',
    defaultMessage: 'Changes have been saved.'
  },
  other: {
    id: 'global.other',
    defaultMessage: 'Other'
  },
  ungrouped: {
    id: 'global.ungrouped',
    defaultMessage: 'Ungrouped'
  },
  unspecified: {
    id: 'global.unspecified',
    defaultMessage: 'Unspecified'
  },
  allCategories: {
    id: 'global.allCategories',
    defaultMessage: 'All Categories'
  },
  tableItems: {
    id: 'global.tableItems',
    defaultMessage: '{range0}-{range1} of {total}'
  },
  shownItems: {
    id: 'global.shownItems',
    defaultMessage: '{shown} of {total}'
  },
  page: {
    id: 'global.page',
    defaultMessage: 'Page {current} of {total}'
  },
  pageSize: {
    id: 'global.pageSize',
    defaultMessage: '/ Page'
  },
  search: {
    id: 'global.search',
    defaultMessage: 'Search'
  },
  searchArticle: {
    id: 'global.searchArticle',
    defaultMessage: 'Search Article'
  },
  searchForId: {
    id: 'global.searchForId',
    defaultMessage: 'Search for ID'
  },
  searchForNameOrId: {
    id: 'global.searchForNameOrId',
    defaultMessage: 'Search for Name or ID'
  },
  actions: {
    id: 'global.actions',
    defaultMessage: 'Actions'
  },
  addRow: {
    id: 'global.addRow',
    defaultMessage: 'Add row'
  },
  noUndo: {
    id: 'global.noUndo',
    defaultMessage: 'This action can\'t be undone.'
  },
  noGroups: {
    id: 'global.noGroups',
    defaultMessage: 'No groups available.'
  },
  select: {
    id: 'global.select',
    defaultMessage: 'Select'
  },
  selectAll: {
    id: 'global.selectAll',
    defaultMessage: 'Select all'
  },
  deSelectAll: {
    id: 'global.deSelectAll',
    defaultMessage: 'Deselect all'
  },
  noData: {
    id: 'global.noData',
    defaultMessage: 'No data'
  },
  upload: {
    id: 'global.upload',
    defaultMessage: 'Upload'
  },
  deleted: {
    id: 'global.deleted',
    defaultMessage: 'Deleted'
  },
  selectedOptionIsSet: {
    id: 'global.selectedOptionIsSet',
    defaultMessage: 'The selected option(s) will be set'
  },
  eventPromotion: {
    id: 'global.eventPromotion',
    defaultMessage: 'Promotion'
  },
  eventSemesterBreak: {
    id: 'global.eventSemesterBreak',
    defaultMessage: 'Semester Break'
  },
  eventOther: {
    id: 'global.eventOther',
    defaultMessage: 'Other Event'
  },
  nextStep: {
    id: 'global.nextStep',
    defaultMessage: 'Next Step'
  },
  nullOption: {
    id: 'global.nullOption',
    defaultMessage: '-'
  },
  pleaseWait: {
    id: 'global.pleaseWait',
    defaultMessage: 'Please wait...'
  },
  invalidDate: {
    id: 'global.invalidDate',
    defaultMessage: 'Invalid date'
  },
  unknownTime: {
    id: 'global.unknownTime',
    defaultMessage: '?'
  },
  tagNotFound: {
    id: 'global.tagNotFound',
    defaultMessage: 'Assigned tag was not found.'
  },
  qualified: {
    id: 'global.qualified',
    defaultMessage: 'Qualified'
  },
  areYouSure: {
    id: 'global.areYouSure',
    defaultMessage: 'Are you sure?'
  },
  invalidComparingPeriod: {
    id: 'global.invalidComparingPeriod',
    defaultMessage: 'The end date of the comparison period has to lie before the start date of the time range'
  },
  automatic: {
    id: 'global.automatic',
    defaultMessage: 'Automatic'
  },
  millionSymbol: {
    id: 'global.millionSymbol',
    defaultMessage: 'M'
  }
})
