import { useCustomerSettings, useLocations } from 'hooks'
import { find } from 'lodash'
import { useMemo } from 'react'
import { getNameWithParantheses } from 'utils'

export const SalesLocation = ({ id }) => {
  const customerSettings = useCustomerSettings()
  const { data, isLoading } = useLocations({ includeDisabled: true, enabled: id != null })

  const foundItem = useMemo(() => {
    if (!data) return null
    return find(data, { id })
  }, [data, id])

  if (id != null && isLoading) {
    return '...'
  }

  if (!data) {
    return null
  }

  return foundItem
    ? getNameWithParantheses('location', foundItem, customerSettings)
    : null
}
