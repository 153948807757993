import { FormattedMessage, useIntl } from 'react-intl'

import { ConfirmationDialog } from 'components/ConfirmationDialog'
import globalMessages from 'components/globalMessages'
import { useAdminData } from 'hooks/queries'
import { useMemo } from 'react'
import OrderConfigTable from 'components/OrderConfigTable'
import { groupOrderConfigs, groupTodoConfigs } from 'utils'
import Heading from 'components/Heading'
import labelMessages from 'components/labelMessages'
import { TodoConfigTable } from 'components/TodoConfigTable'

const AssignedRulesOverview = ({ assignedRules }) => {
  const intl = useIntl()
  const hasOrderConfigAssignments = assignedRules.orderConfigs.count > 0
  const hasTodoConfigAssignments = assignedRules.todoConfigs.count > 0
  return (
    <>
      {hasOrderConfigAssignments && (
        <div className='my-6'>
          <Heading type='h4'>{intl.formatMessage(labelMessages.orderConfig)}</Heading>
          {Object.keys(assignedRules.orderConfigs.grouped).map((key) => {
            const rules = assignedRules.orderConfigs.grouped[key]
            if (rules.length === 0) return null
            return (
              <OrderConfigTable tiny key={key} items={rules} tableType={key} />
            )
          })}
        </div>
      )}
      {hasTodoConfigAssignments && (
        <div className='my-6'>
          <Heading type='h4'>{intl.formatMessage(labelMessages.todoConfig)}</Heading>
          {Object.keys(assignedRules.todoConfigs.grouped).map((key) => {
            const rules = assignedRules.todoConfigs.grouped[key]
            if (rules.length === 0) return null
            return (
              <TodoConfigTable tiny key={key} items={rules} tableType={key} />
            )
          })}
        </div>
      )}
    </>
  )
}

const getAssignedRulesMessage = (assignedRules) => {
  const hasOrderConfigAssignments = assignedRules.orderConfigs.count > 0
  const hasTodoConfigAssignments = assignedRules.todoConfigs.count > 0
  return hasOrderConfigAssignments && hasTodoConfigAssignments
    ? (
      <FormattedMessage
        id='tags.deleteConfirmationAssignmentsBoth'
        defaultMessage='It is assigned to {orderConfigCount} {orderConfigCount, plural, one {order config} other {order configs}} and {todoConfigCount} {todoConfigCount, plural, one {todo config} other {todo configs}} and {count, plural, one {it will} other {they will}} also be deleted.'
        values={{
          orderConfigCount: assignedRules.orderConfigs.count,
          todoConfigCount: assignedRules.todoConfigs.count,
          count: assignedRules.orderConfigs.count + assignedRules.todoConfigs.count
        }}
      />
      )
    : hasOrderConfigAssignments
      ? (
        <FormattedMessage
          id='tags.deleteConfirmationAssignmentsOrder'
          defaultMessage='It is assigned to {orderConfigCount} {orderConfigCount, plural, one {order config} other {order configs}} and {orderConfigCount, plural, one {it will} other {they will}} also be deleted.'
          values={{
            orderConfigCount: assignedRules.orderConfigs.count
          }}
        />
        )
      : (
        <FormattedMessage
          id='tags.deleteConfirmationAssignmentsTodo'
          defaultMessage='It is assigned to {todoConfigCount} {todoConfigCount, plural, one {todo config} other {todo configs}} and {todoConfigCount, plural, one {it will} other {they will}} also be deleted.'
          values={{
            todoConfigCount: assignedRules.todoConfigs.count
          }}
        />
        )
}

export const DeleteConfirmation = ({ tag, visible, onCancel, onConfirm, loading }) => {
  const intl = useIntl()

  // the filter logic for configs is special and we can't reall filter of item_tags OR location_tags in one request, so we just fetch them all, since we have to manually filter them anyway
  const { data: orderConfigData } = useAdminData('order-configs', { enabled: visible, filters: { page: 1, pageSize: 999, ordering: '-rank' } })
  const { data: todoConfigData } = useAdminData('todo-configs', { enabled: visible, filters: { page: 1, pageSize: 999, ordering: '-rank' } })

  const isLoading = (orderConfigData && orderConfigData.isLoading) || (todoConfigData && todoConfigData.isLoading)

  const assignedRules = useMemo(() => {
    if (!tag || !orderConfigData || !orderConfigData.items || !todoConfigData || !todoConfigData.items) {
      return null
    }
    const assignedOrderRules = orderConfigData.items.filter((rule) => rule.item_tag === tag.id || rule.location_tag === tag.id)
    const assignedTodoRules = todoConfigData.items.filter((rule) => rule.item_tag === tag.id || rule.location_tag === tag.id)

    return {
      orderConfigs: {
        count: assignedOrderRules.length,
        grouped: groupOrderConfigs(assignedOrderRules)
      },
      todoConfigs: {
        count: assignedTodoRules.length,
        grouped: groupTodoConfigs(assignedTodoRules)
      }
    }
  }, [tag, orderConfigData.items, todoConfigData.items])

  const hasAssignments = assignedRules && (assignedRules.orderConfigs.count > 0 || assignedRules.todoConfigs.count > 0)

  return (
    <ConfirmationDialog
      visible={visible}
      title={intl.formatMessage(globalMessages.remove)}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonText={intl.formatMessage(globalMessages.remove)}
      confirmLoading={isLoading || loading}
      size={hasAssignments ? 'xxl' : null}
      innerChildren={hasAssignments && (
        <AssignedRulesOverview assignedRules={assignedRules} />
      )}
    >
      <FormattedMessage
        id='tags.deleteConfirmation'
        defaultMessage='Are you sure you want to delete the group "{name}" and remove it from all assignments?'
        values={{ name: tag && tag.name }}
      />
      {hasAssignments && <>{' '}{getAssignedRulesMessage(assignedRules)}</>}
      {' '}<FormattedMessage {...globalMessages.noUndo} />
      <br /><br />
      <FormattedMessage
        id='tags.deleteConfirmationOverview'
        defaultMessage='The following configurations are also deleted:'
      />
    </ConfirmationDialog>
  )
}
