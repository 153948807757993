import { useMemo, useState } from 'react'
import { omit, kebabCase, sortedUniq, flatten, orderBy } from 'lodash'
import { useAdminData } from 'hooks/queries'
import { useIntl } from 'react-intl'
import RemoveConfigModal from './RemoveConfigModal'
import labelMessages from 'components/labelMessages'
import { CUSTOM_RANGE } from 'constants/index'
import { useFilterSetter, useIsDesktop, usePageSettings } from 'hooks'
import { ConfigTableHeader } from 'components/ConfigTableHeader'
import { getColumns } from 'components/ConfigFieldsAndColumns/Columns'
import { Grid, useGridSelectionStore } from 'components/Grid'
import { useModalStore } from 'hooks/store'
import { useGridSettingsHandler } from 'components/Grid/hooks/useGridSettingsHandler'
import { getConfigTitle } from 'utils'
import { trackEvent } from 'components/AppCore/Amplitude'
import { ConfigForm } from 'components/ConfigForm'

const getColumnKeysByTableType = (tableType) => {
  const baseColumns = ['rank', 'itemOrGroup', 'locationOrGroup', 'dateRange', 'timeRange', 'weekdays']
  switch (tableType) {
    case 'totalQuantile':
    default:
      return [...baseColumns, 'daily_quantile']
    case 'initialTodos':
      return [...baseColumns, 'scheduled_due_by', 'scheduled_last_until']
    case 'scheduling':
      return [...baseColumns, 'lead_quantile', 'lead_interval', 'sustain_quantile', 'sustain_interval']
    case 'inventoryGroup':
      return [...baseColumns, 'inventory_group_enabled']
  }
}

export const TodoConfigTable = ({ tableType, onRefinedRow, loading, items: propsItems, tiny = false, selectionKey }) => {
  const intl = useIntl()
  const setPageFilters = useFilterSetter('todoConfigs')
  const { settings } = usePageSettings()
  const filters = settings.todoConfigs
  const isDesktop = useIsDesktop()

  const { add, update, bulkUpdate, remove } = useAdminData('todo-configs', { enabled: false, filters: {} })
  const { data: locationTagData } = useAdminData('tags', { type: 'saleslocation' })
  const { data: itemTagData } = useAdminData('tags', { type: 'item' })

  const { settings: gridSettings, ordering, gridProps } = useGridSettingsHandler({
    id: `admin-todo-configs-${kebabCase(tableType)}`,
    defaultSort: [{ key: 'rank', desc: true }],
    pageResetFilters: filters
  })

  const items = useMemo(() => {
    if (!propsItems) return []

    const items = orderBy(propsItems, ['rank', 'id'], [ordering.startsWith('-') ? 'desc' : 'asc', ordering.startsWith('-') ? 'asc' : 'desc'])

    if (gridSettings.pagination) {
      return items.slice((gridSettings.pagination.current - 1) * gridSettings.pagination.pageSize, gridSettings.pagination.current * gridSettings.pagination.pageSize)
    }

    return items
  }, [propsItems, gridSettings, ordering])

  const [editRecord, setEditRecord] = useState(null)
  const [duplicating, setDuplicating] = useState(false)
  const [bulkEditing, setBulkEditing] = useState(null)
  const [removeItem, setRemoveItem] = useState(null)

  const reset = useGridSelectionStore((state) => state.reset)
  const selection = useGridSelectionStore((state) => state.selection[selectionKey]) || []

  const modalName = `todoConfigForm_${tableType}`
  const show = useModalStore(state => state.show)
  const hide = useModalStore(state => state.hide)
  const modal = useModalStore(state => state.modals[modalName])

  const refineRow = (row) => {
    setPageFilters({
      items: row.item || undefined,
      item_tag: row.item_tag || undefined,
      locations: row.location || undefined,
      location_tag: row.location_tag || undefined,
      rangeOption: CUSTOM_RANGE,
      range: row.from_date && row.to_date ? [row.from_date, row.to_date] : [],
      weekdays: row.weekdays ? row.weekdays.map(i => i.toString()) : []
    })
    if (onRefinedRow) onRefinedRow()
  }

  const columns = useMemo(() => [
    ...getColumns(getColumnKeysByTableType(tableType), locationTagData, itemTagData, intl, tiny),
    ...(!tiny
      ? [{
          type: Grid.TYPE.BUTTONS,
          buttons: [
            {
              icon: 'Filter',
              onClick: ({ data }) => refineRow(data),
              key: 'action-refine'
            },
            {
              icon: 'OverflowMenuVertical',
              key: 'actions',
              menuSections: [{
                key: 'actions-section',
                menuItems: [
                  {
                    key: 'duplicate',
                    label: intl.formatMessage(labelMessages.duplicateRow),
                    icon: 'Copy',
                    onClick: ({ data }) => {
                      setBulkEditing(false)
                      setDuplicating(true)
                      setEditRecord(omit(data, ['id']))
                      show(modalName)
                    }
                  },
                  {
                    key: 'renew',
                    icon: 'Edit',
                    onClick: ({ data }) => {
                      setBulkEditing(false)
                      setDuplicating(false)
                      setEditRecord(data)
                      show(modalName)
                    },
                    label: intl.formatMessage(labelMessages.editRow)
                  },
                  {
                    key: 'delete',
                    label: intl.formatMessage(labelMessages.deleteRow),
                    icon: 'TrashCan',
                    onClick: ({ data }) => setRemoveItem(data)
                  }
                ]
              }]
            }
          ],
          ...Grid.ACTION_COLUMN_PROPS,
          width: 95,
          maxWidth: 95,
          minWidth: 95
        }]
      : [])
  ], [tableType, locationTagData, itemTagData, intl, tiny])

  const onFormSubmit = (values) => {
    if (bulkEditing) {
      const selectedIds = selection.map(s => s.id)
      const collection = selectedIds.map(s => ({
        id: s,
        ...values
      }))
      const bulkUpdateFields = sortedUniq(flatten(collection.map(c => Object.keys(c).filter(i => i !== 'id'))).sort())
      bulkUpdate.mutateAsync(collection)
        .then(() => {
          hide(modalName)
          setBulkEditing(false)
          reset(selectionKey)
          // trackEvent(`Todo Config ${getConfigTitle(intl, 'order', tableType, true)} Bulk Edited`, { IDs: selection.join(','), Fields: bulkUpdateFields.join(',') })
        })
        .catch(() => { })
      return
    }

    if (editRecord && !duplicating) {
      update.mutateAsync({
        ...values,
        id: editRecord.id
      })
        .then(() => {
          hide(modalName)
          setBulkEditing(false)
          setEditRecord(null)
          setDuplicating(false)
          // trackEvent(`Order Config ${getConfigTitle(intl, 'order', tableType, true)} Edited`, { ID: editRecord.id })
        })
        .catch(() => { })
    } else {
      add.mutateAsync(values)
        .then((item) => {
          hide(modalName)
          setBulkEditing(false)
          setDuplicating(false)
          trackEvent(`Order Config ${getConfigTitle(intl, 'order', tableType, true)} created`, { ID: item.id })
        })
        .catch(() => { })
    }
  }

  const onConfirmedDeletion = () => {
    remove.mutateAsync(removeItem.id).then(() => {
      // trackEvent(`Order Config ${getConfigTitle(intl, 'order', tableType, true)} Deleted`, { ID: removeItem.id })
      setRemoveItem(null)
    }).catch(() => { })
  }

  const onCancel = () => {
    hide(modalName)
    setBulkEditing(false)
    setEditRecord(null)
  }

  const onCellEditRequest = ({ colDef, value, data, node }) => {
    switch (colDef.field) {
      case 'rank':
      case 'scheduled_due_by':
      case 'scheduled_last_until':
        if (!value) break
        node.setData({ ...data, [colDef.field]: value })
        update.mutateAsync({ id: data.id, [colDef.field]: value })
        break
      case 'daily_quantile':
      case 'lead_quantile':
      case 'sustain_quantile':
      case 'lead_interval':
      case 'sustain_interval':
        if (!value) value = 0
        node.setData({ ...data, [colDef.field]: value })
        update.mutateAsync({ id: data.id, [colDef.field]: value })
        break
      default:
        break
    }
  }

  return (
    <div>
      <ConfigTableHeader
        category='todo'
        tableType={tableType}
        tiny={tiny}
        onAdd={() => {
          show(modalName)
          setEditRecord(null)
          setBulkEditing(false)
        }}
        onBulkEdit={() => {
          setBulkEditing(true)
          show(modalName)
        }}
        selectionLength={selection.length}
      />
      <Grid
        columns={columns}
        data={items}
        count={propsItems?.length}
        performingRequest={loading}
        noPadding
        noSidebar={tiny}
        heightAsRows
        onCellEditRequest={onCellEditRequest}
        autoSize={isDesktop && !tiny}
        autoSizeStrategy={{ defaultMinWidth: 150 }}
        selectionKey={selectionKey}
        {...gridProps}
      />
      {!tiny && (
        <>
          <ConfigForm
            visible={modal}
            onCancel={onCancel}
            onSubmit={onFormSubmit}
            initialValues={bulkEditing ? {} : editRecord}
            bulkEditingCount={bulkEditing ? selection.length : undefined}
            confirmLoading={add.isPending || update.isPending || bulkUpdate.isPending}
            tableType={tableType}
            duplicate={duplicating}
          />
          <RemoveConfigModal
            intl={intl}
            onClose={() => setRemoveItem(null)}
            onSubmit={onConfirmedDeletion}
            selectedItem={removeItem}
            loading={remove.isPending}
          />
        </>)}
    </div>
  )
}
