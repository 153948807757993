import { divide100, getConfigDialogTitle, isEmpty, multiply100 } from 'utils'
import { get, omit, pickBy } from 'lodash'
import { GenericForm } from 'components/Form'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import labelMessages from 'components/labelMessages'
import { getConfigFields } from 'components/ConfigFieldsAndColumns/Fields'
import formMessages from 'components/formMessages'
import { OVERPLAN_TYPES } from 'constants/index'
import { validationSchemas } from './validationSchema'

// Field type definitions with transformation rules
const FIELD_TYPES = {
  BOOLEAN: 'boolean',
  PERCENTAGE: 'percentage',
  NUMBER: 'number',
  TEXT: 'text',
  REFERENCE: 'reference',
  OVERPLAN: 'overplan',
  DATE_RANGE: 'date_range',
  TIME_RANGE: 'time_range',
  WEEKDAYS: 'weekdays'
}

// Field metadata registry - maps field names to their types and processing rules
const FIELD_REGISTRY = {
  // Boolean fields
  orders_enabled: { type: FIELD_TYPES.BOOLEAN, nullWhenDash: true },
  multiday_tracking: { type: FIELD_TYPES.BOOLEAN, nullWhenDash: true },
  inventory_tracking: { type: FIELD_TYPES.BOOLEAN, nullWhenDash: true },
  stochastic_rounding: {
    type: FIELD_TYPES.BOOLEAN,
    nullWhenDash: true,
    affectsOtherFields: ['batch_rounding_cutoff', 'min_amount_rounding_cutoff']
  },
  demand_forecasting: { type: FIELD_TYPES.BOOLEAN, nullWhenDash: true },
  substitutability: { type: FIELD_TYPES.BOOLEAN, nullWhenDash: true },
  inventory_group_enabled: { type: FIELD_TYPES.BOOLEAN, nullWhenDash: true },
  forecasting_v2: { type: FIELD_TYPES.BOOLEAN, nullWhenDash: true },

  // Percentage fields
  order_factor: { type: FIELD_TYPES.PERCENTAGE },
  batch_rounding_cutoff: {
    type: FIELD_TYPES.PERCENTAGE,
    conditionalNulling: (values) => values.stochastic_rounding === true
  },
  min_amount_rounding_cutoff: {
    type: FIELD_TYPES.PERCENTAGE,
    conditionalNulling: (values) => values.stochastic_rounding === true
  },
  daily_quantile: { type: FIELD_TYPES.PERCENTAGE },
  lead_quantile: { type: FIELD_TYPES.PERCENTAGE },
  sustain_quantile: { type: FIELD_TYPES.PERCENTAGE },

  // Number fields
  rank: { type: FIELD_TYPES.NUMBER },
  fulfillment_delay: {
    type: FIELD_TYPES.NUMBER,
    valueTransform: (value) => value < 0 ? null : value
  },
  lead_interval: { type: FIELD_TYPES.NUMBER, allowZero: true },
  sustain_interval: { type: FIELD_TYPES.NUMBER, allowZero: true },

  // Text fields
  scheduled_due_by: { type: FIELD_TYPES.TEXT, allowEmpty: true },
  scheduled_last_until: { type: FIELD_TYPES.TEXT, allowEmpty: true },
  comment: { type: FIELD_TYPES.TEXT, allowEmpty: true },

  // Reference fields
  reference_item: {
    type: FIELD_TYPES.REFERENCE,
    nullWhenEmpty: true
  },

  // Special fields with complex processing
  overplan_type: { type: FIELD_TYPES.OVERPLAN, part: 'type' },
  overplan_value: { type: FIELD_TYPES.OVERPLAN, part: 'value' },

  // Date and time ranges
  from_date: { type: FIELD_TYPES.DATE_RANGE, part: 'start' },
  to_date: { type: FIELD_TYPES.DATE_RANGE, part: 'end' },
  from_time: { type: FIELD_TYPES.TIME_RANGE, part: 'start' },
  to_time: { type: FIELD_TYPES.TIME_RANGE, part: 'end' },

  // Other field types
  weekdays: {
    type: FIELD_TYPES.WEEKDAYS,
    valueTransform: (value) => Array.isArray(value) && value.length === 0 ? null : value
  }
}

// Configuration maps for different table types
const CONFIG_TYPES = {
  // Order Config Types
  quantile: {
    entityType: 'order',
    fields: ['overplan_type', 'overplan_value'],
    validationSchema: validationSchemas.quantile
  },
  fulfillmentDelay: {
    entityType: 'order',
    fields: ['fulfillment_delay'],
    validationSchema: validationSchemas.fulfillmentDelay
  },
  orderFactor: {
    entityType: 'order',
    fields: ['order_factor'],
    validationSchema: validationSchemas.orderFactor
  },
  ordersEnabled: {
    entityType: 'order',
    fields: ['orders_enabled'],
    validationSchema: validationSchemas.ordersEnabled
  },
  referenceItem: {
    entityType: 'order',
    fields: ['reference_item'],
    validationSchema: validationSchemas.referenceItem
  },
  batchRounding: {
    entityType: 'order',
    fields: ['stochastic_rounding', 'batch_rounding_cutoff', 'min_amount_rounding_cutoff'],
    validationSchema: validationSchemas.batchRounding
  },
  multidayTracking: {
    entityType: 'order',
    fields: ['multiday_tracking', 'inventory_tracking'],
    validationSchema: validationSchemas.multidayTracking
  },
  forecastingV2: {
    entityType: 'order',
    fields: ['forecasting_v2'],
    validationSchema: validationSchemas.forecastingV2
  },
  demandForecasting: {
    entityType: 'order',
    fields: ['demand_forecasting', 'substitutability'],
    validationSchema: validationSchemas.demandForecasting
  },

  // Todo Config Types
  totalQuantile: {
    entityType: 'todo',
    fields: ['daily_quantile'],
    validationSchema: validationSchemas.totalQuantile
  },
  initialTodos: {
    entityType: 'todo',
    fields: ['scheduled_due_by', 'scheduled_last_until'],
    validationSchema: validationSchemas.initialTodos
  },
  scheduling: {
    entityType: 'todo',
    fields: ['lead_quantile', 'lead_interval', 'sustain_quantile', 'sustain_interval'],
    validationSchema: validationSchemas.scheduling
  },
  inventoryGroup: {
    entityType: 'todo',
    fields: ['inventory_group_enabled'],
    validationSchema: validationSchemas.inventoryGroup
  }
}

// Common fields for all configuration types
const COMMON_FIELDS = [
  'rank',
  'item',
  'item_tag',
  'location',
  'location_tag',
  'from_date',
  'to_date',
  'weekdays',
  'comment'
]

// Additional fields for Todo configurations
const TODO_ADDITIONAL_FIELDS = [
  'from_time',
  'to_time'
]

/**
 * Field Processors: Handles data transformations by field type
 */
const FieldProcessors = {
  // Transform API data to form values
  apiToForm: {
    [FIELD_TYPES.BOOLEAN]: (value) => value,
    [FIELD_TYPES.PERCENTAGE]: (value) => multiply100(value),
    [FIELD_TYPES.NUMBER]: (value) => value ?? '',
    [FIELD_TYPES.TEXT]: (value) => value ?? '',
    [FIELD_TYPES.REFERENCE]: (value) => value ?? '',
    [FIELD_TYPES.WEEKDAYS]: (value) => (value || []).map(i => i.toString()),

    // Special processor for overplan fields
    [FIELD_TYPES.OVERPLAN]: (value, fieldName, apiData) => {
      if (fieldName === 'overplan_type') {
        return apiData.overplan_type ?? ''
      }

      if (fieldName === 'overplan_value' && apiData.overplan_value !== null && apiData.overplan_value !== undefined) {
        const value = parseFloat(apiData.overplan_value)

        switch (apiData.overplan_type) {
          case OVERPLAN_TYPES.ABSOLUTE_VALUE:
            return value
          case OVERPLAN_TYPES.QUANTILE:
          case OVERPLAN_TYPES.SALES_PERCENTAGE:
          case OVERPLAN_TYPES.ORDER_PERCENTAGE:
            return value * 100
          default:
            return value
        }
      }

      return null
    }
  },

  // Transform form values to API data
  formToApi: {
    [FIELD_TYPES.BOOLEAN]: (value, fieldName, values, fieldMeta) => {
      if (fieldMeta.nullWhenDash && value === '-') {
        return null
      }

      // Check for conditional nulling based on other fields
      if (fieldMeta.affectsOtherFields && value === true) {
        // This will be handled in the finalizing step
        return value
      }

      return value
    },

    [FIELD_TYPES.PERCENTAGE]: (value, fieldName, values, fieldMeta) => {
      if (fieldMeta.conditionalNulling && fieldMeta.conditionalNulling(values)) {
        return null
      }

      return divide100(value)
    },

    [FIELD_TYPES.NUMBER]: (value, fieldName, values, fieldMeta) => {
      if (fieldMeta.allowZero && (value === 0 || value === '0')) {
        return 0
      }

      if (fieldMeta.valueTransform) {
        return fieldMeta.valueTransform(value)
      }

      return value === '' ? null : value
    },

    [FIELD_TYPES.TEXT]: (value, fieldName, values, fieldMeta) => {
      return fieldMeta.allowEmpty ? value : (value || null)
    },

    [FIELD_TYPES.REFERENCE]: (value, fieldName, values, fieldMeta) => {
      return fieldMeta.nullWhenEmpty && (!value || value === '') ? null : value
    },

    [FIELD_TYPES.WEEKDAYS]: (value, fieldName, values, fieldMeta) => {
      if (fieldMeta.valueTransform) {
        return fieldMeta.valueTransform(value)
      }

      return value
    },

    [FIELD_TYPES.OVERPLAN]: (value, fieldName, values, fieldMeta) => {
      if (fieldName === 'overplan_type') {
        return value || null
      }

      if (fieldName === 'overplan_value' && values.overplan_type && value !== null) {
        const numValue = parseFloat(value)

        switch (values.overplan_type) {
          case OVERPLAN_TYPES.ABSOLUTE_VALUE:
            return numValue
          case OVERPLAN_TYPES.QUANTILE:
          case OVERPLAN_TYPES.SALES_PERCENTAGE:
          case OVERPLAN_TYPES.ORDER_PERCENTAGE:
            return numValue / 100
          default:
            return numValue
        }
      }

      return null
    }
  }
}

// Data transformation utilities
const transformers = {
  // Format API data to form values
  apiToForm: (apiData, tableType) => {
    if (!apiData) return {}

    const entityType = CONFIG_TYPES[tableType]?.entityType || 'order'

    // Determine item and location selection type
    const itemOrGroup = !apiData.item_tag || apiData.item_tag === '' ? 'item' : 'group'
    const locationOrGroup = !apiData.location_tag || apiData.location_tag === '' ? 'location' : 'group'

    // Base form data with common fields
    const formData = {
      itemOrGroup,
      locationOrGroup,
      rank: apiData.rank ?? '',
      item: apiData.item ?? '',
      item_tag: apiData.item_tag ?? '',
      location: apiData.location ?? '',
      location_tag: apiData.location_tag ?? '',
      date: apiData.from_date ? [apiData.from_date, apiData.to_date] : [],
      weekdays: (apiData.weekdays || []).map(i => i.toString()),
      comment: apiData.comment ?? ''
    }

    // Additional fields for Todo configurations
    if (entityType === 'todo') {
      formData.time = apiData.from_time ? [apiData.from_time, apiData.to_time] : []
    }

    // Process type-specific fields using field registry
    const relevantFields = [
      ...CONFIG_TYPES[tableType]?.fields || []
    ]

    relevantFields.forEach(fieldName => {
      const fieldMeta = FIELD_REGISTRY[fieldName]

      if (fieldMeta) {
        const processor = FieldProcessors.apiToForm[fieldMeta.type]

        if (processor) {
          formData[fieldName] = processor(apiData[fieldName], fieldName, apiData)
        }
      }
    })

    return formData
  },

  // Transform form values to API format
  formToApi: (formValues, tableType, isDuplicate) => {
    const entityType = CONFIG_TYPES[tableType]?.entityType || 'order'

    // Get all fields relevant to this table type
    const relevantFields = [
      ...COMMON_FIELDS,
      ...(entityType === 'todo' ? TODO_ADDITIONAL_FIELDS : []),
      ...(CONFIG_TYPES[tableType]?.fields || [])
    ]

    const apiData = {}

    // Process item and item_tag fields
    if (tableType === 'inventoryGroup' || tableType === 'demandForecasting') {
      // For inventoryGroup, we always use item_tag
      apiData.item_tag = formValues.item_tag
      apiData.item = null
    } else if (formValues.itemOrGroup === 'item') {
      apiData.item = formValues.item
      apiData.item_tag = null
    } else if (formValues.itemOrGroup === 'group' || formValues.substitutability === true) {
      apiData.item_tag = formValues.item_tag
      apiData.item = null
    }

    // Process location and location_tag fields
    if (formValues.locationOrGroup === 'location') {
      apiData.location = formValues.location
      apiData.location_tag = null
    } else if (formValues.locationOrGroup === 'group') {
      apiData.location_tag = formValues.location_tag
      apiData.location = null
    }

    // Process date fields
    if (formValues.date !== undefined) {
      if (formValues.date && Array.isArray(formValues.date) && formValues.date.length === 2) {
        apiData.from_date = formValues.date[0]
        apiData.to_date = formValues.date[1]
      } else {
        apiData.from_date = null
        apiData.to_date = null
      }
    }

    // Process time fields for Todo configurations
    if (entityType === 'todo' && formValues.time !== undefined) {
      if (formValues.time && Array.isArray(formValues.time) && formValues.time.length === 2) {
        apiData.from_time = formValues.time[0]
        apiData.to_time = formValues.time[1]
      } else {
        apiData.from_time = ''
        apiData.to_time = ''
      }
    }

    // Process comment field
    if (formValues.comment !== undefined) {
      apiData.comment = formValues.comment
    }

    // Process weekdays field
    if (formValues.weekdays !== undefined) {
      apiData.weekdays = Array.isArray(formValues.weekdays) && formValues.weekdays.length === 0 ? null : formValues.weekdays
    }

    // Process rank field
    if (formValues.rank !== undefined) {
      apiData.rank = formValues.rank
    }

    // Process all other fields using field registry
    relevantFields.forEach(fieldName => {
      // Skip fields we've already processed manually
      if (['item', 'item_tag', 'location', 'location_tag', 'from_date', 'to_date',
        'from_time', 'to_time', 'comment', 'weekdays', 'rank'].includes(fieldName)) {
        return
      }

      if (formValues[fieldName] !== undefined) {
        const fieldMeta = FIELD_REGISTRY[fieldName]

        if (fieldMeta) {
          const processor = FieldProcessors.formToApi[fieldMeta.type]

          if (processor) {
            apiData[fieldName] = processor(formValues[fieldName], fieldName, formValues, fieldMeta)
          }
        }
      }
    })

    // Post-processing step for fields that affect other fields
    Object.entries(FIELD_REGISTRY).forEach(([fieldName, fieldMeta]) => {
      if (fieldMeta.affectsOtherFields && apiData[fieldName] === true) {
        fieldMeta.affectsOtherFields.forEach(affectedField => {
          apiData[affectedField] = null
        })
      }
    })

    // For duplicate, filter out empty values
    if (isDuplicate) {
      return pickBy(apiData, v => (Array.isArray(v) && v.length > 0) || !isEmpty(v))
    }

    // Only return fields that are relevant to the current table type
    return pickBy(apiData, (value, key) => relevantFields.includes(key))
  },

  // Compare initial and current values to get only changed fields
  getChangedValues: (initialValues, currentValues, tableType) => {
    if (!initialValues) return currentValues

    const entityType = CONFIG_TYPES[tableType]?.entityType || 'order'

    const relevantFields = [
      ...COMMON_FIELDS,
      ...(entityType === 'todo' ? TODO_ADDITIONAL_FIELDS : []),
      ...(CONFIG_TYPES[tableType]?.fields || [])
    ]

    const result = {}

    // Compare each field and include only changed ones
    relevantFields.forEach(field => {
      if (currentValues[field] !== initialValues[field]) {
        result[field] = currentValues[field]
      }
    })

    return result
  }
}

/**
 * Generic configuration form for different entity types
 */
export const ConfigForm = ({
  visible,
  onCancel,
  initialValues,
  onSubmit,
  bulkEditingCount,
  confirmLoading,
  tableType,
  duplicate
}) => {
  const bulkEditing = bulkEditingCount != null
  const intl = useIntl()

  // Determine the entity type (order or todo)
  const entityType = CONFIG_TYPES[tableType]?.entityType || 'order'
  const validationSchema = CONFIG_TYPES[tableType]?.validationSchema

  // Transform API data to form values
  const formInitialValues = useMemo(() => {
    if (bulkEditing) return {}
    return transformers.apiToForm(initialValues, tableType)
  }, [initialValues, tableType, bulkEditing])

  // Handle form submission
  const handleSubmit = (values) => {
    let submitValues

    if (bulkEditing) {
      // For bulk editing, include only defined fields
      const apiValues = transformers.formToApi(values, tableType, false)
      submitValues = omit(
        apiValues,
        Object.keys(apiValues).filter(key => apiValues[key] === undefined)
      )
    } else if (duplicate) {
      // For duplicate, transform all values
      submitValues = transformers.formToApi(values, tableType, true)
    } else {
      // For regular editing, only include changed values
      const apiCurrentValues = transformers.formToApi(values, tableType, false)
      const apiInitialValues = transformers.formToApi(formInitialValues, tableType, false)
      submitValues = transformers.getChangedValues(apiInitialValues, apiCurrentValues, tableType)
    }

    if (Object.keys(submitValues).length > 0 && onSubmit) {
      onSubmit(submitValues)
    } else {
      onCancel()
    }
  }

  // Define the form structure
  const structure = [
    {
      title: intl.formatMessage(labelMessages.limitingFactors),
      fields: [
        {
          type: 'number-format',
          decimalSeparator: ',',
          decimalScale: 0,
          label: intl.formatMessage(labelMessages.rank),
          name: 'rank'
        },
        // For inventoryGroup, itemOrGroup is always 'group'
        ...(tableType !== 'inventoryGroup' && tableType !== 'demandForecasting'
          ? [
              {
                type: 'text-select',
                name: 'itemOrGroup',
                label: intl.formatMessage(labelMessages.item),
                required: !bulkEditing,
                allowClear: false,
                creatable: false,
                options: [
                  { value: 'item', label: intl.formatMessage(labelMessages.limitByArticle) },
                  { value: 'group', label: intl.formatMessage(labelMessages.limitByItemTag) }
                ],
                // When substitutability is true, we can only limit by item tag
                visible: (values) => values.substitutability !== true
              }
            ]
          : []),
        {
          type: 'item',
          variant: 'single',
          name: 'item',
          visible: (values) => {
            if (tableType === 'inventoryGroup' || tableType === 'demandForecasting') return false
            return values.itemOrGroup === 'item'
            // return values.substitutability !== true && values.itemOrGroup === 'item'
          },
          // For Todo configurations, add filters
          ...(entityType === 'todo'
            ? {
                filters: [{ field: 'todo_tags', operator: '!=', value: [] }]
              }
            : {})
        },
        {
          type: 'search-tag',
          variant: 'single',
          name: 'item_tag',
          tagType: 'item',
          visible: (values) => {
            if (tableType === 'inventoryGroup' || tableType === 'demandForecasting' || values.itemOrGroup === 'group') return true
          },
          label: tableType === 'inventoryGroup' ? intl.formatMessage(labelMessages.items) : undefined,
          placeholder: intl.formatMessage(labelMessages.selectTag)
        },
        {
          type: 'text-select',
          name: 'locationOrGroup',
          label: intl.formatMessage(labelMessages.locations),
          required: !bulkEditing,
          allowClear: false,
          creatable: false,
          options: [
            { value: 'location', label: intl.formatMessage(labelMessages.limitByLocation) },
            { value: 'group', label: intl.formatMessage(labelMessages.limitByLocationTag) }
          ]
        },
        {
          type: 'location',
          variant: 'single',
          name: 'location',
          visible: (values) => values.locationOrGroup === 'location'
        },
        {
          type: 'search-tag',
          variant: 'single',
          name: 'location_tag',
          tagType: 'saleslocation',
          visible: (values) => values.locationOrGroup === 'group',
          placeholder: intl.formatMessage(labelMessages.selectTag)
        },
        {
          type: 'daterange',
          label: intl.formatMessage(labelMessages.dateRangeOptional),
          name: 'date',
          allowReset: true
        },
        // Time range only for Todo configurations
        ...(entityType === 'todo'
          ? [
              {
                type: 'timerange',
                label: intl.formatMessage(labelMessages.timeRangeOptional),
                name: 'time'
              }
            ]
          : []),
        {
          type: 'weekday',
          label: intl.formatMessage(labelMessages.weekdaysOptional),
          name: 'weekdays',
          variant: 'multi',
          withHoliday: true,
          placeholder: intl.formatMessage(formMessages.selectWeekdays)
        }
      ]
    },
    {
      title: intl.formatMessage(labelMessages.configuration),
      fields: [
        // For Todo configurations, add error-indicator
        ...(entityType === 'todo'
          ? [
              {
                type: 'error-indicator',
                errorKey: 'config'
              }
            ]
          : []),
        ...getConfigFields(
          CONFIG_TYPES[tableType]?.fields || [],
          intl,
          bulkEditing
        ),
        ...(entityType === 'order'
          ? [{
              type: 'text',
              name: 'comment',
              label: intl.formatMessage(labelMessages.comment)
            }]
          : [])
      ]
    }
  ]

  console.log('ValidationSchema', validationSchema)

  return (
    <GenericForm
      asModal
      title={getConfigDialogTitle(intl, entityType, tableType, initialValues != null && !duplicate)}
      visible={visible}
      structure={structure}
      validationSchema={bulkEditing ? undefined : validationSchema}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      bulkEditing={bulkEditing}
      bulkEditingCount={bulkEditingCount}
    />
  )
}
