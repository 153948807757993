import { defineMessages } from 'react-intl'

export default defineMessages({
  newDashboard: {
    id: 'dashboard.newDashboard',
    defaultMessage: 'New Dashboard'
  },
  editDashboard: {
    id: 'dashboard.editDashboard',
    defaultMessage: 'Edit Dashboard'
  },
  deleteDashboard: {
    id: 'dashboard.deleteDashboard',
    defaultMessage: 'Delete Dashboard'
  },
  duplicateDashboard: {
    id: 'dashboard.duplicateDashboard',
    defaultMessage: 'Duplicate Dashboard'
  },
  deleteConfirmation: {
    id: 'dashboard.deleteConfirmation',
    defaultMessage: 'Are you sure you want to delete the dashboard "{name}"?'
  },
  share: {
    id: 'dashboard.share',
    defaultMessage: 'Share'
  },
  shareDashboard: {
    id: 'dashboard.shareDashboard',
    defaultMessage: 'Share "{name}"'
  },
  selectDashboard: {
    id: 'dashboard.selectDashboard',
    defaultMessage: 'Select Dashboard'
  },
  myDashboards: {
    id: 'dashboard.myDashboards',
    defaultMessage: 'My Dashboards'
  },
  sharedDashboards: {
    id: 'dashboard.sharedDashboards',
    defaultMessage: 'Shared Dashboards'
  },
  defaultDashboard: {
    id: 'dashboard.defaultDashboard',
    defaultMessage: 'Default Dashboard'
  },
  copyOf: {
    id: 'dashboard.copyOf',
    defaultMessage: 'Copy of {name}'
  },
  typeToSearchForUsers: {
    id: 'dashboard.typeToSearchForUsers',
    defaultMessage: 'Type to search for users...'
  },
  shareHelpText: {
    id: 'dashboard.shareHelpText',
    defaultMessage: 'Other users may see different data based on their data permissions.'
  },
  migratingDashboard: {
    id: 'dashboard.migratingDashboard',
    defaultMessage: 'Migrating Dashboard... Please wait.'
  },
  peopleWithAccess: {
    id: 'dashboard.peopleWithAccess',
    defaultMessage: 'People with access'
  },
  generalAccess: {
    id: 'dashboard.generalAccess',
    defaultMessage: 'General access'
  },
  allCustomerUsers: {
    id: 'dashboard.allCustomerUsers',
    defaultMessage: 'All existing or future users of {name}'
  },
  confirmOwnershipChanges: {
    id: 'dashboard.confirmOwnershipChanges',
    defaultMessage: 'Confirm Ownership Change'
  },
  addOwnerDescription: {
    id: 'dashboard.addOwnerDescription',
    defaultMessage: 'You are adding another user as owner of the dashboard. Please keep in mind, that all owners have full access to the dashboard including deleting the dashboard or removing other owners.'
  },
  removeSelfOwnerDescription: {
    id: 'dashboard.removeSelfOwnerDescription',
    defaultMessage: 'You are removing yourself as owner of the dashboard. Please keep in mind, that you will lose the ability to update the dashboard permissions again.'
  },
  viewOnly: {
    id: 'dashboard.viewOnly',
    defaultMessage: 'View only'
  },
  viewOnlyTitle: {
    id: 'dashboard.viewOnlyTitle',
    defaultMessage: 'This dashboard is for view only'
  },
  viewOnlyDescription: {
    id: 'dashboard.viewOnlyDescription',
    defaultMessage: 'You can only view this shared dashboard. All changes to filters are temporary and local. You can reset them below. Duplicate the dashboard to make persistent changes.'
  },
  resetLocalFilters: {
    id: 'dashboard.resetLocalFilters',
    defaultMessage: 'Reset local filters'
  }
})
