import { Filter } from 'hooks/useAvailableFilters'

export const RANGE_GROUP_FIELDS = ['datehour', 'date', 'week']

// Fields which can be used for grouping / ordering by the framework.
// They will be converted into data-query (e.g. item_offering__sales_location__name) fields by the property definition.
export const FIELD = {
  LOCATION: 'location',
  LOCATION_PK: 'location_pk',
  LOCATION_ID: 'location_id',
  ITEM: 'item',
  ITEM_PK: 'item_pk',
  ITEM_ID: 'item_id',
  ITEM_CATEGORY_1: 'item_category_1',
  ITEM_CATEGORY_2: 'item_category_2',
  OFFERING_GROUP_1: 'offering_group_1', // menuline
  OFFERING_GROUP_2: 'offering_group_2', // component
  WASTE_CATEGORY: 'waste_category',
  WASTE_CATEGORY_ID: 'waste_category_id',
  NONE: 'none',
  ITEM_TAG: 'item_tag',
  LOCATION_TAG: 'item_offering__sales_location__tag_associations__tag__name'
}

// Default Field Maps for Database Tables (= the `from` field in the data source)
export const FIELD_MAPS = {
  Sale: {
    [FIELD.LOCATION]: 'item_offering__sales_location__name',
    [FIELD.LOCATION_PK]: 'item_offering__sales_location__sales_loc',
    [FIELD.LOCATION_ID]: 'item_offering__sales_location',
    [FIELD.ITEM]: 'item_offering__item__name',
    [FIELD.ITEM_ID]: 'item_offering__item',
    [FIELD.ITEM_PK]: 'item_offering__item__remote_pk',
    [FIELD.ITEM_CATEGORY_1]: 'item_offering__item__category_1',
    [FIELD.ITEM_CATEGORY_2]: 'item_offering__item__category_2',
    [FIELD.OFFERING_GROUP_1]: 'item_offering__menuline',
    [FIELD.OFFERING_GROUP_2]: 'item_offering__component',
    [FIELD.WASTE_CATEGORY]: null,
    [FIELD.WASTE_CATEGORY_ID]: null,
    [FIELD.ITEM_TAG]: 'item_offering__item__tag_associations__tag__name',
    itemTag: 'item_offering__item__tag_associations__tag', // urgh, this has to be fixed but time is running out
    [FIELD.LOCATION_TAG]: 'item_offering__sales_location__tag_associations__tag__name'
  },
  DemandOrSale: {
    [FIELD.LOCATION]: 'item_offering__sales_location__name',
    [FIELD.LOCATION_PK]: 'item_offering__sales_location__sales_loc',
    [FIELD.LOCATION_ID]: 'item_offering__sales_location',
    [FIELD.ITEM]: 'item_offering__item__name',
    [FIELD.ITEM_ID]: 'item_offering__item',
    [FIELD.ITEM_PK]: 'item_offering__item__remote_pk',
    [FIELD.ITEM_CATEGORY_1]: 'item_offering__item__category_1',
    [FIELD.ITEM_CATEGORY_2]: 'item_offering__item__category_2',
    [FIELD.OFFERING_GROUP_1]: 'item_offering__menuline',
    [FIELD.OFFERING_GROUP_2]: 'item_offering__component',
    [FIELD.WASTE_CATEGORY]: null,
    [FIELD.WASTE_CATEGORY_ID]: null,
    [FIELD.ITEM_TAG]: 'item_offering__item__tag_associations__tag__name',
    itemTag: 'item_offering__item__tag_associations__tag', // urgh, this has to be fixed but time is running out
    [FIELD.LOCATION_TAG]: 'item_offering__sales_location__tag_associations__tag__name'
  },
  Transaction: {
    [FIELD.LOCATION]: 'item_offering__sales_location__name',
    [FIELD.LOCATION_PK]: 'item_offering__sales_location__sales_loc',
    [FIELD.LOCATION_ID]: 'item_offering__sales_location',
    [FIELD.ITEM]: 'item_offering__item__name',
    [FIELD.ITEM_ID]: 'item_offering__item',
    [FIELD.ITEM_PK]: 'item_offering__item__remote_pk',
    [FIELD.ITEM_CATEGORY_1]: 'item_offering__item__category_1',
    [FIELD.ITEM_CATEGORY_2]: 'item_offering__item__category_2',
    [FIELD.OFFERING_GROUP_1]: 'item_offering__menuline',
    [FIELD.OFFERING_GROUP_2]: 'item_offering__component',
    [FIELD.WASTE_CATEGORY]: null,
    [FIELD.WASTE_CATEGORY_ID]: null,
    [FIELD.ITEM_TAG]: 'item_offering__item__tag_associations__tag__name',
    itemTag: 'item_offering__item__tag_associations__tag', // urgh, this has to be fixed but time is running out
    [FIELD.LOCATION_TAG]: 'item_offering__sales_location__tag_associations__tag__name'
  },
  ItemOffering: {
    [FIELD.LOCATION]: 'sales_location__name',
    [FIELD.LOCATION_PK]: 'sales_location__sales_loc',
    [FIELD.LOCATION_ID]: 'sales_location',
    [FIELD.ITEM]: 'item__name',
    [FIELD.ITEM_ID]: 'item',
    [FIELD.ITEM_PK]: 'item__remote_pk',
    [FIELD.ITEM_CATEGORY_1]: 'item__category_1',
    [FIELD.ITEM_CATEGORY_2]: 'item__category_2',
    [FIELD.OFFERING_GROUP_1]: 'menuline',
    [FIELD.OFFERING_GROUP_2]: 'component',
    [FIELD.WASTE_CATEGORY]: null,
    [FIELD.WASTE_CATEGORY_ID]: null,
    [FIELD.ITEM_TAG]: 'item__tag_associations__tag__name',
    itemTag: 'item__tag_associations__tag', // urgh, this has to be fixed but time is running out
    [FIELD.LOCATION_TAG]: 'sales_location__tag_associations__tag__name'
  },
  Waste: {
    [FIELD.LOCATION]: 'sales_location__name',
    [FIELD.LOCATION_PK]: 'sales_location__sales_loc',
    [FIELD.LOCATION_ID]: 'sales_location',
    [FIELD.ITEM]: null,
    [FIELD.ITEM_ID]: null,
    [FIELD.ITEM_PK]: null,
    [FIELD.ITEM_CATEGORY_1]: null,
    [FIELD.ITEM_CATEGORY_2]: null,
    [FIELD.OFFERING_GROUP_1]: null,
    [FIELD.OFFERING_GROUP_2]: null,
    [FIELD.WASTE_CATEGORY]: 'category__name',
    [FIELD.WASTE_CATEGORY_ID]: 'category',
    [FIELD.ITEM_TAG]: null,
    [FIELD.LOCATION_TAG]: 'sales_location__tag_associations__tag__name'
  },
  Order: {
    [FIELD.LOCATION]: 'location__name',
    [FIELD.LOCATION_PK]: 'location__sales_loc',
    [FIELD.LOCATION_ID]: 'location',
    [FIELD.ITEM]: 'item__name',
    [FIELD.ITEM_ID]: 'item',
    [FIELD.ITEM_PK]: 'item__remote_pk',
    [FIELD.ITEM_CATEGORY_1]: 'item__category_1',
    [FIELD.ITEM_CATEGORY_2]: 'item__category_2',
    [FIELD.OFFERING_GROUP_1]: null,
    [FIELD.OFFERING_GROUP_2]: null,
    [FIELD.WASTE_CATEGORY]: null,
    [FIELD.WASTE_CATEGORY_ID]: null,
    [FIELD.ITEM_TAG]: 'item__tag_associations__tag__name',
    itemTag: 'item__tag_associations__tag', // urgh, this has to be fixed but time is running out
    [FIELD.LOCATION_TAG]: 'location__tag_associations__tag__name'
  },
  ToDo: {
    [FIELD.LOCATION]: 'location__name',
    [FIELD.LOCATION_PK]: 'location__sales_loc',
    [FIELD.LOCATION_ID]: 'location',
    [FIELD.ITEM]: 'item__name',
    [FIELD.ITEM_ID]: 'item',
    [FIELD.ITEM_PK]: 'item__remote_pk',
    [FIELD.ITEM_CATEGORY_1]: 'item__category_1',
    [FIELD.ITEM_CATEGORY_2]: 'item__category_2',
    [FIELD.OFFERING_GROUP_1]: null,
    [FIELD.OFFERING_GROUP_2]: null,
    [FIELD.WASTE_CATEGORY]: null,
    [FIELD.WASTE_CATEGORY_ID]: null,
    [FIELD.ITEM_TAG]: 'item__tag_associations__tag__name',
    itemTag: 'item__tag_associations__tag', // urgh, this has to be fixed but time is running out
    [FIELD.LOCATION_TAG]: 'location__tag_associations__tag__name'
  },
  GoalInterval: {
    [FIELD.LOCATION]: 'location__name',
    [FIELD.LOCATION_PK]: 'location__sales_loc',
    [FIELD.LOCATION_ID]: 'location',
    [FIELD.LOCATION_TAG]: 'location__tag_associations__tag__name'
  }
}

export const FIELD_FILTER_MAP = {
  [FIELD.LOCATION]: Filter.LOCATION,
  [FIELD.ITEM]: Filter.ITEM,
  [FIELD.ITEM_CATEGORY_1]: Filter.ITEM_GROUP_1,
  [FIELD.ITEM_CATEGORY_2]: Filter.ITEM_GROUP_2,
  [FIELD.ITEM_TAG]: Filter.ITEM_TAG,
  [FIELD.OFFERING_GROUP_1]: Filter.OFFERING_GROUP_1_NAMES,
  [FIELD.OFFERING_GROUP_2]: Filter.OFFERING_GROUP_2_NAMES,
  [FIELD.WASTE_CATEGORY]: Filter.FOODWASTE_CATEGORY
}
