import { omit, map, split, dropRight, last, forEach, join, includes, keys, take, sortBy, isEmpty, get, clone, has, reverse, reduce, every, range } from 'lodash'
import { dateFormatter, gramFormatter, kgFormatter, liquidFormatter, dateTimeFormatter, deviationFormatter, timeRangeFormatter, daysFormatter, cronFormatter } from '../../utils/formatter'
import { DateWeatherRenderer } from './Renderer/DateWeatherRenderer'
import { LIQUID_VALUE_FORMAT, SMALL_WEIGHT_VALUE_FORMAT, WEIGHT_VALUE_FORMAT } from 'constants/index'
import NumberFormatEditor from './Editors/NumberFormatEditor'
import classNames from 'classnames'
import { TagsRenderer } from './Renderer/TagsRenderer'
import { BooleanRenderer } from './Renderer/BooleanRenderer'
import { ButtonsRenderer } from './Renderer/ButtonsRenderer'
import { MeasurementRenderer } from './Renderer/MeasurementRenderer'
import { ConfidenceRenderer } from './Renderer/ConfidenceRenderer'
import { DifferenceRenderer } from './Renderer/DifferenceRenderer'
import { TextWithTooltipRenderer } from './Renderer/TextWithTooltipRenderer'
import { ForecastRenderer } from './Renderer/ForecastRenderer'
import { CheckboxRenderer } from './Renderer/CheckboxRenderer'
import { DateRangeRenderer } from './Renderer/DateRangeRenderer'
import { NumberWithDifferenceRenderer } from './Renderer/NumberWithDifferenceRenderer'
import { DatastatusRenderer } from './Renderer/DataStatusRenderer'
import { AddressRenderer } from './Renderer/AddressRenderer'
import TagsEditor from './Editors/TagsEditor'
import { DownloadFileRenderer } from './Renderer/DownloadFileRenderer'
import { LinkRenderer } from './Renderer/LinkRenderer'
import { WeekdaysRenderer } from './Renderer/WeekdaysRenderer'
import { NumberWithComparisonValueRenderer } from './Renderer/NumberWithComparisonValueRenderer'
import { CronEditor } from './Editors/CronEditor'
import { getFormatterForReturnType } from 'components/Report/utils'
import { NumberWithButtonRenderer } from './Renderer/NumberWithButtonRenderer'

const withCompareDataMinWidth = 200

// We want an easy to use API for developers, so we define a single type for a column
// we pass to the grid. This function will convert that column into the ag-grid column definition
// using the right class, value formatters or renderers
export const getColumnDefinitionByColumn = (column, { intl, tagCollection, density, demo, regionPreset, hasCompareData }) => {
  let agGridProps
  switch (column.type) {
    case 'text':
    default:
      agGridProps = {
        ...(column.editable ? { cellClass: 'editable' } : undefined),
        ...(column.disguise && demo ? { valueFormatter: ({ value }) => demo(value) } : undefined)
      }
      break
    case 'textWithTooltip':
      agGridProps = {
        ...(column.editable ? { cellClass: 'editable' } : undefined),
        cellRenderer: TextWithTooltipRenderer
      }
      break
    case 'link':
      agGridProps = {
        cellRenderer: LinkRenderer
      }
      break
    case 'address':
      agGridProps = {
        cellRenderer: AddressRenderer
      }
      break
    case 'textLocalized':
      agGridProps = {
        valueFormatter: ({ value, colDef }) => {
          const locStr = colDef.localeDict ? colDef.localeDict[value] : null
          return locStr ? intl.formatMessage(locStr) : value
        }
      }
      break
    case 'number':
      agGridProps = {
        cellClass: typeof (column.cellClass) === 'function' ? column.cellClass : classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: (params) => {
          if (params.value == null) return null
          const formatter = getFormatterForReturnType(column.returnType)
          return formatter({
            value: params.value,
            colDef: {
              ...column,
              regionPreset
            }
          })
        },
        cellEditorParams: {
          returnType: column.returnType,
          colDef: {
            ...column,
            regionPreset
          }
        },
        ...(column.withDifference || column.isLocked ? { cellRenderer: NumberWithDifferenceRenderer } : undefined),
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.button ? { cellRenderer: NumberWithButtonRenderer } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                suffix: column.editorSuffix
              },
              density
            }
          : undefined)
      }
      break
    case 'integer':
      agGridProps = {
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: (params) => {
          if (params.value == null) return null
          const formatter = getFormatterForReturnType('integer')
          return formatter({
            value: params.value,
            colDef: {
              ...column,
              regionPreset
            }
          })
        },
        cellEditorParams: {
          returnType: 'integer',
          colDef: {
            ...column,
            regionPreset
          }
        },
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              editable: true,
              cellEditor: NumberFormatEditor,
              density
            }
          : undefined)
      }
      break
    case 'currency':
      agGridProps = {
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: (params) => {
          if (params.value == null) return null
          const formatter = getFormatterForReturnType('currency')
          return formatter({
            value: params.value,
            colDef: {
              ...column,
              regionPreset
            }
          })
        },
        cellEditorParams: {
          returnType: 'currency',
          colDef: {
            ...column,
            regionPreset
          }
        },
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              density
            }
          : undefined)
      }
      break
    case 'percent':
      agGridProps = {
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: (params) => {
          if (params.value == null) return null
          const formatter = getFormatterForReturnType('percent')
          return formatter({
            value: params.value,
            colDef: {
              ...column,
              regionPreset
            }
          })
        },
        cellEditorParams: {
          returnType: 'percent',
          colDef: {
            ...column,
            regionPreset
          }
        },
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              density
            }
          : undefined)
      }
      break
    case 'kg':
      agGridProps = {
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: (params) => kgFormatter({ ...params, colDef: { ...params.colDef, precision: 2, regionPreset } }),
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                format: { ...WEIGHT_VALUE_FORMAT, defaultValue: '' },
                regionPreset
              },
              density
            }
          : undefined)
      }
      break
    case 'gram':
      agGridProps = {
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: (params) => gramFormatter({ ...params, colDef: { ...params.colDef, precision: 2, regionPreset } }),
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                format: { ...SMALL_WEIGHT_VALUE_FORMAT, defaultValue: '' },
                regionPreset
              },
              density
            }
          : undefined)
      }
      break
    case 'liquid':
      agGridProps = {
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: liquidFormatter,
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                format: { ...LIQUID_VALUE_FORMAT, defaultValue: '' },
                regionPreset
              },
              density
            }
          : undefined)
      }
      break
    case 'date':
      agGridProps = {
        valueFormatter: dateFormatter,
        intl
      }
      break
    case 'datetime':
      agGridProps = {
        valueFormatter: dateTimeFormatter,
        intl
      }
      break
    case 'dateweather':
      agGridProps = {
        cellRenderer: DateWeatherRenderer
      }
      break
    case 'daterange':
      agGridProps = {
        cellRenderer: DateRangeRenderer
      }
      break
    case 'timerange':
      agGridProps = {
        valueFormatter: timeRangeFormatter
      }
      break
    case 'weekdays':
      agGridProps = {
        cellRenderer: WeekdaysRenderer
      }
      break
    case 'days':
      agGridProps = {
        valueFormatter: daysFormatter,
        intl
      }
      break
    case 'datastatus':
      agGridProps = {
        cellRenderer: DatastatusRenderer
      }
      break
    case 'measurement':
      agGridProps = {
        cellRenderer: MeasurementRenderer,
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: (params) => kgFormatter({ ...params, colDef: { ...params.colDef, precision: 2, regionPreset } }),
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                returnType: 'measurement',
                colDef: {
                  ...column,
                  regionPreset,
                  suffix: 'kg'
                }
              },
              density
            }
          : undefined)
      }
      break
    case 'forecast':
      agGridProps = {
        cellRenderer: ForecastRenderer,
        valueFormatter: deviationFormatter
      }
      break
    case 'deviation':
      agGridProps = {
        valueFormatter: deviationFormatter
      }
      break
    case 'tags':
      agGridProps = {
        cellRenderer: TagsRenderer,
        tagCollection,
        ...(column.editable
          ? {
              cellClass: 'editable',
              cellEditor: TagsEditor,
              cellEditorParams: {
                tagType: column.tagType
              },
              density,
              minWidth: 256 // To fit the TagPicker
            }
          : undefined)
      }
      break
    case 'boolean':
      agGridProps = {
        cellRenderer: BooleanRenderer,
        intl
      }
      break
    case 'buttons':
      agGridProps = {
        cellRenderer: ButtonsRenderer,
        density
      }
      break
    case 'confidence':
      agGridProps = {
        cellRenderer: ConfidenceRenderer
      }
      break
    case 'difference':
      agGridProps = {
        cellRenderer: DifferenceRenderer
      }
      break
    case 'checkbox':
      agGridProps = {
        cellRenderer: CheckboxRenderer,
        cellEditor: null,
        intl
      }
      break
    case 'downloadFile':
      agGridProps = {
        cellRenderer: DownloadFileRenderer,
        intl
      }
      break
    case 'component':
      agGridProps = {
        cellRenderer: column.renderer,
        intl
      }
      break
    case 'customformatter':
      agGridProps = {}
      break
    case 'cron':
      agGridProps = {
        cellClass: classNames(column.editable === true ? 'editable' : null),
        valueFormatter: cronFormatter,
        ...(column.editable
          ? {
              cellEditor: CronEditor,
              density
            }
          : undefined)
      }
      break
  }

  const classes = column.classesFunc ? (props) => classNames(column.classesFunc(props), agGridProps.cellClass) : agGridProps.cellClass

  return {
    ...omit(column, ['type']),
    colType: column.type,
    ...agGridProps,
    cellClass: classes
  }
}

export const getOrderingFromColState = (currentColState, defaultOrdering, sortKeys = {}, asObj = false) => {
  const firstColumnWithOrder = currentColState ? currentColState.find((c) => c.sortIndex != null) : null

  // default sort
  if (!firstColumnWithOrder) return asObj ? defaultOrdering : defaultOrdering.map(o => `${o.desc ? '-' : ''}${o.key}`).join(',')
  const sortKey = sortKeys[firstColumnWithOrder.colId] || firstColumnWithOrder.colId
  const sortingKeys = sortKey === 'address' ? ['city', 'address_one'] : [sortKey]

  // remove the first column from the default ordering
  const defaultOrderingFiltered = defaultOrdering.filter(o => !sortingKeys.includes(o.key))

  if (asObj) {
    return [
      ...sortingKeys.map(k => ({ key: k, desc: firstColumnWithOrder.sort === 'desc' })),
      ...defaultOrderingFiltered
    ]
  }

  return [
    ...sortingKeys.map(k => firstColumnWithOrder.sort === 'asc' ? k : `-${k}`),
    ...defaultOrderingFiltered.map(o => `${o.desc ? '-' : ''}${o.key}`)
  ].join(',')
}

/**
 * Sorts pivot result fields based on a sort model configuration
 *
 * @param {Array<Object>} rowData - The row data containing values for each pivot field
 * @param {Array<string>} pivotResultFields - Array of pivot field names in format "level1|level2|...|metric"
 * @param {Array<Object>} sortModel - Configuration for sorting pivot columns
 * @param {string} sortModel[].colId - The column ID to sort by ("self" for the level name or a metric name)
 * @param {string} sortModel[].sort - Sort direction ("asc" or "desc")
 * @param {Array<string>} metricKeys - Array of metric keys in the order they should appear
 * @returns {Array<string>} Array containing the sorted pivotResultFields
 *
 * @example
 * // Sort first pivot level by name descending, second level by revenue ascending
 * const sortModel = [
 *   { colId: "self", sort: "desc" },
 *   { colId: "revenue", sort: "asc" }
 * ];
 * const metricKeys = ['revenue', 'num_sold', 'lost_demand'];
 * const result = performPivotSort(rowData, pivotResultFields, sortModel, metricKeys);
 */
export const performPivotSort = (rowData, pivotResultFields, sortModel, metricKeys) => {
  if (isEmpty(sortModel)) {
    return pivotResultFields
  }

  // Create a map to store aggregated values for each pivot level
  const aggregatedValues = {}

  // Parse pivot fields to understand structure
  const pivotStructure = map(pivotResultFields, field => {
    const parts = split(field, '|')
    return {
      field,
      pivotLevels: dropRight(parts, 1), // All parts except the last one (metric)
      metric: last(parts)
    }
  })

  // Group pivot fields by their levels
  const pivotGroups = {}
  forEach(pivotStructure, item => {
    // Create keys for each pivot level
    for (let i = 0; i < item.pivotLevels.length; i++) {
      const levelKey = join(take(item.pivotLevels, i + 1), '|')
      if (!pivotGroups[levelKey]) {
        pivotGroups[levelKey] = []
      }
      if (!includes(pivotGroups[levelKey], item.field)) {
        pivotGroups[levelKey].push(item.field)
      }
    }
  })

  // Aggregate values from rowData for each pivot level and metric
  forEach(rowData, row => {
    forEach(keys(row), key => {
      // Skip non-pivot fields
      if (!includes(pivotResultFields, key)) return

      const parts = split(key, '|')
      const metric = last(parts)

      // Create aggregation keys for each pivot level
      for (let i = 0; i < parts.length - 1; i++) {
        const levelKey = join(take(parts, i + 1), '|')
        const levelMetricKey = `${levelKey}|${metric}`

        if (!has(aggregatedValues, levelMetricKey)) {
          aggregatedValues[levelMetricKey] = 0
        }

        // Add the value to the aggregation
        aggregatedValues[levelMetricKey] += get(row, key, 0)
      }

      // Also store the original value
      if (!has(aggregatedValues, key)) {
        aggregatedValues[key] = 0
      }
      aggregatedValues[key] += get(row, key, 0)
    })
  })

  // Create a copy of pivotResultFields to sort
  const sortedPivotFields = clone(pivotResultFields)

  // Apply sorting based on sortModel
  forEach(sortModel, (sortItem, sortLevel) => {
    const { colId, sort } = sortItem

    // Group fields by their pivot level for this sort level
    const pivotLevel = sortLevel
    const levelGroups = {}

    forEach(pivotStructure, item => {
      if (item.pivotLevels.length > pivotLevel) {
        const groupKey = join(take(item.pivotLevels, pivotLevel), '|')
        const levelKey = item.pivotLevels[pivotLevel]

        if (!has(levelGroups, groupKey)) {
          levelGroups[groupKey] = new Set()
        }
        levelGroups[groupKey].add(levelKey)
      }
    })

    // Sort each group
    forEach(keys(levelGroups), groupKey => {
      const levelKeys = Array.from(levelGroups[groupKey])

      // Sort the level keys
      const sortedLevelKeys = sortBy(levelKeys, levelKey => {
        if (colId === 'self') {
          // Sort by the level name itself
          return levelKey
        } else {
          // Sort by the aggregated metric value
          const keyPrefix = groupKey ? `${groupKey}|${levelKey}` : levelKey
          return get(aggregatedValues, `${keyPrefix}|${colId}`, 0)
        }
      })

      // Reverse if descending order
      if (sort === 'desc') {
        reverse(sortedLevelKeys)
      }

      // Create a mapping for the new order
      const orderMap = reduce(sortedLevelKeys, (result, key, index) => {
        result[key] = index
        return result
      }, {})

      // Sort the pivot fields based on this level's ordering
      sortedPivotFields.sort((a, b) => {
        const partsA = split(a, '|')
        const partsB = split(b, '|')

        // Only compare if we're at the right level and the previous levels match
        if (partsA.length > pivotLevel && partsB.length > pivotLevel) {
          // Check if previous levels match
          const previousLevelsMatch = every(range(pivotLevel), i => partsA[i] === partsB[i])

          if (!previousLevelsMatch) {
            return 0 // Different groups, maintain relative order
          }

          const keyA = partsA[pivotLevel]
          const keyB = partsB[pivotLevel]

          if (keyA !== keyB) {
            return orderMap[keyA] - orderMap[keyB]
          }
        }

        return 0 // Maintain relative order for other cases
      })
    })
  })

  // If metricKeys is provided, sort the fields to respect the metric order within each pivot group
  if (metricKeys && metricKeys.length > 0) {
    // Group fields by their pivot levels (without the metric part)
    const pivotLevelGroups = {}

    forEach(sortedPivotFields, field => {
      const parts = split(field, '|')
      const pivotLevels = dropRight(parts, 1)
      const pivotLevelKey = join(pivotLevels, '|')

      if (!pivotLevelGroups[pivotLevelKey]) {
        pivotLevelGroups[pivotLevelKey] = []
      }

      pivotLevelGroups[pivotLevelKey].push(field)
    })

    // Sort each group based on the metricKeys order
    const finalSortedFields = []

    forEach(keys(pivotLevelGroups), pivotLevelKey => {
      const fieldsInGroup = pivotLevelGroups[pivotLevelKey]

      // Sort fields within this pivot level group based on metricKeys order
      const sortedGroupFields = sortBy(fieldsInGroup, field => {
        const metric = last(split(field, '|'))
        return metricKeys.indexOf(metric)
      })

      // Add the sorted fields to the final result
      finalSortedFields.push(...sortedGroupFields)
    })

    return finalSortedFields
  }

  return sortedPivotFields
}
