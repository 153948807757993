import { determineCustomerState } from 'utils'
import { create } from 'zustand'
import { useSettingsStore } from './useSettingsStore'
import { INITIAL_PERMISSIONS } from 'components/AppCore/Root'

export const useGeneralStore = create((set) => ({
  customersStore: null,
  locationsStore: null,
  fetch: null,
  cookies: null,
  token: null,
  allCustomers: null,
  customer: null,
  customerType: null,
  customerFoodWasteMode: null,
  customerSettings: null,
  version: null,
  version_backend: null,
  demo: false,
  locale: null,
  intl: null,
  socketEnabled: true,
  socketState: null,
  showConfirmationDialog: false,
  confirmationDialog: null,
  colorTable: {},
  deviceSize: {
    isMobile: false,
    isTablet: false,
    isDesktop: true
  },
  windowSize: null,
  permissions: INITIAL_PERMISSIONS,
  amplitudeInitialized: false,
  setFetch: (fetch) => set(() => ({ fetch })),
  setCookies: (cookies) => set(() => ({ cookies })),
  setToken: (token) => set(() => ({ token })),
  setCustomer: (customer, user) => set((state) => {
    if (useSettingsStore.getState().isInitialized && state.customer && customer != state.customer) {
      console.info('Resetted SettingsProvider initialization state.')
      useSettingsStore.setState({ isInitialized: false })
    }

    // TODO: TEC-1941 | We might want to refactor the handling of customerType and foodWasteMode
    // We could have customerProperties for holding the customerType and foodWasteMode and other fields we may get in the future
    return ({ customer, ...determineCustomerState(customer, user, state.allCustomers) })
  }),
  setVersion: (version) => set(() => ({
    version: version ? version.frontend_version : null,
    version_backend: version ? version.backend_version : null
  })),
  toggleDemo: () => set((state) => ({ demo: !state.demo })),
  setLocale: (locale) => set(() => ({ locale })),
  setIntl: (intl) => set(() => ({ intl })),
  setAllCustomers: (allCustomers) => set(() => ({ allCustomers })),
  setSocketState: (socketState) => set(() => ({ socketState })),
  setSocketEnabled: (socketEnabled) => set(() => ({ socketEnabled })),
  setConfirmationDialog: (confirmationDialog) => set(() => ({ confirmationDialog })),
  setShowConfirmationDialog: (showConfirmationDialog) => set(() => ({ showConfirmationDialog })),
  setColorTableForGroup: (group, colorTable) => set((state) => (
    {
      colorTable: {
        ...state.colorTable,
        // merge with existing color table
        [group]: {
          ...state.colorTable[group],
          ...colorTable
        }
      }
    }
  )
  ),
  setDeviceSize: (deviceSize) => set(() => ({ deviceSize })),
  setWindowSize: (windowSize) => set(() => ({ windowSize })),
  setPermissions: (permissions) => set(() => ({ permissions })),
  setAmplitudeInitialized: (amplitudeInitialized) => set(() => ({ amplitudeInitialized }))
}))
