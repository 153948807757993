import { ACCENT_COLORS, DFNS_DATE_FORMAT, DFNS_WEEK_FORMAT, OTHER_GROUP_THRESHOLD } from 'constants/index'
import { FOODWASTE_CHARTS, FORECAST_CHARTS, ORDER_CHARTS, TODO_CHARTS } from 'routes/dashboard/constants'
import {
  defaultTo,
  keys,
  map,
  pipe,
  prop,
  reduce,
  reject,
  reverse,
  sortBy,
  sum,
  toPairs,
  values
} from 'ramda'
import { format, parse } from 'date-fns'

import React from 'react'
import { ReadyState } from 'react-use-websocket'
import { getCompleteDateRange, getPreviousDateRange } from 'utils/datetime'
import { startsWith, without, orderBy, isNil, omit } from 'lodash'
import { cyrb53, keyValueObjectToSortedArr, sumKeyValue } from 'utils'

export const getPrevRangeParams = (params) => {
  if (!params) { return params }
  return params.map((param) =>
    param.field === 'date'
      ? getPreviousDateRange(param)
      : param
  )
}

export const getParamsWithout = (paramName, params) =>
  reject((item) => {
    const paramNames = typeof paramName === 'string' ? [paramName] : paramName
    return paramNames.includes(item.field)
  }, params)

export const getPrefixIcon = (key) => {
  if (FOODWASTE_CHARTS.includes(key)) {
    return 'TrashCan'
  }
  if (FORECAST_CHARTS.includes(key)) {
    return 'Restaurant'
  }
  if (ORDER_CHARTS.includes(key)) {
    return 'DeliveryAdd'
  }
  if (TODO_CHARTS.includes(key)) {
    return 'Measurement'
  }
  return null
}

export const calcBarChartData = (data, options) => {
  if (!data) {
    return []
  }
  const opts = {
    withAverage: true,
    percentageFromTotalAll: false,
    valueProp: false,
    ...options
  }
  const totalValues = data.totalValues || {}
  const prevValues = data.prevValues || {}
  const totalAllValues = data.totalAllValues || {}
  const prevAllValues = data.prevAllValues || {}
  const infoValues = data.info || {}
  const allLocations = Object.keys(totalAllValues)
  const allNonNullsLocations = allLocations.filter(l => opts.valueProp ? totalAllValues[l].value != null : totalAllValues[l] != null)

  const allLocationsValues = opts.withAverage
    ? reduce(
        (acc, location) => {
          return {
            location: null,
            value: totalAllValues[location] + (acc.value || 0),
            prevValue: !isNil(prevAllValues[location])
              ? prevAllValues[location] + (acc.prevValue || 0)
              : null
          }
        },
        {},
        allNonNullsLocations
      )
    : null

  const averageValue = opts.withAverage ? allLocationsValues.value / allNonNullsLocations.length : null
  const maxValue = Math.max(...(opts.valueProp ? Object.values(totalValues).map(v => v.value) : Object.values(totalValues)), averageValue)

  const averageValues = opts.withAverage
    ? {
        location: allLocationsValues.location,
        value: averageValue,
        prevValue: allLocationsValues.prevValue / allNonNullsLocations.length,
        percentage: (averageValue / maxValue) * 100,
        average: true
      }
    : null

  const sortProp = opts.percentageFromTotalAll ? 'percentValue' : 'value'

  return reverse(
    sortBy(prop(sortProp), [
      ...Object.keys(totalValues).map((location) => {
        const value = opts.valueProp ? totalValues[location] && totalValues[location].value : totalValues[location]
        const overallValue = opts.valueProp ? totalAllValues[location] && totalAllValues[location].value : totalAllValues[location]
        const prevValue = opts.valueProp ? prevValues[location] && prevValues[location].value : prevValues[location]
        const percentage = (value / maxValue) * 100
        const info = infoValues[location]

        const context = opts.valueProp
          ? {
              current: omit(totalValues[location], ['value']),
              prev: omit(prevValues[location], ['value'])
            }
          : null

        return {
          location,
          value,
          prevValue,
          percentage,
          info,
          ...(opts.percentageFromTotalAll && overallValue
            ? {
                percentValue: (value / overallValue) * 100,
                absoluteTotal: overallValue
              }
            : undefined),
          context
        }
      }),
      ...(allLocations.length && opts.withAverage ? [averageValues] : [])
    ])
  )
}

export const calcDifferenceChartData = (data = {}, groupBy, range, otherString, weekdays) => {
  const totalValues = calcTotalValues(data)

  const totalValue = sum(map(Math.abs, values(totalValues)))
  // We don't have any data to work with
  if (totalValue === 0) {
    return {
      data: [],
      bars: []
    }
  }

  const { dates, byWeek } = getCompleteDateRange(data, weekdays)

  const overallSums = {}
  const result = map((date) => {
    const name = format(new Date(date), byWeek ? DFNS_WEEK_FORMAT : DFNS_DATE_FORMAT)
    const items = data[name]
    return {
      date,
      ...reduce(
        (acc, name) => {
          if (totalValues[name] !== null &&
            Math.abs(totalValues[name]) / totalValue <
            OTHER_GROUP_THRESHOLD
          ) {
            sumKeyValue(overallSums, otherString, items[name])
            return { ...acc, [otherString]: (acc[otherString] || 0) + items[name] }
          }
          sumKeyValue(overallSums, name, items[name])
          return { ...acc, [name]: items[name] }
        },
        {},
        keys(items)
      )
    }
  })(dates)
  return {
    data: result,
    bars: keyValueObjectToSortedArr(overallSums).map(i => i.key)
  }
}

export const calcCompositionChartData = (data = {}, x, y, otherString) => {
  const total = sum(map(Math.abs, values(data)))
  const others = []
  return orderBy(pipe(
    defaultTo({}),
    toPairs,
    reduce((acc, [name, value]) => {
      if (Math.abs(value) / total < OTHER_GROUP_THRESHOLD) {
        others.push(name)
        return { ...acc, [otherString]: (acc[otherString] || 0) + value }
      }
      return { ...acc, [name]: value }
    }, {}),
    toPairs,
    map(([name, value]) => name === otherString
      ? ({
          name,
          value,
          group: others
        })
      : ({
          name,
          value
        }))
  )(data), ['value'], ['desc'])
}

export const calcFoodwasteChartData = (data = {}) => pipe(
  defaultTo({}),
  toPairs,
  map(([name, value]) => {
    const byWeek = name.includes('W')
    const date = parse(name.split(' - ')[0], byWeek ? DFNS_WEEK_FORMAT : DFNS_DATE_FORMAT, new Date()).valueOf()

    return {
      date,
      value
    }
  }),
  sortBy(prop('date'))
)(data)

export const calcBarCounts = (height, data) => {
  if (height == null || data == null) {
    return null
  }
  const tabsHeight = 3.5
  const cardRowHeightInRem = 2.5
  const footerHeightInRem = 1.5

  const pxPerRem = parseInt(window.getComputedStyle(document.documentElement).fontSize)

  const innerHeight = height - (tabsHeight * pxPerRem) - (footerHeightInRem * pxPerRem)
  const result = {
    barsCount: data ? Object.keys(data).length + 1 : 1,
    maxBarsCount: Math.ceil(innerHeight / (cardRowHeightInRem * pxPerRem))
  }
  return result
}

export const calcTotalValues = (data) =>
  pipe(
    values,
    reduce((acc, items) => {
      for (const prop in items) {
        if (acc[prop] != null) {
          acc[prop] += items[prop]
        } else {
          acc[prop] = items[prop]
        }
      }
      return acc
    }, {})
  )(data)

export const renderCustomizedLabel = (props, size) => {
  const { percent, cx, cy, innerRadius, maxRadius, outerRadius, midAngle } = props
  const minorSide = size ? Math.min(size.width, size.height - 50) : maxRadius
  const RADIAN = Math.PI / 180
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN) * 1.12
  const y = cy + radius * Math.sin(-midAngle * RADIAN) * 1.12
  return (
    <text
      x={x}
      y={y}
      cx={cx}
      cy={cy}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
      fontSize={20 * Math.min(minorSide / 400, 1)}
      fontWeight={900}
      fontFamily='Lato'
      fill='#113E6F'
    >
      {`${(percent * 100).toFixed(0)} %`}
    </text>
  )
}

export const WebsocketConnectionStateToString = (readyState) => {
  switch (readyState) {
    case ReadyState.CONNECTING:
    case 'connecting':
      return 'Connecting'
    case ReadyState.OPEN:
      return 'Open'
    case 'online':
      return 'Online'
    case ReadyState.CLOSING:
      return 'Closing'
    case ReadyState.CLOSED:
      return 'Closed'
    case 'offline':
      return 'Offline'
    case ReadyState.UNINSTANTIATED:
    case null:
      return 'Uninstantiated'
  }
}

export const getColor = (colorSet, key) => {
  const colorName = colorSet && colorSet[key] ? colorSet[key].color : '#dde1e6'
  return getColorByName(colorName)
}

export const getColorByName = (colorName) => {
  if (startsWith(colorName, '#')) return colorName
  if (colorName === 'NEUTRAL') return ACCENT_COLORS[colorName][40]
  const p = colorName.split('|')
  return ACCENT_COLORS[p[0]][p[1]]
}

/* this method returns consistenc colors for different keys */
export const determineColor = (table, key, group, used, otherString, ungroupedString, seed = 0) => {
  const usedGroup = table[group] || {}
  const usedColor = usedGroup[key]
  if (usedColor) {
    return {
      key,
      ...usedColor
    }
  }
  const colorKeys1 = without(without(Object.keys(ACCENT_COLORS), 'NEUTRAL').map(k => `${k}|25`), ...used)
  const colorKeys2 = without(without(Object.keys(ACCENT_COLORS), 'NEUTRAL').map(k => `${k}|50`), ...used)
  const colorKeys3 = without(without(Object.keys(ACCENT_COLORS), 'NEUTRAL').map(k => `${k}|75`), ...used)
  let fallbackLevel = 0

  /** The "Other" key always gets its static grey */
  if (key === otherString || key === ungroupedString) {
    return {
      key,
      hash: null,
      color: 'NEUTRAL'
    }
  }

  /** if we have all colors in use, we'll have to fallback to the bigger collection of badge colors */
  if (colorKeys1.length === 0) {
    fallbackLevel = 1
  }
  if (colorKeys2.length === 0) {
    fallbackLevel = 2
  }
  if (colorKeys3.length === 0) {
    console.warn('No more colors available, this should not happen!')
    return {
      key,
      hash: null,
      color: 'NEUTRAL'
    }
  }

  const hash = cyrb53(key, seed)

  let desiredColor
  switch (fallbackLevel) {
    case 0:
    default:
      desiredColor = colorKeys1[hash % colorKeys1.length]
      break
    case 1:
      desiredColor = colorKeys2[hash % colorKeys2.length]
      break
    case 2:
      desiredColor = colorKeys3[hash % colorKeys3.length]
      break
  }

  // if the determined color is already in use,
  // we have to increase the seed and try again
  // At this point colors for specific strings can become inconsistent over
  // different side loads based on the data, since random factor kicks in.
  if (used.includes(desiredColor)) {
    return determineColor(table, key, group, used, otherString, ungroupedString, seed + 1)
  }

  return {
    key,
    hash,
    color: desiredColor
  }
}
