import { cloneDeep, find, kebabCase, omit, pick, some } from 'lodash'
import messages from './messages'
import { getDefaultSizesByReportType, getMinSizesByReportType } from 'components/Report/utils'
import { useDashboardStore } from 'hooks/store/useDashboardStore'
import { DASHBOARD_DEFAULT_FILTERS } from 'constants/defaultSettings'
import { FilterSettingsMap } from 'hooks/useAvailableFilters'
import { DASHBOARD_ROUTE } from 'routes'
import { CUSTOM_RANGE, Filter } from 'constants/index'
import { comparingPeriodMigration } from 'components/Pickers/ComparisonPeriodPicker'
import { getRangePreset } from 'utils'

export const NO_ACCESS = 'noAccess'
export const VIEWER = 'viewer'
export const EDITOR = 'editor'
export const OWNER = 'owner'

export const getDashboardTitle = (dashboard, intl) => dashboard.title || intl.formatMessage(messages.defaultDashboard)

export const addReport = (reportEntity, forceLayout) => {
  const { id, definition } = reportEntity
  const { breakpoint, currentDashboard } = useDashboardStore.getState()
  const def = cloneDeep(definition)

  const bp = breakpoint || 'lg'

  const minSizes = getMinSizesByReportType(def.type)

  const l = {
    i: definition.id,
    ...(forceLayout
      ? {
          ...pick(forceLayout, ['x', 'y']),
          w: minSizes.minW > forceLayout.w ? minSizes.minW : forceLayout.w,
          h: minSizes.minH > forceLayout.h ? minSizes.minH : forceLayout.h,
          ...minSizes
        }
      : {
          x: 0,
          // we are placing new reports at the bottom of the dashboard
          y: currentDashboard.layouts[bp].length === 0 ? 0 : Math.max(...currentDashboard.layouts[bp].map((item) => item.y)),
          ...(def.dimensions ? def.dimensions : getDefaultSizesByReportType(def.type)),
          ...(getMinSizesByReportType(def.type))
        }
    )
  }

  const newLayouts = {}
  Object.keys(currentDashboard.layouts).forEach((key) => {
    newLayouts[key] = [l, ...currentDashboard.layouts[key]]
  })

  return {
    dashboardId: currentDashboard.id,
    layouts: newLayouts,
    reports: [...currentDashboard.reports, { id, definition: def }]
  }
}

export const minifyLayouts = (layouts) => {
  // for each key in layouts, we map the value to get an array with only the relevant attributes
  const minifiedLayout = {}
  Object.keys(layouts).forEach((key) => {
    minifiedLayout[key] = layouts[key].map((item) => pick(item, ['i', 'x', 'y', 'w', 'h', 'minW', 'minH']))
  })
  return minifiedLayout
}

export const getOwnAccess = (user, access, extendedAccessUsers) => {
  const ownAccess = extendedAccessUsers
    ? find(access, (a) => a.user?.id === user.id)?.type
    : find(access, (a) => a.user === user.id)?.type
  const tagAccess = find(access, (a) => user.tags?.includes(a.user_tag))?.type
  const generalAccess = find(access, { user: null, user_tag: null })?.type
  const arr = [ownAccess, tagAccess, generalAccess]

  if (arr.includes(OWNER)) return OWNER
  if (arr.includes(EDITOR)) return EDITOR
  if (arr.includes(VIEWER)) return VIEWER
  return NO_ACCESS
}

export const getReportByDefinition = (definition, dashboard) => find(dashboard.reports, (r) => r.definition.id === definition.id)

export const getDashboardIdsInOrder = (dashboards, orderIds) => {
  const ids = orderIds || []
  const dashboardsOrdered = (ids).filter(id => dashboards.find(d => d.id === id))
  if (ids.length !== dashboards.length) {
    dashboardsOrdered.push(...dashboards.filter(d => !ids.includes(d.id)).map(d => d.id))
  }
  return dashboardsOrdered
}

export const extendDashboard = (user, dashboard) => {
  const oDashboard = omit(dashboard, ['path', 'isOwner', 'isShared', 'isPrivate', 'ownAccessType', 'reports'])
  // check if dashboard.access.user is an object or a number
  const extendedAccessUsers = some(dashboard.access, (d) => d.user != null && typeof d.user === 'object')
  const isShared = extendedAccessUsers
    ? find(dashboard.access, (d) => d.user?.id !== user.id) != null
    : find(dashboard.access, (d) => d.user !== user.id) != null
  const isOwner = extendedAccessUsers
    ? find(dashboard.access, (a) => a.user?.id === user.id && a.type === OWNER) != null
    : find(dashboard.access, (a) => a.user === user.id && a.type === OWNER) != null
  return {
    path: kebabCase(`${dashboard.id}-${dashboard.title}` || 'null'),
    isOwner,
    isShared,
    isPrivate: !isShared,
    ownAccessType: getOwnAccess(user, dashboard.access, extendedAccessUsers),
    ...(dashboard.reports ? { reports: ensureReportMigration(ensureValidReportFilterSettings(dashboard.reports)) } : undefined),
    ...oDashboard,
    ...((dashboard.layouts !== undefined)
      ? {
          layouts: {
            lg: [],
            md: [],
            xxs: [],
            ...dashboard.layouts
          }
        }
      : undefined),
    ...((dashboard.filters !== undefined)
      ? {
          filters: {
            ...DASHBOARD_DEFAULT_FILTERS,
            ...omit(dashboard.filters, ['comparingPeriod']),
            ...(!dashboard.filters[Filter.COMPARISON] && dashboard.filters[Filter.DATE_RANGE] && dashboard.filters[Filter.DATE_RANGE]?.comparingPeriod
              ? { [Filter.COMPARISON]: comparingPeriodMigration(dashboard.filters[Filter.DATE_RANGE].comparingPeriod) }
              : undefined
            ),
            // enforce date range filter and migrate from dashboard settings
            ...(!dashboard.filters[Filter.DATE_RANGE] || (dashboard.filters[Filter.DATE_RANGE].option === CUSTOM_RANGE && dashboard.filters[Filter.DATE_RANGE].value.length === 0)
              ? dashboard.settings?.defaultRange
                  ? { [Filter.DATE_RANGE]: { value: getRangePreset(dashboard.settings.defaultRange, true), option: dashboard.settings.defaultRange } }
                  : { [Filter.DATE_RANGE]: { value: getRangePreset('yesterday'), option: 'yesterday' } }
              : undefined
            )
          }
        }
      : undefined)
  }
}

// FIXME: so when introducing collaborative reports, we got rid of the historical and confusing filter mapping
// We save the filter keys as they come from the components instead of using some naming some guy came up with years ago.
// Sadly, this would break all report definitions with the old filter keys, so we need to convert them here to the new system
// as soon the user edits/duplicates a report, it will be saved with the new filter keys, so at some point we can get rid of this code.
export const ensureValidReportFilterSettings = (reports) => {
  try {
    return reports.map((report) => {
      const mapping = omit(FilterSettingsMap[DASHBOARD_ROUTE], ['location', 'locations'])
      // switch key and values of the mapping object
      const reverseMapping = Object.keys(mapping).reduce((acc, key) => {
        acc[mapping[key]] = key
        return acc
      }, {})

      const definitionFilters = report.definition.filters || {}
      // we fix each key of definitionFilters object according to the reverseMapping
      const filterKeys = Object.keys(definitionFilters)
      const newFilters = {}
      filterKeys.forEach((key) => {
        newFilters[reverseMapping[key] || key] = definitionFilters[key]
      })

      if (!newFilters[Filter.COMPARISON] && newFilters[Filter.DATE_RANGE] && newFilters[Filter.DATE_RANGE]?.comparingPeriod) {
        newFilters[Filter.COMPARISON] = comparingPeriodMigration(newFilters[Filter.DATE_RANGE].comparingPeriod)
        newFilters[Filter.DATE_RANGE] = omit(newFilters[Filter.DATE_RANGE], ['comparingPeriod'])
      }

      let newDefinition = {
        ...report.definition,
        filters: newFilters
      }
      // ensure that the definition.data.groupBy is an array if definition.type is 'table'
      if (report.definition.type === 'table' && report.definition.data.groupBy && !Array.isArray(report.definition.data.groupBy)) {
        newDefinition = {
          ...newDefinition,
          data: {
            ...newDefinition.data,
            groupBy: [newDefinition.data.groupBy]
          }
        }
      }

      return {
        ...report,
        definition: newDefinition
      }
    })
  } catch (e) {
    console.error('Error in ensureValidReportFilterSettings', e)
    return reports
  }
}

// This function performs migrations for various report types:
// 1. For table reports: We switched from persisting the colState to having the row group, metric and pivot configuration with sorting in data.rows and data.columns.
// 2. For list reports: We switched from a single sort property to a rows configuration similar to table reports.
export const ensureReportMigration = (reports) => {
  return reports.map((report) => {
    // Handle table reports migration
    if (report.definition.type === 'table' &&
      (!report.definition.data.rows || !report.definition.data.columns)) {
      // Deep clone to avoid mutating the original
      const newDefinition = cloneDeep(report.definition)

      // Initialize rows and columns if they don't exist
      if (!newDefinition.data.rows) newDefinition.data.rows = []
      if (!newDefinition.data.columns) newDefinition.data.columns = []

      // Make sure groupBy is an array
      if (!Array.isArray(newDefinition.data.groupBy)) {
        newDefinition.data.groupBy = newDefinition.data.groupBy ? [newDefinition.data.groupBy] : []
      }

      // Process colState if it exists
      if (report.definition.colState && Array.isArray(report.definition.colState)) {
        // Extract row groups from colState
        const rowGroups = report.definition.data.groupBy

        // Find sorted columns
        const sortedColumns = report.definition.colState
          .filter(col => col.sort && col.sortIndex !== null)
          .filter(col => col.colId !== 'ag-Grid-AutoColumn')

        // Create rows configuration
        newDefinition.data.rows = rowGroups.map(group => {
          // Find if this column has a sort defined
          const sortConfig = sortedColumns.find(col => col.colId === group)

          return {
            key: group,
            sort: sortConfig
              ? { key: 'self', asc: sortConfig.sort === 'asc' }
              : { key: 'self', asc: true } // Default sort
          }
        })

        // If we have a sort on a metric column, apply it to the first row group
        if (sortedColumns.length > 0 && newDefinition.data.rows.length > 0) {
          const metricSort = sortedColumns.find(col =>
            !rowGroups.some(r => r === col.colId)
          )

          if (metricSort) {
            newDefinition.data.rows[0].sort = {
              key: metricSort.colId,
              asc: metricSort.sort === 'asc'
            }
          }
        }
      } else {
        // No colState, create default configuration based on groupBy
        newDefinition.data.rows = newDefinition.data.groupBy
          .filter(group => group !== '$rangeGroup') // Special handling for rangeGroup
          .map(group => ({
            key: group,
            sort: { key: 'self', asc: true }
          }))

        // Special handling for $rangeGroup - always sort chronologically
        const rangeGroupIndex = newDefinition.data.groupBy.indexOf('$rangeGroup')
        if (rangeGroupIndex !== -1) {
          // Insert at the correct position
          newDefinition.data.rows.splice(rangeGroupIndex, 0, {
            key: '$rangeGroup',
            sort: { key: 'self', asc: true }
          })
        }
      }

      // Remove colState as it's no longer needed
      delete newDefinition.colState

      return {
        ...report,
        definition: newDefinition
      }
    }

    // Handle list reports migration
    if (report.definition.type === 'list' &&
        report.definition.data.sort &&
        (!report.definition.data.rows || !report.definition.data.columns)) {
      // Deep clone to avoid mutating the original
      const newDefinition = cloneDeep(report.definition)

      // Initialize rows and columns if they don't exist
      if (!newDefinition.data.rows) newDefinition.data.rows = []
      if (!newDefinition.data.columns) newDefinition.data.columns = []

      // Make sure groupBy is an array
      if (!Array.isArray(newDefinition.data.groupBy)) {
        newDefinition.data.groupBy = newDefinition.data.groupBy ? [newDefinition.data.groupBy] : []
      }

      // Get the property key from the first property (legacy list reports always had exactly one property)
      const propertyKey = newDefinition.data.properties &&
                         newDefinition.data.properties.length > 0
        ? newDefinition.data.properties[0].propKey
        : 'self'

      // Create rows configuration based on groupBy and sort
      if (newDefinition.data.groupBy.length > 0) {
        newDefinition.data.rows = [{
          key: newDefinition.data.groupBy[0],
          sort: {
            key: propertyKey,
            // Legacy list reports used 'desc' as the default sort
            asc: newDefinition.data.sort !== 'desc'
          }
        }]
      }

      // Remove the old sort property as it's no longer needed
      delete newDefinition.data.sort

      return {
        ...report,
        definition: newDefinition
      }
    }

    return report
  })
}

/**
 * Applicable to View Only mode (`readonly = true`)
 * Returns the default filter we reset to.
 */
export const getDefaultFilters = (dashboard) => {
  const viewOnlyDefaultFilter = useDashboardStore.getState().viewOnlyDefaultFilters[dashboard.id]
  return viewOnlyDefaultFilter || dashboard.filters
}
export const getFilters = (dashboard, readonly, overriddenFilters = undefined) => {
  if (!overriddenFilters) {
    overriddenFilters = useDashboardStore.getState().overriddenFilters
  }
  if (!dashboard) return undefined
  const f = overriddenFilters[dashboard.id]
  return readonly && f !== undefined
    ? f
    : readonly
      ? getDefaultFilters(dashboard)
      : dashboard.filters
}
