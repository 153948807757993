import { useState } from 'react'
import classNames from 'classnames'
import { Icon, Text } from 'components/Primitives'
import { FormattedMessage } from 'react-intl'
import { useNotificationStore } from 'hooks/store/useNotificationStore'
import s from './Notification.module.scss'
import { DateValue } from 'components/DateValue'
import ScriptResult from 'components/ScriptResult'
import { Json } from 'components/Json'

/**
 * Component for displaying a single notification with expandable content
 */
export const Notification = ({ notification }) => {
  const [expanded, setExpanded] = useState(false)
  const markAsRead = useNotificationStore(state => state.markAsRead)

  // Mark as read when expanded
  const handleExpand = () => {
    setExpanded(!expanded)
    if (!notification.read) {
      markAsRead(notification.id)
    }
  }

  /**
   * Get the icon based on notification type
   */
  const getIcon = (type) => {
    switch (type) {
      case 'info':
      default:
        return 'Information'
      case 'success':
        return 'CheckmarkOutline'
      case 'warning':
        return 'Warning'
      case 'error':
        return 'MisuseOutline'
    }
  }

  /**
   * Get the notification text based on code
   */
  const getText = (notification) => {
    switch (notification.code) {
      case 'scripts.response':
        return notification.metadata.status === 'success'
          ? <FormattedMessage id='notification.script.success' defaultMessage='Script {scriptName} completed successfully' values={{ scriptName: notification.metadata.script_name }} />
          : <FormattedMessage id='notification.script.error' defaultMessage='Script {scriptName} failed' values={{ scriptName: notification.metadata.script_name }} />
      case 'demand_refreshed':
        return <FormattedMessage id='notification.demand_refreshed' defaultMessage='Demand data refreshed' />
      default:
        return <FormattedMessage id='notification.unknown' defaultMessage='Unknown notification ({code})' values={{ code: notification.code }} />
    }
  }

  /**
   * Get detailed content based on notification code and metadata
   */
  const getContent = (notification) => {
    switch (notification.code) {
      case 'scripts.response':
        const task = {
          result: notification.metadata.payload,
          status: notification.metadata.status,
          content_type: notification.metadata.content_type,
          encoding: notification.metadata.encoding
        }

        return (
          <div className={s.details}>
            <div className={s.scriptResultContainer}>
              <ScriptResult
                task={task}
                scriptName={notification.metadata.script_name}
                allowDownload
              />
            </div>
          </div>
        )
      default:
        return notification.metadata && Object.keys(notification.metadata).length > 0
          ? (
            <div className={s.detailsContainer}>
              <Json>{notification.metadata}</Json>
            </div>
            )
          : null
    }
  }

  const isExpandable = notification.metadata !== null && notification.metadata !== undefined

  const handleMarkAsRead = () => {
    if (!notification.read) {
      markAsRead(notification.id)
    }
  }

  return (
    <div
      className={classNames(
        s.notification,
        s[notification.type],
        notification.read && s.read,
        expanded && s.expanded,
        !expanded && isExpandable && s.clickable,
        !isExpandable && s.nonExpandable
      )}
      onClick={isExpandable ? (!expanded ? handleExpand : undefined) : handleMarkAsRead}
    >
      <div className='flex items-center'>
        <Icon name={getIcon(notification.type)} className={s.icon} />

        <div className={s.content}>
          <div
            className={classNames(
              s.header, 
              expanded && s.clickable,
              !isExpandable && s.nonExpandableHeader
            )}
            onClick={isExpandable && expanded ? handleExpand : undefined}
          >
            <Text className={s.title} color='black' size='s' bold>
              {getText(notification)}
            </Text>
            {isExpandable && (
              <Icon
                name={expanded ? 'CaretUp' : 'CaretDown'}
                size='xs'
                className={s.expandIcon}
              />
            )}
          </div>

          <div className={s.timestamp}>
            <DateValue withTime iso asString>{notification.timestamp}</DateValue>
          </div>

          {expanded && getContent(notification)}
        </div>
      </div>
    </div>
  )
}
