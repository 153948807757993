import messages from './messages'

export const ReportTypeOptions = {
  'summarized-area-with-comparison': {
    icon: 'SummaryKpi',
    label: messages['summarized-area-with-comparison']
  },
  line: {
    icon: 'Analytics',
    multiAxis: true,
    multiValue: true,
    metricsAsTags: true,
    label: messages.line
  },
  bar: {
    icon: 'ChartStacked',
    withGroupBy: true,
    allowNoGrouping: false, // right now report engine expects groupin
    label: messages.bar
  },
  pie: {
    icon: 'ChartRing',
    withGroupBy: true,
    label: messages.pie
  },
  table: {
    icon: 'Table',
    withGroupBy: true,
    multiValue: true,
    label: messages.table
  },
  list: {
    icon: 'ChartBar',
    withGroupBy: true,
    multiValue: true,
    label: messages.list
  },
  text: {
    icon: 'TextCreation',
    label: messages.text,
    multiValue: true
  }
}

export const INITIAL_REPORT_DEFINITION = {
  type: 'summarized-area-with-comparison',
  data: {
    properties: [
      {
        propKey: 'revenue'
      }
    ]
  },
  filters: {}
}
