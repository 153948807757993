import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { useIntl, FormattedMessage } from 'react-intl'
import { useModalStore } from 'hooks/store'
import { Icon, Text } from 'components/Primitives'
import { Tooltip } from 'components/Primitives/Tooltip'
import s from './Sidebar.module.scss'
import titleMessages from 'components/titleMessages'
import { useNotificationStore } from 'hooks/store/useNotificationStore'
import { memo } from 'react'

export const NotificationsMenuItem = memo(() => {
  const intl = useIntl()
  const show = useModalStore(state => state.show)
  const unreadCount = useNotificationStore(state => state.unreadCount)
  const hasUnreadNotifications = unreadCount > 0

  return (
    <NavigationMenu.Item value='notificationsModal' className={s.item}>
      {hasUnreadNotifications
        ? (
          <Tooltip
            side='right'
            title={
              <FormattedMessage
                id='notifications.unreadCount'
                defaultMessage='{unreadCount} new {unreadCount, plural, one {notification} other {notifications}}'
                values={{ unreadCount }}
              />
          }
            triggerAsChild
          >
            <button id='btn-notifications' type='button' onClick={() => show('notifications')}>
              <Icon color='danger' name='NotificationNew' className={s.icon} />
              <Text color='danger' type='headline' size='s'>{intl.formatMessage(titleMessages.notifications)}</Text>
            </button>
          </Tooltip>
          )
        : (
          <button id='btn-notifications' type='button' onClick={() => show('notifications')}>
            <Icon name='Notification' className={s.icon} />
            <Text type='headline' size='s'>{intl.formatMessage(titleMessages.notifications)}</Text>
          </button>
          )}
    </NavigationMenu.Item>
  )
})
