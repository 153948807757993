import {
  ADMIN_USERS_ROUTE,
  CUSTOMERS_ROUTE,
  DASHBOARD_ROUTE,
  FOODWASTE_ROUTE,
  FORECASTS_ROUTE,
  LOGOUT_ROUTE,
  ROOT_ROUTE,
  TODO_ROUTE,
  DEVELOPMENT_ROUTE,
  SCRIPTS_ROUTE,
  ORDERS_ROUTE,
  SETTINGS_GENERAL_ROUTE
} from 'routes'
import { useLocation } from 'react-router-dom'
import React, { memo, useEffect } from 'react'

import Logo from 'static/logo.svg'
import SmallLogo from 'static/small-logo.svg'
import cn from 'classnames'
import s from './Sidebar.module.scss'
import { useIntl } from 'react-intl'
import { useDemo, usePermissions, useUser } from 'hooks'
import titleMessages from 'components/titleMessages'
import globalMessages from 'components/globalMessages'
import { OpenMeasurementsIndicator } from 'components/OpenMeasurementsIndicator'
import { Link } from 'components/Link'
import { ADMIN_TAB_ROUTES } from 'components/App/Router'
import { Collapsible, Icon, Text } from 'components/Primitives'
import { useSettingsStore } from 'hooks/store/useSettingsStore'
import { useCustomerType } from 'hooks/useCustomerType'
import { BAKERY } from 'constants/index'
import { useAuth } from 'hooks/useAuth'
import { RenderCount } from 'components/RenderCount'
import { useOpenMeasurements } from 'hooks/queries/useOpenMeasurements'
import { setSetting } from 'services/SettingsProvider'
import { getByPath } from 'utils'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { Button } from 'components/Button'
import { useModalStore } from 'hooks/store'
import { NotificationsMenuItem } from './NotificationsMenuItem'

const linkId = (route) => `lnk-${route.replace('/', '')}`

const MenuItem = ({ className, label, icon, route, to, children }) => {
  const { pathname: currentRouteName } = useLocation()
  let selectedRoute = currentRouteName
  if (currentRouteName === ROOT_ROUTE || currentRouteName.startsWith(DASHBOARD_ROUTE)) {
    selectedRoute = DASHBOARD_ROUTE
  } else if (currentRouteName.startsWith('/admin')) {
    selectedRoute = ADMIN_USERS_ROUTE
  }

  let id = linkId(route)
  if (route.startsWith('/admin')) {
    id = 'lnk-admin'
  } else if (route.startsWith('/settings')) {
    id = 'lnk-settings'
  }

  return (
    <NavigationMenu.Item className={cn(className, s.item, selectedRoute === route && s.active)}>
      <Link id={id} to={to || route}>
        {children ||
          <>
            <Icon name={icon} className={s.icon} />
            <Text type='headline' size='s'>{label}</Text>
          </>}
      </Link>
    </NavigationMenu.Item>
  )
}

export const Sidebar = memo(({ onCollapsedChange }) => {
  const intl = useIntl()
  const { logout } = useAuth()

  const { user } = useUser()
  const lastAdminTab = useSettingsStore(state => getByPath(state.settings, 'adminFilters.tab', 'manageUsers'))
  const settings = useSettingsStore(state => state.globalSettings)
  const { lastDashboard } = useSettingsStore(state => state.settings)
  const { enabled } = useDemo()
  const { count } = useOpenMeasurements()
  const permissions = usePermissions()
  const customerType = useCustomerType()
  const show = useModalStore(state => state.show)

  const toggleMenu = () => {
    setSetting({ sidebarCollapsed: !settings.sidebarCollapsed }, true)
  }

  useEffect(() => {
    if (onCollapsedChange) onCollapsedChange(settings.sidebarCollapsed)
  }, [settings.sidebarCollapsed])

  // TODO: We could refactor this to use the routeRoleConfig, see the check method in AuthManager.

  return (
    <aside className={cn(s.sidebar, settings.sidebarCollapsed ? s.collapsed : null)}>
      <RenderCount className='top-20' />
      {user && (
        <NavigationMenu.Root
          className={s.menu}
          orientation='vertical'
        >
          <NavigationMenu.Sub className={s.sub}>
            <NavigationMenu.List className={s.list}>
              <NavigationMenu.Item className={s.logo}>
                <Link to={FORECASTS_ROUTE}>
                  {settings.sidebarCollapsed ? <img src={SmallLogo} alt='' /> : <img src={Logo} alt='' />}
                </Link>
              </NavigationMenu.Item>
              <MenuItem
                className='mt-1'
                label={intl.formatMessage(titleMessages.dashboard)}
                icon='ReportData'
                route={DASHBOARD_ROUTE}
                to={lastDashboard ? `${DASHBOARD_ROUTE}/${lastDashboard}` : DASHBOARD_ROUTE}
              />
              {permissions.isSuperUser && customerType === BAKERY && (
                <MenuItem
                  label={intl.formatMessage(titleMessages.orders)}
                  icon='DeliveryAdd'
                  route={ORDERS_ROUTE}
                />
              )}
              {permissions.offering && (
                <MenuItem
                  label={intl.formatMessage(titleMessages.offering)}
                  icon='Restaurant'
                  route={FORECASTS_ROUTE}
                />
              )}
              {permissions.foodwaste && (
                <MenuItem
                  label={intl.formatMessage(titleMessages.foodwaste)}
                  icon='TrashCan'
                  route={FOODWASTE_ROUTE}
                >
                  <div className={s.badgeWrapper}>
                    <Icon name='TrashCan' className={s.icon} />
                    <Text type='headline' size='s'>{intl.formatMessage(titleMessages.foodwaste)}</Text>
                    <OpenMeasurementsIndicator tiny={settings.sidebarCollapsed} className={s.indicator} count={count} />
                  </div>
                </MenuItem>
              )}
              {permissions.todo && (
                <MenuItem
                  label={intl.formatMessage(titleMessages.todo)}
                  icon='Measurement'
                  route={TODO_ROUTE}
                />
              )}
              {settings.development && (
                <MenuItem
                  label={intl.formatMessage(titleMessages.development)}
                  icon='Network_3'
                  route={DEVELOPMENT_ROUTE}
                />
              )}
            </NavigationMenu.List>
          </NavigationMenu.Sub>
          <NavigationMenu.Sub className={cn(s.sub, s.bottom)}>
            <NavigationMenu.List className={s.list}>
              {permissions.isOperator && <NotificationsMenuItem />}
              {!enabled && permissions.isOperator && (
                <Collapsible
                  indicatorSide='right'
                  className={s.collapsible}
                  triggerClassName={s.collapsibleTrigger}
                  contentClassName={s.collapsibleContent}
                  noIndicator={settings.sidebarCollapsed}
                  label={
                    <div className='flex items-center'>
                      <Icon name='GuiManagement' size='l' className={s.icon} />
                      <Text type='headline' size='s'>{intl.formatMessage(titleMessages.operator)}</Text>
                    </div>
                  }
                >
                  <MenuItem
                    label={intl.formatMessage(titleMessages.customers)}
                    icon='Events'
                    route={CUSTOMERS_ROUTE}
                  />
                  <MenuItem
                    label={intl.formatMessage(titleMessages.backendScripts)}
                    icon='ScriptReference'
                    route={SCRIPTS_ROUTE}
                  />
                </Collapsible>
              )}
              <NavigationMenu.Item value='helpModal' className={s.item}>
                <button id='btn-help' type='button' onClick={() => show('helpCenter')}>
                  <Icon name='Help' className={s.icon} />
                  <Text type='headline' size='s'>{intl.formatMessage(titleMessages.helpCenter)}</Text>
                </button>
              </NavigationMenu.Item>
              {permissions.isSuperUser && (
                <MenuItem
                  label={intl.formatMessage(titleMessages.admin)}
                  icon='UserAdmin'
                  route={ADMIN_USERS_ROUTE}
                  to={ADMIN_TAB_ROUTES[lastAdminTab]}
                />
              )}
              <MenuItem
                label={intl.formatMessage(titleMessages.settings)}
                icon='Settings'
                route={SETTINGS_GENERAL_ROUTE}
              />
              <NavigationMenu.Item value={LOGOUT_ROUTE} className={s.item}>
                <button id='btn-logout' type='button' onClick={logout}>
                  <Icon name='Logout' className={s.icon} />
                  <Text type='headline' size='s'>{intl.formatMessage(globalMessages.logout)}</Text>
                </button>
              </NavigationMenu.Item>
            </NavigationMenu.List>
          </NavigationMenu.Sub>
        </NavigationMenu.Root>
      )}
      <Button
        onClick={toggleMenu}
        className={cn(s.toggler, settings.sidebarCollapsed ? s.rotated : null)}
        icon='ChevronLeft'
        size='sm'
      />
    </aside>
  )
})
