import { differenceInDays, parseISO } from 'date-fns'
import { usePageSettings } from 'hooks/usePageSettings'
import { Filter } from './useAvailableFilters'
import { FLOATING_RANGE } from 'constants/index'
import { calculateFloatingRange } from 'utils/datetime'

const useChartGrouping = (data) => {
  const { settings } = usePageSettings()
  const range = settings[Filter.DATE_RANGE]?.option === FLOATING_RANGE
    ? calculateFloatingRange(settings[Filter.DATE_RANGE]?.value, true)
    : settings[Filter.DATE_RANGE]?.value

  if (!range) return 'date'

  const diff = differenceInDays(parseISO(range[1]), parseISO(range[0]))
  const dataKeys = data ? Object.keys(data) : null

  const timeKeyRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}/

  if (diff > 100) {
    return 'week'
  } else if (diff === 0 && (dataKeys == null || (dataKeys.length > 0 && (dataKeys ? dataKeys.some(key => timeKeyRegex.test(key)) : false)))) {
    return 'datehour'
  }
  return 'date'
}

export default useChartGrouping
