import { Button } from 'components/Button'
import { Text } from 'components/Primitives'
import globalMessages from 'components/globalMessages'
import labelMessages from 'components/labelMessages'
import { getConfigTitle } from 'utils'

const { default: Heading } = require('components/Heading')
const { memo } = require('react')
const { FormattedMessage, useIntl } = require('react-intl')

export const ConfigTableHeader = memo(({ tiny, category, tableType, onAdd, onBulkEdit, selectionLength }) => {
  const intl = useIntl()
  let description

  if (category === 'order') {
    switch (tableType) {
      default:
        description = <FormattedMessage id='OrderConfigTable.quantileConfigDescription' defaultMessage='In this table, you can control the planning quantities. This can be configured via Quantile ("the item should be available on 90% of the days until closing"), Absolute Target Returns ("the item should normally return with 5 pieces") and the Relative Target Returns (Sold) ("the item should normally achieve a return amount rate of 10%"). Important: Sales items should be configured here, not the order items. The quantities for the order items are automatically generated by the quantities for the sales items.' />
        break
      case 'fulfillmentDelay':
        description = <FormattedMessage id='OrderConfigTable.fulfillmentDelayConfigDescription' defaultMessage='In this table, you can specify a fulfillment delay for order items, in case they cannot go on sale immediately on the fulfillment date. An example of this could be a bread item that first has to go into the fermentation oven until the next day before it can be sold.' />
        break
      case 'orderFactor':
        description = <FormattedMessage id='OrderConfigTable.orderFactorConfigDescription' defaultMessage='In this table, you can store a factor for various order items that produce the same sales item, otherwise the full quantity will be ordered for each order item. An example of this could be that the sales item can be ordered fresh, but a frozen version of the item can also be ordered. For this, it would make sense to store factors for the two items that add up to 100% (e.g., 30% fresh, 70% frozen).' />
        break
      case 'ordersEnabled':
        description = <FormattedMessage id='OrderConfigTable.ordersEnabledConfigDescription' defaultMessage='In this table, you can enable or disable the ordering of the affected order items.' />
        break
      case 'referenceItem':
        description = <FormattedMessage id='OrderConfigTable.referenceItemConfigDescription' defaultMessage='In this table, you can specify a reference item which will be used to improve forcasting if there is not enough historical data available.' />
        break
      case 'batchRounding':
        description = <FormattedMessage tagName='p' id='OrderConfigTable.batchRoundingConfigDescription' defaultMessage='In this table, you can configure batch size rounding. Use cutoff-based rounding to set a percentage cutoff for rounding up (0% = always up, 50% = commercial rounding, 100% = always down). Alternatively, enable stochastic rounding to dynamically round up or down, ensuring the actual demand is met on average over time.' />
        break
      case 'multidayTracking':
        description = <FormattedMessage id='OrderConfigTable.multidayTrackingConfigDescription' defaultMessage='In this table, you can configure which items are treated as multi-day items. In this case the shelf life of the item is used to automatically calculate the stock at the time of delivery. If an item has a shelf life of two days and can only be ordered on Mondays and Wednesdays, we increase the order coverage for Monday to Monday and Tuesday.' />
        break
      case 'demandForecasting':
        description = <FormattedMessage id='OrderConfigTable.demandForecastingDescription' defaultMessage="In this table, you can configure how sales potential is estimated and whether it is used for forecasting.  The sales potential is an estimation of how much would have been sold if there hadn't been a stock out. It can either be calculated for each item individually, or for a group of substitutable items (marked by an item tag), in which case the stock out only occurs when all substitutable items stocked out as a whole. Forecasts can be enabled to use the sales potential instead of the sales amount." />
        break
      case 'forecastingV2':
        description = <FormattedMessage id='OrderConfigTable.forecastingV2ConfigDescription' defaultMessage='In this table, you can enable the Forecasting v2 preview features. When enabled, a new approach will be used to generate forecasts for the items.' />
        break
    }
  } else if (category === 'todo') {
    switch (tableType) {
      case 'totalQuantile':
      default:
        description = <FormattedMessage id='TodoConfigTable.totalQuantileConfigDescription' defaultMessage='In this table, you can configure the overall availability that should apply to the items. A value of 60% means that the item should be available on 60% of the days until closing. In other words, the item will be sold out on 40% of the days and will not generate any returns.' />
        break
      case 'initialTodos':
        description = <FormattedMessage id='TodoConfigTable.initialTodosConfigDescription' defaultMessage='In this table, you can configure the scheduled todos, which are created for the items the day before. "Scheduled due by" is the time at which the todos will be due, and "Schedule last until" is the time until which the quantities of the initial todos should last. The times can be specified absolutely in the format "HH:MM:SS" or relative to the opening time ("opening_time") or closing time ("closing_time"), for example in the format "opening_time+HH:MM:SS".' />
        break
      case 'scheduling':
        description = <FormattedMessage id='TodoConfigTable.schedulingConfigDescription' defaultMessage='In this table, you can configure the frequency of the todos. New todos will be generated when the item inventory amount will no longer be sufficient within the lead interval (e.g., within the next 2 hours) to ensure the lead quantile (e.g., the item should be available 90% of the time during the day). When a todo is generated, it will only become due after the expiry of the lead interval (e.g., in 2 hours). The production quantity in the todo will be so high that the next todo should not become due until after the expiration of the sustain interval. An availability can then also be configured for the sustain interval, which configures how sure you want to be that no task will come before the end of the sustain interval. The intervals are configured in seconds (1 hour = 3600 seconds).' />
        break
      case 'inventoryGroup':
        description = <FormattedMessage id='TodoConfigTable.inventoryGroupConfigDescription' defaultMessage='In this table, all items of a todo config group can be treated in such a way that new todos are only generated for the respective items when all items of the group would need a new todo.' />
        break
    }
  }

  return (
    <div className='flex justify-between items-center mt-3 mb-3'>
      <div>
        <Heading type='h5' id={`${category}-${tableType}`.replaceAll(' ', '-').toLowerCase()}>{getConfigTitle(intl, category, tableType)}</Heading>
        {!tiny && (
          <p className='mt-1 max-w-4xl'>
            <Text size='s' color='gray'>
              {description}
            </Text>
          </p>
        )}
      </div>
      <div className='flex ml-2'>
        {!tiny && (<Button icon='Add' onClick={onAdd}><FormattedMessage {...labelMessages.addConfig} /></Button>)}
        {!tiny && onBulkEdit && selectionLength > 0 && (
          <Button
            onClick={onBulkEdit}
            icon='Edit'
            data-action={`bulk-edit-${tableType}`}
            className='ml-2'
          >
            {selectionLength ? intl.formatMessage(globalMessages.bulkEditCount, { count: selectionLength }) : intl.formatMessage(globalMessages.bulkEdit)}
          </Button>)}
      </div>
    </div>
  )
})
