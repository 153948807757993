import { OperatorPanel } from 'components/OperatorPanel'
import s from './TopPanelContainer.module.scss'

export const TopPanelContainer = () => {
  return (
    <div className={s.root}>
      <OperatorPanel />
    </div>
  )
}
