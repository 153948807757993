import { useDebounceEffect, useMount } from 'ahooks'
import { useCustomers, useCustomerSettings, useDebounce, useSelectedCustomer, useUser } from 'hooks'
import { useGeneralStore, useInitializationStore, useSettingsStore } from 'hooks/store'
import { useIntl } from 'react-intl'
import { init, Identify, identify, track } from '@amplitude/analytics-browser'
import { WebsocketConnectionStateToString } from 'components/Charts/utils'
import { getSettingsStatistics } from 'utils/settings'
import { useEffect, useMemo } from 'react'
import { getAccessCollection, guessName } from 'utils'
import config from 'config'
import { useLoadingPerformance } from 'hooks/useLoadingPerformance'
import { setAmplitudeVars, useAmplitudeStore } from 'hooks/store/useAmplitudeStore'
import { getEffectiveRange, getEnglishMetricLabel, getEnglishReportTypeLabel, getGroupLabel } from 'components/Report/utils'
import { calculateComparisonRange, formatISODate, getWeekdays } from 'utils/datetime'
import { find } from 'lodash'
import { getIntlInstanceForEn } from 'services/IntlService'

export const Amplitude = () => {
  const intl = useIntl()
  const { user } = useUser()
  const locale = useGeneralStore(state => state.locale)
  const amplitudeInitialized = useGeneralStore(state => state.amplitudeInitialized)
  const setAmplitudeInitialized = useGeneralStore(state => state.setAmplitudeInitialized)
  const socketState = useGeneralStore(state => state.socketState)
  const socketStateString = WebsocketConnectionStateToString(socketState)
  const setEnabled = useAmplitudeStore(state => state.setEnabled)

  const settings = useSettingsStore(state => state.settings)
  const globalSettings = useSettingsStore(state => state.globalSettings)
  const loadingDuration = useInitializationStore(state => state.loadingDuration)
  const customerSettings = useCustomerSettings()
  const stats = useMemo(() => getSettingsStatistics(settings, globalSettings, customerSettings), [settings, globalSettings, customerSettings])
  const debouncedStats = useDebounce(stats, { wait: 500 })
  const { data: customers } = useCustomers()

  const customerId = useSelectedCustomer()

  const customer = useMemo(() => {
    if (!user) return null
    if (!customerId || !customers) {
      return {
        id: typeof (customerId) === 'string' ? parseInt(customerId) : customerId,
        name: user.customer_name,
        type: user.customer_type
      }
    }
    return customers.find((c) => c.id == customerId)
  }, [customerId, customers, user])

  useMount(() => {
    if (!config.amplitude.apiKey) {
      console.warn('🔎 ~ Amplitude API key is not set. Skipping initialization.')
    } else {
      console.log('🔎 ~ Amplitude API key is set. Awaiting initialization...', config.amplitude.apiKey)
    }
  })

  useEffect(() => {
    if (config.amplitude.apiKey && user && customer && locale && loadingDuration != null && !amplitudeInitialized && debouncedStats && customerSettings && customerSettings.enableTracking && socketState !== null) {
      console.log('🔎 ~ Initializing Amplitude...')
      init(config.amplitude.apiKey,
        user.email,
        {
          serverUrl: 'https://docs.api.app.delicious-data.com/amplitude/2/httpapi',
          logLevel: 'WARN', // 'debug',
          appVersion: window.localStorage.getItem('lastVisitedVersion'),
          autocapture: {
            attribution: false,
            pageViews: false,
            sessions: true,
            formInteractions: false,
            fileDownloads: true,
            elementInteractions: false
          },
          trackingOptions: {
            ipAddress: true,
            language: false,
            platform: true
          }
        }).promise.then(() => {
        const i = new Identify()

        // Set user properties
        i.set('Name', guessName(user))
        i.set('User Role', user.role)
        i.set('Permissions', getAccessCollection(user, intl))
        i.set('Loading Duration', loadingDuration)
        i.set('Websocket', socketStateString)
        i.set('Language', locale)

        // TODO: Should we use user groups for this? https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2#user-groups
        // Set company properties
        i.set('Customer ID', customer.id)
        i.set('Customer Name', customer.name)
        i.set('Customer Type', customer.type)

        // Apply the identify call
        identify(i)

        setAmplitudeInitialized(true)
        setEnabled(true)
      })
    }
  }, [user, debouncedStats, loadingDuration, socketState, customerSettings, customer])

  // This hook updates the user properties in some cases
  useEffect(() => {
    if (!amplitudeInitialized) return
    if (!customer) return

    const i = new Identify()

    i.set('Websocket', socketStateString)
    i.set('Language', locale)
    i.set('Customer ID', customer.id)
    i.set('Customer Name', customer.name)
    i.set('Customer Type', customer.type)

    // Apply the identify call
    identify(i)
  }, [customer, locale, socketState])
}

/* Tracks a page visit after mount when placed in a route */
export const usePageTrack = (opts) => {
  const { prefix, suffix, suffixMessage, title, titleMessage, path, props } = opts || {}
  const enabled = useAmplitudeStore(state => state.enabled)
  useDebounceEffect(() => {
    const intl = getIntlInstanceForEn()
    if (!enabled) return

    let pageTitle
    if (title) {
      pageTitle = title
    } else if (titleMessage) {
      pageTitle = intl.formatMessage(titleMessage)
    } else {
      pageTitle = document.title
    }
    if (prefix) pageTitle = `${prefix} - ${pageTitle}`
    if (suffixMessage) {
      pageTitle = `${pageTitle} - ${intl.formatMessage(suffixMessage)}`
    } else if (suffix) {
      pageTitle = `${pageTitle} - ${suffix}`
    }

    const p = {
      '[Amplitude] Page Domain': window.location.hostname,
      '[Amplitude] Page Location': window.location.href,
      '[Amplitude] Page Path': window.location.pathname,
      '[Amplitude] Page Title': pageTitle,
      '[Amplitude] Page URL': path ? `${window.location.origin}/${path}` : window.location.href,
      ...props
    }
    console.log('🔎 ~ Page Viewed', p)

    track('[Amplitude] Page Viewed', p)
  }, [enabled], {
    wait: 500
  })
}

export const trackEvent = (eventName, props, vars) => {
  if (useAmplitudeStore.getState().enabled) {
    console.log('🔎 ~ Track Event', eventName, props)
    if (vars) {
      setAmplitudeVars(vars)
    }
    track(eventName, props)
  } else {
    console.log('🔎 ~ Skip Track Event', useAmplitudeStore.getState(), eventName, props)
  }
}

export const useAmplitudePickerLoadingPerformance = (type, fetchStatus, props) => {
  const loadingTime = useLoadingPerformance(fetchStatus)
  useEffect(() => {
    if (loadingTime) {
      trackEvent('Picker loaded', { Type: type, 'Loading Time': loadingTime, ...props })
    }
  }, [loadingTime])
}

export const getAmplitudeReportProps = (definition, title) => ({
  Name: title || definition.title,
  Type: getEnglishReportTypeLabel(definition.type),
  ID: definition.id,
  Metrics: definition.data.properties.map(p => getEnglishMetricLabel(p.propKey)),
  ...(definition.data.groupBy ? { 'Group By': Array.isArray(definition.data.groupBy) ? getGroupLabel(null, definition.data.groupBy, true, true) : [getGroupLabel(null, definition.data.groupBy, true, true)] } : undefined)
})

export const getAmplitudeReportFilterProps = (filters, properties) => {
  const weekdayStrings = getWeekdays('en-US')
  return {
    'Date Range': getEffectiveRange(filters, properties),
    'Comparison Range': filters.comparison ? calculateComparisonRange(filters.dateRange, filters.comparison).map(formatISODate) : undefined,
    Weekdays: filters.weekday ? filters.weekday.map(wd => find(weekdayStrings, { key: parseInt(wd) }).label) : undefined,
    Locations: filters.location,
    'Waste Categories': filters.foodwasteCategory,
    'Offering Group 1': filters.offeringGroup1Names,
    'Offering Group 2': filters.offeringGroup2Names,
    'Item Group 1': filters.itemGroup1,
    'Item Group 2': filters.itemGroup2,
    'Item Tags': filters.itemTag,
    Items: filters.item
  }
}
