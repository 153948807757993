import { create } from 'zustand'
import { v4 as uuidv4 } from 'uuid'
// import db64 from 'db64'

// Maximum number of notifications to store
const MAX_NOTIFICATIONS = 100

// DB64 store for notifications
const notificationsStore = null

/**
 * Initialize the db64 store for notifications
 * Called in init.js
 */
// export const initNotificationsStore = async () => {
//   try {
//     notificationsStore = await db64.use('cache', 'notifications')
//     // Load stored notifications from IndexedDB
//     const storedData = await notificationsStore.getEntries(['notifications', 'unreadCount'])
//     if (storedData) {
//       // Update the store state with the stored data
//       useNotificationStore.setState({
//         notifications: storedData.notifications || [],
//         unreadCount: storedData.unreadCount || 0
//       })
//     }

//     return true
//   } catch (error) {
//     console.error('Error initializing notification store:', error)
//     return false
//   }
// }

/**
 * Persist the current state to IndexedDB
 */
const persistState = async () => {
  if (notificationsStore) {
    try {
      // Always get the current state from the store
      const currentState = useNotificationStore.getState()
      await notificationsStore.setEntries({
        notifications: currentState.notifications.slice(0, MAX_NOTIFICATIONS), // Limit the number
        unreadCount: currentState.unreadCount
      })
    } catch (error) {
      console.error('Error saving notifications:', error)
    }
  }
}

/**
 * Store for managing notifications
 * Stores notifications in IndexedDB for persistence across browser refreshes
 */
export const useNotificationStore = create((set, get) => ({
  // Array to store all notifications
  notifications: [],
  // Count of unread notifications
  unreadCount: 0,

  /**
   * Add a new notification to the store
   * @param {string} type - Type of notification (info, success, warning, error)
   * @param {string} code - Internal code of the notification
   * @param {object} metadata - Additional data related to the notification
   * @param {string} id - Optional ID for the notification (generates UUID if not provided)
   * @returns {string} The ID of the created notification
   */
  addNotification: (type, code, metadata = {}, id = uuidv4()) => {
    const notification = {
      id,
      type,
      code,
      metadata,
      timestamp: new Date().toISOString(),
      read: false
    }

    const newState = (state) => ({
      notifications: [notification, ...state.notifications],
      unreadCount: state.unreadCount + 1
    })

    set(newState)
    persistState()

    return id
  },

  /**
   * Mark a notification as read
   * @param {string} id - ID of the notification to mark as read
   */
  markAsRead: (id) => {
    const currentState = useNotificationStore.getState()
    const notification = currentState.notifications.find(n => n.id === id)
    const wasUnread = notification?.read === false

    const newState = (state) => ({
      notifications: state.notifications.map((n) =>
        n.id === id
          ? { ...n, read: true }
          : n
      ),
      unreadCount: wasUnread ? state.unreadCount - 1 : state.unreadCount
    })

    set(newState)
    persistState()
  },

  /**
   * Mark a notification as unread
   * @param {string} id - ID of the notification to mark as unread
   */
  markAsUnread: (id) => {
    const currentState = useNotificationStore.getState()
    const notification = currentState.notifications.find(n => n.id === id)
    const wasRead = notification?.read === true

    const newState = (state) => ({
      notifications: state.notifications.map((n) =>
        n.id === id
          ? { ...n, read: false }
          : n
      ),
      unreadCount: wasRead ? state.unreadCount + 1 : state.unreadCount
    })

    set(newState)
    persistState()
  },

  /**
   * Mark all notifications as read
   */
  markAllAsRead: () => {
    const newState = (state) => ({
      notifications: state.notifications.map((notification) => ({
        ...notification,
        read: true
      })),
      unreadCount: 0
    })

    set(newState)
    persistState()
  },

  /**
   * Remove a notification from the store
   * @param {string} id - ID of the notification to remove
   */
  removeNotification: (id) => {
    const newState = (state) => {
      const isUnread = state.notifications.find(n => n.id === id)?.read === false
      return {
        notifications: state.notifications.filter((notification) => notification.id !== id),
        unreadCount: isUnread ? state.unreadCount - 1 : state.unreadCount
      }
    }

    set(newState)
    persistState()
  },

  /**
   * Remove all notifications from the store
   */
  clearNotifications: () => {
    const newState = { notifications: [], unreadCount: 0 }
    set(newState)
    persistState()
  },

  /**
   * Calculate and update the unread count
   * This is useful if you need to recalculate the count after operations
   * that might have affected it
   */
  recalculateUnreadCount: () => {
    const newState = (state) => ({
      unreadCount: state.notifications.filter((notification) => !notification.read).length
    })

    set(newState)
    persistState()
  }
}))
