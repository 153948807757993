import classNames from "classnames"
import { useEffect, useRef } from "react"
import s from "./GameLoader.module.scss"
import SmallLogo from 'static/logo-px.png'
import { Text } from "components/Primitives"
import notifyMessages from "components/notifyMessages"
import { Alert } from "components/Alert"
import { FormattedMessage } from "react-intl"

export const GameLoader = ({ className, message, error }) => {
  const canvasRef = useRef(null)
  const gameStateRef = useRef({
    paddle: {
      x: 0,
      width: 100,
      height: 15,
      glowing: false,
      glowTime: 0,
      glowDuration: 50, // ms the paddle will glow
    },
    logos: [],
    particles: [], // For explosion effects
    animationFrameId: null,
    lastLogoSpawn: 0,
    spawnInterval: 800, // ms between logo spawns
  })

  // Score update functions outside useEffect with scoreRef
  const scoreRef = useRef(0)

  // Function to increase score and activate paddle glow
  // If delayed is true, it won't increase the score immediately
  const increaseScore = (delayed = false) => {
    if (!delayed) {
      scoreRef.current += 1
    }

    // Activate paddle glow effect
    gameStateRef.current.paddle.glowing = true;
    gameStateRef.current.paddle.glowTime = Date.now();
  }

  // Function to decrease score (but not below 0)
  const decreaseScore = () => {
    if (scoreRef.current > 0) {
      scoreRef.current -= 1
    }
  }

  // Initialize game
  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    const gameState = gameStateRef.current

    // Set canvas size
    const resizeCanvas = () => {
      canvas.width = canvas.clientWidth
      canvas.height = canvas.clientHeight
      gameState.paddle.x = canvas.width / 2 - gameState.paddle.width / 2
    }

    // Event listener for mouse movements
    const handleMouseMove = (e) => {
      const rect = canvas.getBoundingClientRect()
      const mouseX = e.clientX - rect.left

      // Paddle-Position aktualisieren
      gameState.paddle.x = mouseX - gameState.paddle.width / 2

      // Paddle im Canvas halten
      if (gameState.paddle.x < 0) {
        gameState.paddle.x = 0
      } else if (gameState.paddle.x + gameState.paddle.width > canvas.width) {
        gameState.paddle.x = canvas.width - gameState.paddle.width
      }
    }

    // Touch event handler
    const handleTouchMove = (e) => {
      e.preventDefault()
      const rect = canvas.getBoundingClientRect()
      const touchX = e.touches[0].clientX - rect.left

      // Paddle-Position aktualisieren
      gameState.paddle.x = touchX - gameState.paddle.width / 2

      // Paddle im Canvas halten
      if (gameState.paddle.x < 0) {
        gameState.paddle.x = 0
      } else if (gameState.paddle.x + gameState.paddle.width > canvas.width) {
        gameState.paddle.x = canvas.width - gameState.paddle.width
      }
    }

    // Create logo
    const createLogo = () => {
      const baseSize = 30 + Math.random() * 20 // Random base size
      const aspectRatio = 82 / 106 // Original logo aspect ratio (width/height)
      const width = baseSize * aspectRatio
      const height = baseSize
      const initialSpeed = 1 + Math.random() * 2 // Initial speed
      const rotationSpeed = initialSpeed * 0.05 // Rotation speed based on fall speed
      const gravity = 0.05 + (Math.random() * 0.1) // Random gravity factor

      return {
        x: Math.random() * (canvas.width - width),
        y: -height,
        width,
        height,
        speed: initialSpeed,
        initialSpeed, // Keep track of initial speed
        maxSpeed: 5 + Math.random() * 3, // Maximum speed cap
        gravity, // Add gravity to increase speed over time
        rotation: 0,
        rotationSpeed,
      }
    }

    // Neues Logo spawnen
    const spawnLogo = (timestamp) => {
      if (timestamp - gameState.lastLogoSpawn > gameState.spawnInterval) {
        gameState.logos.push(createLogo())
        gameState.lastLogoSpawn = timestamp
      }
    }

    // Logo zeichnen
    const drawLogo = (logo) => {
      ctx.save()
      ctx.translate(logo.x + logo.width / 2, logo.y + logo.height / 2)
      ctx.rotate(logo.rotation)
      ctx.drawImage(
        document.getElementById('game-logo'),
        -logo.width / 2,
        -logo.height / 2,
        logo.width,
        logo.height
      )
      ctx.restore()
    }

    // Draw paddle with optional glow effect - pixel art style
    const drawPaddle = () => {
      const paddleX = gameState.paddle.x;
      const paddleY = canvas.height - gameState.paddle.height;
      const paddleWidth = gameState.paddle.width;
      const paddleHeight = gameState.paddle.height;

      // Check if paddle should be glowing
      const now = Date.now();
      const glowTimeElapsed = now - gameState.paddle.glowTime;
      const isGlowing = gameState.paddle.glowing && glowTimeElapsed < gameState.paddle.glowDuration;

      // Turn off glow if time elapsed
      if (gameState.paddle.glowing && glowTimeElapsed >= gameState.paddle.glowDuration) {
        gameState.paddle.glowing = false;
      }

      // Set fill color based on glow state
      if (isGlowing) {
        ctx.fillStyle = '#4094da'; // paddle color
      } else {
        // Normal color
        ctx.fillStyle = '#1c5593'; // primary.DEFAULT
      }

      // Draw simple rectangle for pixel art style
      ctx.fillRect(paddleX, paddleY, paddleWidth, paddleHeight);
    }

    // Get score position (for particles to target)
    const getScorePosition = () => {
      return { x: 30, y: 30 }; // Center of the score text
    }

    // Display score
    const drawScore = () => {
      // Calculate score width for particle targeting
      ctx.font = '20px Lato, sans-serif';
      const scoreText = scoreRef.current.toString();
      const scoreWidth = ctx.measureText(scoreText).width;

      // Draw score
      ctx.fillStyle = '#1c5593'; // primary color
      ctx.textAlign = 'left';
      ctx.fillText(scoreText, 10, 30);

      // Store score dimensions for particle targeting
      gameState.scoreArea = {
        x: 10,
        y: 10,
        width: scoreWidth,
        height: 25
      };
    }

    // Collision detection
    const checkCollision = (logo) => {
      const paddleY = canvas.height - gameState.paddle.height

      // Check if the logo hits the paddle
      return (
        logo.y + logo.height > paddleY &&
        logo.y < paddleY + gameState.paddle.height &&
        logo.x + logo.width > gameState.paddle.x &&
        logo.x < gameState.paddle.x + gameState.paddle.width
      )
    }

    // Create explosion particles for caught logos with fancy effects
    // These particles will fly toward the score and then increase it
    const createScoreExplosion = (x, y, size) => {
      // Track if this explosion's particles have reached the score
      const explosionId = Date.now() + Math.random();
      gameState.pendingScoreExplosions = gameState.pendingScoreExplosions || {};
      gameState.pendingScoreExplosions[explosionId] = {
        particleCount: 0,
        particlesArrived: 0,
        scoreIncreased: false
      };

      const particleCount = 15 + Math.floor(Math.random() * 8); // Reduced total particles

      const colors = ['#000000', '#008d96', '#00f182', '#ff007b', '#ff2d00', '#ff0035'];

      // Create different types of particles for a more interesting explosion

      // 1. Fast outward particles (initial blast) - some will fly to score
      for (let i = 0; i < particleCount * 0.4; i++) {
        const angle = Math.random() * Math.PI * 2;
        const speed = 2 + Math.random() * 4; // Faster initial speed
        const radius = 3 + Math.random() * 3; // Bigger particles (was 1.5-4, now 3-6)

        // Determine if this particle will fly to score (about 50% will now)
        const flyToScore = Math.random() < 0.5; // Increased chance to fly to score
        // Longer life for particles that will fly to score
        const life = flyToScore ? 60 + Math.random() * 40 : 20 + Math.random() * 15;
        const color = colors[Math.floor(Math.random() * colors.length)];

        if (flyToScore) {
          // Count this particle for score tracking
          gameState.pendingScoreExplosions[explosionId].particleCount++;
        }

        gameState.particles.push({
          x,
          y,
          vx: Math.cos(angle) * speed,
          vy: Math.sin(angle) * speed,
          radius,
          color,
          life,
          maxLife: life,
          type: 'explosion',
          gravity: 0.1, // Add gravity
          friction: 0.97, // Add air friction to slow down over time
          fadeSize: true, // Particles will shrink as they fade
          flyToScore: flyToScore, // This particle will fly to score
          flyDelay: 20 + Math.floor(Math.random() * 30), // Longer delay before flying to score
          explosionId: explosionId // Track which explosion this particle belongs to
        });
      }

      // 2. Slower particles with more gravity (falling debris) - some will fly to score
      for (let i = 0; i < particleCount * 0.3; i++) {
        const angle = Math.random() * Math.PI * 2;
        const speed = 1 + Math.random() * 2;
        const radius = 3.5 + Math.random() * 3.5; // Bigger particles (was 2-5, now 3.5-7)

        // Determine if this particle will fly to score (about 40% will now)
        const flyToScore = Math.random() < 0.4; // Increased chance to fly to score
        // Longer life for particles that will fly to score
        const life = flyToScore ? 70 + Math.random() * 50 : 30 + Math.random() * 25;
        const color = colors[Math.floor(Math.random() * colors.length)];

        if (flyToScore) {
          // Count this particle for score tracking
          gameState.pendingScoreExplosions[explosionId].particleCount++;
        }

        gameState.particles.push({
          x,
          y,
          vx: Math.cos(angle) * speed,
          vy: Math.sin(angle) * speed - 2, // Initial upward boost
          radius,
          color,
          life,
          maxLife: life,
          type: 'explosion',
          gravity: 0.15, // More gravity for these particles
          bounce: !flyToScore && Math.random() > 0.5, // Only non-flying particles can bounce
          bounceFactor: 0.4 + Math.random() * 0.3, // How much they bounce
          fadeSize: true,
          flyToScore: flyToScore, // This particle will fly to score
          flyDelay: 25 + Math.floor(Math.random() * 35), // Longer delay before flying to score
          explosionId: explosionId // Track which explosion this particle belongs to
        });
      }

      // Increase the number of other particles to compensate for removing sparkles
      // Add a few more fast particles that will fly to score
      for (let i = 0; i < particleCount * 0.2; i++) {
        const angle = Math.random() * Math.PI * 2;
        const speed = 2.5 + Math.random() * 4.5; // Even faster speed
        const radius = 3.5 + Math.random() * 3; // Bigger particles

        // Higher chance to fly to score
        const flyToScore = Math.random() < 0.7;
        // Longer life for particles that will fly to score
        const life = flyToScore ? 70 + Math.random() * 50 : 30 + Math.random() * 20;
        // Use only logo colors
        const color = colors[Math.floor(Math.random() * colors.length)];

        if (flyToScore) {
          // Count this particle for score tracking
          gameState.pendingScoreExplosions[explosionId].particleCount++;
        }

        gameState.particles.push({
          x,
          y,
          vx: Math.cos(angle) * speed,
          vy: Math.sin(angle) * speed,
          radius,
          color,
          life,
          maxLife: life,
          type: 'explosion',
          gravity: 0.08, // Medium gravity
          friction: 0.98, // Less friction for faster particles
          fadeSize: true,
          flyToScore: flyToScore,
          flyDelay: 10 + Math.floor(Math.random() * 20), // Shorter delay
          explosionId: explosionId
        });
      }
    }

    // Regular explosion (not for score) - used for other effects
    const createExplosion = (x, y, size) => {
      // More particles for a more impressive explosion
      const particleCount = 25 + Math.floor(Math.random() * 15);
      const colors = ['#008495', '#00af8c', '#00e980', '#ff0055', '#ff6241', '#ff5b56', '#ff3db2', '#ff4d80']; // Brand colors

      // Create different types of particles for a more interesting explosion

      // 1. Fast outward particles (initial blast)
      for (let i = 0; i < particleCount * 0.4; i++) {
        const angle = Math.random() * Math.PI * 2;
        const speed = 2 + Math.random() * 4; // Faster initial speed
        const radius = 1.5 + Math.random() * 2.5;
        const life = 20 + Math.random() * 15;
        const color = colors[Math.floor(Math.random() * colors.length)];

        gameState.particles.push({
          x,
          y,
          vx: Math.cos(angle) * speed,
          vy: Math.sin(angle) * speed,
          radius,
          color,
          life,
          maxLife: life,
          type: 'explosion',
          gravity: 0.1, // Add gravity
          friction: 0.97, // Add air friction to slow down over time
          fadeSize: true // Particles will shrink as they fade
        });
      }

      // 2. Slower particles with more gravity (falling debris)
      for (let i = 0; i < particleCount * 0.3; i++) {
        const angle = Math.random() * Math.PI * 2;
        const speed = 1 + Math.random() * 2;
        const radius = 2 + Math.random() * 3;
        const life = 30 + Math.random() * 25;
        const color = colors[Math.floor(Math.random() * colors.length)];

        gameState.particles.push({
          x,
          y,
          vx: Math.cos(angle) * speed,
          vy: Math.sin(angle) * speed - 2, // Initial upward boost
          radius,
          color,
          life,
          maxLife: life,
          type: 'explosion',
          gravity: 0.15, // More gravity for these particles
          bounce: Math.random() > 0.5, // Some particles will bounce
          bounceFactor: 0.4 + Math.random() * 0.3, // How much they bounce
          fadeSize: true
        });
      }

      // 3. Sparkling particles (small, bright, longer-lived)
      for (let i = 0; i < particleCount * 0.3; i++) {
        const angle = Math.random() * Math.PI * 2;
        const speed = 0.5 + Math.random() * 2;
        const radius = 1 + Math.random() * 1.5;
        const life = 40 + Math.random() * 30; // Longer life
        // Use brighter colors for sparkles
        const sparkleColors = ['#ffffff', '#c1e3ff', '#ffffaa', '#ffddff'];
        const color = sparkleColors[Math.floor(Math.random() * sparkleColors.length)];

        gameState.particles.push({
          x,
          y,
          vx: Math.cos(angle) * speed,
          vy: Math.sin(angle) * speed,
          radius,
          color,
          life,
          maxLife: life,
          type: 'sparkle',
          gravity: 0.03, // Light gravity
          twinkle: true, // Will flicker in brightness
          twinkleSpeed: 0.05 + Math.random() * 0.1
        });
      }
    }

    // Create splatter effect for logos hitting the ground
    // impactFactor determines the size and intensity of the splatter
    const createSplatter = (x, y, width, height, impactFactor = 1.0) => {
      // Strawberry splatter colors - reds and pinks
      const colors = ['#000000', '#008d96', '#00f182', '#ff007b', '#ff2d00', '#ff0035'];

      // Adjust particle count based on impact factor (reduced overall count)
      const particleCount = Math.floor((10 + Math.floor(Math.random() * 6)) * impactFactor);

      // Create a puddle of particles that spread in all directions
      for (let i = 0; i < particleCount; i++) {
        // Full 360-degree random angle for more natural splatter
        const angle = Math.random() * Math.PI * 2;
        const speed = (0.4 + Math.random() * 1.5) * impactFactor; // Speed scales with impact (reduced)
        const radius = (0.8 + Math.random() * 2) * Math.sqrt(impactFactor); // Size scales with impact (reduced)
        const life = 20 + Math.random() * 15; // Shorter lifespan
        const color = colors[Math.floor(Math.random() * colors.length)];

        // Adjust horizontal/vertical speed ratio based on angle
        // This creates a more natural splatter pattern
        let vxMultiplier = 1.0;
        let vyMultiplier = 0.3; // Less vertical movement in general

        // For particles moving upward, reduce speed
        if (angle > Math.PI && angle < Math.PI * 2) {
          vyMultiplier = 0.2;
        }

        gameState.particles.push({
          x,
          y: y - 2, // Start slightly above ground for better visual
          vx: Math.cos(angle) * speed * vxMultiplier,
          vy: Math.sin(angle) * speed * vyMultiplier,
          radius,
          color,
          life,
          maxLife: life,
          type: 'splatter'
        });
      }

      // Add a few larger "juice" drops that splash more randomly
      // Number of drops scales with impact (reduced max count)
      const dropCount = Math.floor(3 + (impactFactor * 1.5));
      for (let i = 0; i < dropCount; i++) {
        const angle = Math.random() * Math.PI * 2; // Full 360 degrees
        const speed = (0.2 + Math.random() * 1.2) * impactFactor; // Reduced speed
        const radius = (1.5 + Math.random() * 2.5) * Math.sqrt(impactFactor); // Smaller drops
        const life = 15 + Math.random() * 10;
        const color = colors[Math.floor(Math.random() * colors.length)];

        // Vary the initial position slightly for more natural look
        // Higher impact = wider spread (but reduced overall)
        const offsetX = (Math.random() - 0.5) * (width * 0.6 * impactFactor);

        gameState.particles.push({
          x: x + offsetX, // Vary starting position
          y: y - 1,
          vx: Math.cos(angle) * speed * 1.2 * impactFactor, // Reduced horizontal velocity
          vy: Math.min(-0.08 * impactFactor, Math.sin(angle) * speed * 0.6), // Reduced vertical velocity
          radius,
          color,
          life,
          maxLife: life,
          type: 'splatter',
          gravity: 0.08 // Increased gravity for faster falling
        });
      }
    }

    // Update particles (move and fade) with advanced physics
    const updateParticles = () => {
      // Process pending score explosions
      if (gameState.pendingScoreExplosions) {
        Object.keys(gameState.pendingScoreExplosions).forEach(id => {
          const explosion = gameState.pendingScoreExplosions[id];
          // If all particles arrived and score not yet increased, increase it
          if (explosion.particleCount > 0 &&
            explosion.particlesArrived >= explosion.particleCount &&
            !explosion.scoreIncreased) {
            scoreRef.current += 1; // Actually increase the score now
            explosion.scoreIncreased = true;

            // Create a pixel art effect at the score using logo colors
            const scorePos = getScorePosition();
            for (let i = 0; i < 8; i++) { // Fewer but bigger particles
              const angle = Math.random() * Math.PI * 2;
              const speed = 0.5 + Math.random() * 1;
              const radius = 2.5 + Math.random() * 3; // Even bigger particles for pixel effect
              const life = 20 + Math.random() * 15; // Longer life for score effect
              // Use logo colors instead of sparkle colors
              const scoreColors = ['#008495', '#00af8c', '#00e980', '#ff0055', '#ff6241', '#ff5b56'];
              const color = scoreColors[Math.floor(Math.random() * scoreColors.length)];

              gameState.particles.push({
                x: scorePos.x + (Math.random() - 0.5) * 20,
                y: scorePos.y + (Math.random() - 0.5) * 10,
                vx: Math.cos(angle) * speed,
                vy: Math.sin(angle) * speed,
                radius,
                color,
                life,
                maxLife: life,
                type: 'explosion', // Changed from sparkle to explosion
                fadeSize: true
              });
            }

            // Clean up this explosion tracking
            delete gameState.pendingScoreExplosions[id];
          }
        });
      }

      for (let i = gameState.particles.length - 1; i >= 0; i--) {
        const particle = gameState.particles[i];

        // Handle particles that need to fly to score
        if (particle.flyToScore) {
          // Wait for fly delay before heading to score
          if (particle.flyDelay > 0) {
            particle.flyDelay--;
          } else {
            // Get target position (score)
            const scorePos = getScorePosition();

            // Calculate direction to score
            const dx = scorePos.x - particle.x;
            const dy = scorePos.y - particle.y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            // If close to score, mark as arrived and remove
            if (distance < 10) {
              // Mark this particle as arrived at score
              if (particle.explosionId && gameState.pendingScoreExplosions &&
                gameState.pendingScoreExplosions[particle.explosionId]) {
                gameState.pendingScoreExplosions[particle.explosionId].particlesArrived++;
              }

              // Remove the particle
              gameState.particles.splice(i, 1);
              continue;
            }

            // Otherwise fly toward score with increasing speed
            // Much faster speed for particles flying to score
            const speed = Math.min(20, 8 + (120 / distance)); // Higher base speed and acceleration
            particle.vx = (dx / distance) * speed;
            particle.vy = (dy / distance) * speed;

            // Add slight randomness to movement for more natural flow
            particle.vx += (Math.random() - 0.5) * 0.5;
            particle.vy += (Math.random() - 0.5) * 0.5;

            // Make particles more visible when flying to score
            particle.life = Math.max(particle.life, 30); // Ensure particles don't die too quickly

            // Make particles larger and brighter as they approach the score
            if (distance < 100) {
              // More dramatic size increase as they get closer, but maintain pixel art feel
              particle.radius = Math.round(particle.radius * 1.015 * 2) / 2; // Round to nearest 0.5

              // Ensure minimum size for pixel art feel
              particle.radius = Math.max(2, particle.radius);

              // Add trail effect for fast-moving particles
              if (Math.random() < 0.1 && gameState.particles.length < 500) { // Limit trail particles
                const trailColor = particle.color;
                gameState.particles.push({
                  x: particle.x,
                  y: particle.y,
                  vx: 0,
                  vy: 0,
                  radius: Math.max(1.5, particle.radius * 0.7), // Ensure minimum size for pixel art
                  originalRadius: Math.max(1.5, particle.radius * 0.7),
                  color: trailColor,
                  life: 5 + Math.random() * 5,
                  maxLife: 10,
                  type: 'trail',
                  fadeSize: true
                });
              }
            }

            // Disable other physics while flying to score
            particle.gravity = 0;
            particle.bounce = false;
          }
        } else {
          // Regular particle physics
          // Apply friction if particle has it
          if (particle.friction) {
            particle.vx *= particle.friction;
            particle.vy *= particle.friction;
          }

          // Apply gravity if particle has it
          if (particle.gravity) {
            particle.vy += particle.gravity;
          }

          // Handle bouncing off the ground
          if (particle.bounce && particle.y > canvas.height - particle.radius) {
            particle.y = canvas.height - particle.radius;
            particle.vy = -particle.vy * particle.bounceFactor; // Reverse velocity with damping

            // If bounce is too small, stop bouncing
            if (Math.abs(particle.vy) < 0.5) {
              particle.bounce = false;
              particle.vy = 0;
              particle.vx *= 0.9; // Slow down when stopped bouncing
            }
          }

          // Splatter particles should stick to the ground
          if (particle.type === 'splatter' && particle.y > canvas.height - 2) {
            particle.y = canvas.height - 2;
            particle.vy = 0;
            particle.vx *= 0.9; // Slow down horizontal movement
          }
        }

        // Move particle
        particle.x += particle.vx;
        particle.y += particle.vy;

        // Reduce life
        particle.life--;

        // Remove dead particles
        if (particle.life <= 0) {
          // If this was a score particle that didn't make it, still count it as arrived
          if (particle.flyToScore && particle.explosionId &&
            gameState.pendingScoreExplosions &&
            gameState.pendingScoreExplosions[particle.explosionId]) {
            gameState.pendingScoreExplosions[particle.explosionId].particlesArrived++;
          }

          gameState.particles.splice(i, 1);
        }
      }
    }

    // Draw particles as squares for pixel art style with special effects
    const drawParticles = () => {
      for (let i = 0; i < gameState.particles.length; i++) {
        const particle = gameState.particles[i];
        let alpha = particle.life / particle.maxLife;

        // Twinkle effect for sparkle particles
        if (particle.twinkle) {
          // Create a pulsing/twinkling effect
          const twinkleFactor = Math.sin(Date.now() * particle.twinkleSpeed) * 0.3 + 0.7;
          alpha *= twinkleFactor;
        }

        ctx.globalAlpha = alpha;
        ctx.fillStyle = particle.color;

        // Calculate particle size (may shrink over time if fadeSize is true)
        let currentRadius = particle.radius;
        if (particle.fadeSize) {
          // Particle gets smaller as it fades out, but maintain pixel art feel
          // Much slower shrinking for pixel art style
          if (!particle.originalRadius) {
            particle.originalRadius = particle.radius;
          }

          // For particles flying to score, keep them larger
          if (particle.flyToScore && particle.flyingToScore) {
            // Keep a minimum size for particles flying to score
            currentRadius = Math.max(particle.originalRadius * 0.7,
              particle.radius * (0.7 + 0.3 * (particle.life / particle.maxLife)));
          } else {
            // For other particles, allow more shrinking but still keep pixel art feel
            currentRadius = Math.max(particle.originalRadius * 0.5,
              particle.radius * (0.5 + 0.5 * (particle.life / particle.maxLife)));
          }

          // Quantize radius to maintain pixel art feel (round to nearest 0.5)
          currentRadius = Math.round(currentRadius * 2) / 2;
          currentRadius = Math.max(1.5, currentRadius); // Ensure minimum size for pixel art
        }

        const size = currentRadius * 2;

        // Splatter particles are slightly different - more flat when on ground
        if (particle.type === 'splatter' && particle.y >= canvas.height - 4) {
          // Make particles flatter when they hit the ground
          const flattenedHeight = size * 0.7;
          ctx.fillRect(
            particle.x - currentRadius,
            particle.y - flattenedHeight / 2,
            size,
            flattenedHeight
          );
        }
        // Sparkle particles can have a glow effect
        else if (particle.type === 'sparkle' && alpha > 0.6) {
          // Draw a slightly larger, more transparent square behind for glow effect
          const glowAlpha = ctx.globalAlpha * 0.5;
          ctx.globalAlpha = glowAlpha;
          ctx.fillRect(
            particle.x - currentRadius - 1,
            particle.y - currentRadius - 1,
            size + 2,
            size + 2
          );
          ctx.globalAlpha = alpha; // Restore original alpha
          ctx.fillRect(particle.x - currentRadius, particle.y - currentRadius, size, size);
        }
        // Regular particles
        else {
          ctx.fillRect(particle.x - currentRadius, particle.y - currentRadius, size, size);
        }
      }

      // Reset alpha
      ctx.globalAlpha = 1;
    }

    // Game Loop
    const gameLoop = (timestamp) => {

      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Spawn new logo
      spawnLogo(timestamp)

      // Update and draw logos
      for (let i = gameState.logos.length - 1; i >= 0; i--) {
        const logo = gameState.logos[i]

        // Apply gravity to increase speed
        if (logo.speed < logo.maxSpeed) {
          logo.speed += logo.gravity
        }

        // Move logo
        logo.y += logo.speed
        logo.rotation += logo.rotationSpeed

        // Check collision with paddle
        if (checkCollision(logo)) {
          // Create explosion particles at logo position that will fly to the score
          createScoreExplosion(logo.x + logo.width / 2, logo.y + logo.height / 2, Math.max(logo.width, logo.height) / 2);

          // Remove logo but don't increase score yet - particles will do that
          gameState.logos.splice(i, 1);
          increaseScore(true); // Just activate paddle glow, score will increase when particles reach score
          continue;
        }

        // Check if logo hits the ground
        if (logo.y + logo.height >= canvas.height) {
          // Calculate impact factor based on speed
          // Higher speed = bigger splatter, but with reduced maximum impact
          const impactFactor = Math.min(1.8, Math.max(1.0, (logo.speed / logo.initialSpeed) * 0.8));

          // Create splatter effect at the bottom with size based on impact
          createSplatter(
            logo.x + logo.width / 2,
            canvas.height,
            logo.width,
            logo.height,
            impactFactor
          );

          // Remove logo and decrease score
          gameState.logos.splice(i, 1);
          decreaseScore(); // Decrease score when logo is missed
          continue;
        }

        // Draw logo
        drawLogo(logo)
      }

      // Update and draw particles
      updateParticles();
      drawParticles();

      // Draw paddle and score
      drawPaddle();
      drawScore()

      // Request next frame
      gameState.animationFrameId = requestAnimationFrame(gameLoop)
    }

    // Add event listeners
    window.addEventListener('resize', resizeCanvas)
    canvas.addEventListener('mousemove', handleMouseMove)
    canvas.addEventListener('touchmove', handleTouchMove, { passive: false })

    // Initialize canvas and start game loop
    resizeCanvas()
    gameState.animationFrameId = requestAnimationFrame(gameLoop)

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', resizeCanvas)
      canvas.removeEventListener('mousemove', handleMouseMove)
      canvas.removeEventListener('touchmove', handleTouchMove)
      cancelAnimationFrame(gameState.animationFrameId)
    }
  }, []) // No dependencies needed

  const renderTextContent = () => {
    if (!error && !message) return null
    if (error) {
      return <Alert
        type='error'
        showIcon
        message={<FormattedMessage {...notifyMessages.failed} />}
        description={error}
      />
    }
    return <Text size="m" className={s.message}>{message}</Text>
  }

  return (
    <div className={classNames(className, s.root)}>
      <img id="game-logo" src={SmallLogo} alt="Logo" style={{ display: 'none' }} />
      <div className={s.gameContainer}>
        <canvas ref={canvasRef} className={s.gameCanvas} />
      </div>
      {(message || error) && (
        <div className={s.messageContainer}>
          {renderTextContent()}
        </div>
      )}
    </div>
  )
}