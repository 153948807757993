import s from './CellRenderer.module.scss'
import { Button } from 'components/Button'
import { Tooltip } from 'components/Primitives/Tooltip'

export const NumberWithButtonRenderer = (props) => {
  const { value, valueFormatted, data, colDef } = props
  if (value == null) return null
  const showButton = colDef.button.condition != null && colDef.button.onClick != null && colDef.button.condition(data)

  if (!showButton) {
    return (
      <div className={s.flexRightCell}>
        {valueFormatted}
      </div>
    )
  }

  const renderButton = () => {
    const buttonProps = {
      size: 'xs',
      type: 'neutral',
      icon: colDef.button.icon,
      onClick: () => colDef.button.onClick(props.data)
    }
    
    if (colDef.button.label) {
      return (
        <Tooltip title={colDef.button.label} triggerAsChild>
          <Button {...buttonProps} />
        </Tooltip>
      )
    }
    
    return <Button {...buttonProps} />
  }

  if (colDef.button.between) {
    return <div className={s.flexBetweenCell}>
      {renderButton()}
      <span>{valueFormatted}</span>
    </div>
  } else {
    return <div className={s.flexRightCell}>
      <span>{valueFormatted}</span>
      <div className='ml-2'>{renderButton()}</div>
    </div>
  }
}
