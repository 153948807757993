import { OVERPLAN_TYPES } from 'constants/index'
import * as yup from 'yup'

// Order Config Schemas
export const quantileSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    overplan_type: yup.string().oneOf([OVERPLAN_TYPES.QUANTILE, OVERPLAN_TYPES.ABSOLUTE_VALUE, OVERPLAN_TYPES.SALES_PERCENTAGE, OVERPLAN_TYPES.ORDER_PERCENTAGE]).required(),
    overplan_value: yup.mixed().test('overplan_type', 'Invalid Overplan Value', function (value) {
      const { overplan_type } = this.parent
      switch (overplan_type) {
        case OVERPLAN_TYPES.ORDER_PERCENTAGE:
          return typeof value === 'number' && value >= 0 && value <= 50
        case OVERPLAN_TYPES.QUANTILE:
          return typeof value === 'number' && value >= 0 && value <= 100
        default:
          return typeof value === 'number'
      }
    })
  })

export const fulfillmentDelaySchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    fulfillment_delay: yup.number().required()
  })

export const orderFactorSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    order_factor: yup.number().required()
  })

export const orderEnabledSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    orders_enabled: yup.boolean()
  })

export const forecastingV2Schema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    forecasting_v2: yup.boolean()
  })

export const referenceItemSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    reference_item: yup.string().required()
  })

export const batchRoundingSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    batch_rounding_cutoff: yup.number()
      .min(0)
      .max(100)
      .nullable()
      .when('stochastic_rounding', {
        is: true,
        then: schema => schema.nullable(),
        otherwise: schema => schema.required()
      }),
    stochastic_rounding: yup.boolean()
  })

export const multidayTrackingSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    location_tag: yup.string().nullable(),
    multiday_tracking: yup.boolean(),
    inventory_tracking: yup.boolean()
  })

export const demandForecastingSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().required(),
    location: yup.number().nullable(),
    demand_forecasting: yup.boolean(),
    substitutability: yup.boolean()
  })

// Todo Config Schemas
export const totalQuantileSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    daily_quantile: yup.number().required().min(0).max(100)
  })

export const initialTodosSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    scheduled_due_by: yup.string().nullable(),
    scheduled_last_until: yup.string().nullable(),
    config: yup
      .mixed()
      .test(
        'at-least-one-field-set',
        'at-least-one-field-set',
        function () {
          const { scheduled_due_by, scheduled_last_until } = this.parent
          return Boolean(scheduled_due_by || scheduled_last_until)
        }
      )
  })

export const schedulingSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    lead_quantile: yup.number().nullable().min(0).max(100),
    lead_interval: yup.string().nullable(),
    sustain_quantile: yup.number().nullable().min(0).max(100),
    sustain_interval: yup.string().nullable(),
    config: yup
      .mixed()
      .test(
        'at-least-one-field-set',
        'at-least-one-field-set',
        function () {
          const { lead_quantile, lead_interval, sustain_quantile, sustain_interval } = this.parent
          return Boolean(lead_quantile || lead_interval || sustain_quantile || sustain_interval)
        }
      )
  })

export const inventoryGroupSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    inventory_group_enabled: yup.boolean()
  })

export const validationSchemas = {
  // Order Config Schemas
  quantile: quantileSchema,
  fulfillmentDelay: fulfillmentDelaySchema,
  orderFactor: orderFactorSchema,
  ordersEnabled: orderEnabledSchema,
  referenceItem: referenceItemSchema,
  batchRounding: batchRoundingSchema,
  multidayTracking: multidayTrackingSchema,
  demandForecasting: demandForecastingSchema,
  forecastingV2: forecastingV2Schema,

  // Todo Config Schemas
  totalQuantile: totalQuantileSchema,
  initialTodos: initialTodosSchema,
  scheduling: schedulingSchema,
  inventoryGroup: inventoryGroupSchema
}
