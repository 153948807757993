import { defineMessages } from 'react-intl'

export default defineMessages({
  demand: {
    id: 'reports.demand',
    defaultMessage: 'Sales potential'
  },
  lost_demand: {
    id: 'reports.lost_demand',
    defaultMessage: 'Lost sales potential'
  },
  demand_revenue: {
    id: 'reports.demand_revenue',
    defaultMessage: 'Revenue potential'
  },
  lost_demand_revenue: {
    id: 'reports.lost_demand_revenue',
    defaultMessage: 'Lost revenue potential'
  },
  numSales: {
    id: 'reports.numSales',
    defaultMessage: 'Sales No.'
  },
  avgTransactionRevenue: {
    id: 'reports.avgTransactionRevenue',
    defaultMessage: 'Ø revenue per transaction'
  },
  numVanished: {
    id: 'reports.numVanished',
    defaultMessage: 'Depreciated amount'
  },
  fullfilledAmount: {
    id: 'reports.fullfilledAmount',
    defaultMessage: 'Delivery amount'
  },
  orderedAmount: {
    id: 'reports.orderedAmount',
    defaultMessage: 'Order amount'
  },
  requestedAmount: {
    id: 'reports.requestedAmount',
    defaultMessage: 'Order proposal'
  },
  unsoldAmount: {
    id: 'reports.unsoldAmount',
    defaultMessage: 'Return amount'
  },
  returnRate: {
    id: 'reports.returnRate',
    defaultMessage: 'Return amount rate'
  },
  revenue: {
    id: 'reports.revenue',
    defaultMessage: 'Revenue'
  },
  cogsInEUR: {
    id: 'reports.cogsInEUR',
    defaultMessage: 'COGS in €'
  },
  cogsInPercent: {
    id: 'reports.cogsInPercent',
    defaultMessage: 'COGS in %'
  },
  transactionCount: {
    id: 'reports.transactionCount',
    defaultMessage: 'Transaction count'
  },
  foodwaste: {
    id: 'reports.foodwaste',
    defaultMessage: 'Food Waste'
  },
  costOfGoods: {
    id: 'reports.costOfGoods',
    defaultMessage: 'Cost of Goods'
  },
  co2: {
    id: 'reports.co2',
    defaultMessage: 'CO₂'
  },
  water: {
    id: 'reports.water',
    defaultMessage: 'Drinking Water'
  },
  amountPerSale: {
    id: 'reports.amountPerSale',
    defaultMessage: 'Amount per sale'
  },
  returnValue: {
    id: 'reports.returnValue',
    defaultMessage: 'Return value'
  },
  returnValueRate: {
    id: 'reports.returnValueRate',
    defaultMessage: 'Return value rate'
  },
  fulfilmentValue: {
    id: 'reports.fulfilmentValue',
    defaultMessage: 'Delivery value'
  },
  orderValue: {
    id: 'reports.orderValue',
    defaultMessage: 'Order value'
  },
  requestedValue: {
    id: 'reports.requestedValue',
    defaultMessage: 'Requested value'
  },
  vanishedValue: {
    id: 'reports.vanishedValue',
    defaultMessage: 'Depreciated value'
  },
  composition: {
    id: 'reports.compositionChart',
    defaultMessage: 'Composition'
  },
  metric: {
    id: 'reports.metric',
    defaultMessage: '{metric}'
  },
  metricByGroup: {
    id: 'reports.metricByGroup',
    defaultMessage: '{metric} by {group}'
  },
  nMetrics: {
    id: 'reports.nMetrics',
    defaultMessage: '{count} metrics'
  },
  nMetricsByGroup: {
    id: 'reports.nMetricsByGroup',
    defaultMessage: '{count} metrics by {group}'
  },
  differenceFromMetricByGroup: {
    id: 'reports.differenceFromMetricByGroup',
    defaultMessage: 'Difference from {metric} by {group}'
  },
  forecastAccuracy: {
    id: 'reports.forecastAccuracy',
    defaultMessage: 'Forecast accuracy'
  },
  forecast: {
    id: 'reports.forecast',
    defaultMessage: 'Forecast'
  },
  ordersOverview: {
    id: 'reports.ordersOverview',
    defaultMessage: 'Orders overview'
  },
  exportReport: {
    id: 'reports.exportReport',
    defaultMessage: 'Export Report'
  },
  reportRemoved: {
    id: 'reports.reportRemoved',
    defaultMessage: 'Report "{title}" removed.'
  },
  editReport: {
    id: 'reports.editReport',
    defaultMessage: 'Edit Report'
  },
  noData: {
    id: 'reports.noData',
    defaultMessage: 'No data for the chosen filters'
  },
  netFlow: {
    id: 'reports.netFlow',
    defaultMessage: 'Net flow'
  },
  relativeNetFlow: {
    id: 'reports.relativeNetFlow',
    defaultMessage: 'Net flow in %'
  }
})
