import { OPERATOR } from 'constants/index'
import { useCallback, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useRole } from '..'
import { handleMutationError } from 'utils'

export const useClientIntegrations = (options) => {
  const { role } = useRole()
  const opts = {
    enabled: true,
    ...options
  }
  const { fetch } = useQueryFetcher()
  const queryClient = useQueryClient()
  const queryKey = ['client-integrations']

  const invalidate = useCallback(() => queryClient.invalidateQueries({ queryKey: [queryKey[0]] }), [queryKey])

  const isEnabled = role === OPERATOR && opts.enabled

  const { data, status, error, isFetching, isLoading, refetch, remove } = useQuery({
    queryKey: queryKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        '/clients/integrations/',
        {
          method: 'GET',
          success: (res) => resolve(res),
          failure: (err) => reject(err)
        }
      )
    }),
    enabled: isEnabled,
    staleTime: 120000
  })

  const { mutateAsync: addMutation, isPending: addIsPending } = useMutation({
    mutationFn: (data) => new Promise((resolve, reject) => {
      fetch(
        '/clients/integrations/',
        {
          method: 'POST',
          success: (res) => resolve(res),
          failure: (errors) => handleMutationError(errors, reject),
          body: data
        }
      )
    }),
    onSettled: !opts.skipOnSettled ? () => queryClient.invalidateQueries({ queryKey: ['client-integrations'] }) : undefined
  })

  const { mutateAsync: updateMutation, isPending: updateIsPending } = useMutation({
    mutationFn: (data) => new Promise((resolve, reject) => {
      fetch(
        `/clients/integrations/${data.id}/`,
        {
          method: 'PATCH',
          success: (res) => resolve(res),
          failure: (errors) => handleMutationError(errors, reject),
          body: data
        }
      )
    }),
    onSettled: !opts.skipOnSettled ? () => queryClient.invalidateQueries({ queryKey: [queryKey[0]] }) : undefined
  })

  const resultData = useMemo(() => {
    return {
      items: data ? data.results : null,
      count: data ? data.count : null,
      status,
      error,
      isFetching,
      isLoading,
      refetch,
      invalidate,
      remove
    }
  }, [data, status, error, isFetching, isLoading, refetch, invalidate, remove])

  return {
    data: resultData,
    add: {
      mutateAsync: addMutation,
      isPending: addIsPending
    },
    update: {
      mutateAsync: updateMutation,
      isPending: updateIsPending
    }
  }
}
