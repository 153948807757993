import { Filter } from 'hooks/useAvailableFilters'
import { find, forEach, forOwn, intersection, isPlainObject, zipObject } from 'lodash'
import { ensureUniqueValues, getOrdering } from 'utils'

const convertColDefToBackendSortString = (colDef, replaceDict = {}) =>
`${colDef.sort === 'asc' ? '' : '-'}${replaceDict[colDef.colId] ? replaceDict[colDef.colId] : colDef.colId}`

export const getOrderingForSortModel = (sortModel = [], replaceDict = {}) =>
  sortModel.map(sm => convertColDefToBackendSortString(sm, replaceDict))

export const convertDataQueryParamsToLegacyParams = (pageSettings, params, overwrite, overwriteCombineFilters, combineFilters = true, orderId = true, sortKeysReplacements = {}, defaultSorter = ['id']) => {
  const locFilters = find(params, { field: 'sales_location' })
  // FIXME: We can't safely filter for og strings right now // TODO: Why not?
  // const og1Filters = find(params, { field: 'menuline' })
  // const og2Filters = find(params, { field: 'component' })

  const cFilters = {
    // Fallback to default if an empty date range was saved
    ...(pageSettings[Filter.DATE_RANGE]?.value ? combineFilters ? { date_range: pageSettings[Filter.DATE_RANGE].value } : { date_range: pageSettings[Filter.DATE_RANGE].value.join(',') } : undefined),
    ...(locFilters
      ? { sales_locations: Array.isArray(locFilters.value) ? locFilters : [locFilters.value] }
      : undefined),
    ...overwriteCombineFilters
    // FIXME: We can't safely filter for og strings right now // TODO: Why not?
    // ...(og1Filters ? { menuline_items: og1Filters.value } : undefined),
    // ...(og2Filters ? { component_items: og2Filters.value } : undefined)
  }

  let ordering
  if (pageSettings.sortModel) {
    const sortModel = getOrderingForSortModel(pageSettings.sortModel, sortKeysReplacements)
    forEach(defaultSorter, s => {
      if (intersection([`-${s}`, s], sortModel).length === 0) sortModel.push(s)
    })
    ordering = sortModel.join(',')
  } else {
    ordering = orderId
      ? getOrdering(pageSettings.sorter, sortKeysReplacements) ? `${getOrdering(pageSettings.sorter, sortKeysReplacements)},category,sales_location` : 'category,sales_location'
      : `${getOrdering(pageSettings.sorter, sortKeysReplacements)},category,sales_location` || 'category,sales_location'
  }

  return {
    ordering,
    page: pageSettings.pagination && pageSettings.pagination.current ? pageSettings.pagination.current : 1,
    page_size: pageSettings.pagination && pageSettings.pagination.pageSize ? pageSettings.pagination.pageSize : 100,
    ...(pageSettings.search && pageSettings.search.trim() !== '' ? { search: pageSettings.search } : undefined),
    ...(pageSettings.wasteCategory && pageSettings.wasteCategory.length > 0 ? { categories: pageSettings.wasteCategory.join(',') } : undefined),
    ...(combineFilters ? { combine_filters: JSON.stringify(cFilters) } : cFilters),
    ...overwrite
  }
}

// This function converts a deeply grouped data query result to a flat list of objects
export const convertNestedDataQueryResultToFlatObjects = (data, fieldNames) => {
  const result = []

  const uniqueFieldNames = ensureUniqueValues(fieldNames)

  function recursiveFlatten (obj, keys = []) {
    if (isPlainObject(obj)) {
      forOwn(obj, (value, key) => {
        recursiveFlatten(value, [...keys, key])
      })
    } else if (Array.isArray(obj)) {
      const lastField = uniqueFieldNames[keys.length]
      if (Array.isArray(lastField)) {
        // Assign array values into corresponding field names from the array

        const clonedObj = [...obj]
        if (lastField.length > clonedObj.length) {
          // the same value has to be put in all fields
          // duplicate the last value of the obj array until it's length matches the lastField array
          clonedObj.push(...Array(lastField.length - clonedObj.length).fill(clonedObj[clonedObj.length - 1]))
        }

        const mappedFields = zipObject(lastField, clonedObj)
        result.push({ ...zipObject(uniqueFieldNames.slice(0, keys.length), keys), ...mappedFields })
      } else {
        // Assign the entire array as a value for the last field name
        result.push(zipObject(uniqueFieldNames, [...keys, obj]))
      }
    } else {
      result.push(zipObject(uniqueFieldNames, [...keys, obj]))
    }
  }

  recursiveFlatten(data)

  // extend with an _id by joining all fieldNames which are not an array (grouping fields)
  result.forEach(r => {
    r._id = uniqueFieldNames.filter(f => !Array.isArray(f)).map(f => r[f]).join('|')
  })

  return result
}

/**
 * Converts flat data to AG Grid pivot format
 * @param {Array} flatData - Array of flat data objects
 * @param {Object} request - AG Grid request object containing pivot and value configuration
 * @returns {Object} - Object containing rowData and pivotResultFields
 */
export const convertFlatDataToAgGridPivot = (flatData, request) => {
  const { pivotCols, valueCols, rowGroupCols } = request

  // If no pivot columns, return original data with empty pivot fields
  if (!pivotCols?.length || !valueCols?.length) {
    return {
      rowData: flatData,
      pivotResultFields: []
    }
  }

  // Set to collect unique pivot result fields
  const pivotResultFieldsSet = new Set()

  // Get the row grouping fields
  const rowGroupFields = rowGroupCols?.map(col => col.field) || []

  // Group data by row group fields
  const groupedData = flatData.reduce((acc, row) => {
    // Create a unique key for this group based on row group fields
    let groupKey

    if (rowGroupFields.length > 0) {
      // If we have explicit row group fields, use those for the key
      groupKey = rowGroupFields
        .map(field => row[field])
        .filter(Boolean)
        .join('|')
    } else {
      // Otherwise use all non-pivot, non-value fields
      const baseFields = Object.keys(row).filter(key =>
        key !== '_id' &&
        !pivotCols.some(pc => pc.field === key) &&
        !valueCols.some(vc => vc.field === key)
      )

      groupKey = baseFields
        .map(field => row[field])
        .filter(Boolean)
        .join('|')
    }

    if (!acc[groupKey]) {
      // Initialize group with all fields that should be preserved
      // This includes row group fields and any other fields that are not pivot or value fields
      const fieldsToPreserve = Object.keys(row).filter(key =>
        key !== '_id' &&
        (rowGroupFields.includes(key) ||
         (!pivotCols.some(pc => pc.field === key) &&
          !valueCols.some(vc => vc.field === key)))
      )

      acc[groupKey] = fieldsToPreserve.reduce((obj, field) => {
        obj[field] = row[field]
        return obj
      }, {})

      // Add a new _id
      acc[groupKey]._id = groupKey
    }

    // For each value column, create pivoted column names and add values
    valueCols.forEach(valueCol => {
      const pivotValue = pivotCols.map(pc => row[pc.field]).join('|')
      const pivotedColName = `${pivotValue}|${valueCol.field}`
      acc[groupKey][pivotedColName] = row[valueCol.field]

      // Add to our set of pivot result fields
      pivotResultFieldsSet.add(pivotedColName)

      // Check if there's a corresponding _compare field and add it as well
      const compareField = `${valueCol.field}_compare`
      if (row.hasOwnProperty(compareField)) {
        const pivotedCompareColName = `${pivotValue}|${compareField}`
        acc[groupKey][pivotedCompareColName] = row[compareField]
      }
    })

    return acc
  }, {})

  // Calculate summary values for each row
  const rowDataWithSummaries = Object.values(groupedData).map(row => {
    // Track metrics and their totals
    const metricTotals = {}

    // Find all pivoted columns and group by metric
    Object.keys(row).forEach(key => {
      // Only process keys that contain a pipe symbol
      if (key.includes('|')) {
        // Get the metric name (last part after pipe)
        const parts = key.split('|')
        const metric = parts[parts.length - 1]

        // Skip _compare metrics for summaries - they'll be handled separately
        if (metric.endsWith('_compare')) {
          return
        }

        // Initialize the metric total if not exists
        if (!metricTotals[metric]) {
          metricTotals[metric] = 0
        }

        // Add the value to the total for this metric
        metricTotals[metric] += parseFloat(row[key] || 0)

        // Check if there's a corresponding _compare metric and calculate its total separately
        const compareMetric = `${metric}_compare`
        const compareKeys = Object.keys(row).filter(k =>
          k.includes('|') && k.endsWith(compareMetric)
        )

        if (compareKeys.length > 0 && !metricTotals[compareMetric]) {
          metricTotals[compareMetric] = 0

          // Sum up all compare values
          compareKeys.forEach(compareKey => {
            metricTotals[compareMetric] += parseFloat(row[compareKey] || 0)
          })
        }
      }
    })

    // Add all metric summaries to the row
    return {
      ...row,
      ...metricTotals
    }
  })

  return {
    rowData: rowDataWithSummaries,
    pivotResultFields: Array.from(pivotResultFieldsSet).sort()
  }
}
