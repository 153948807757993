import { CUSTOM_RANGE, DFNS_WEEK_FORMAT } from 'constants/index'
import { endOfWeek, parse } from 'date-fns'

import { filter } from 'lodash'
import { useFoodwasteCategories } from './queries/useFoodwasteCategories'
import { useLocations, usePermissions, useTags } from './'
import { useIntl } from 'react-intl'
import globalMessages from 'components/globalMessages'
import { FIELD_FILTER_MAP } from 'components/Report/constants'
import { Filter } from './useAvailableFilters'
import { formatISODate } from 'utils/datetime'
import { useDashboardStore } from './store/useDashboardStore'
import { getNameWithParantheses } from 'utils'
import { useDashboardManagement } from './queries/useDashboardManagement'
import { useCustomerSettings } from './useCustomerSettings'
import { getFilters, VIEWER } from 'components/Dashboard/utils'

// TODO: Rename when Dashboard 1 is gone
export const useDashboardRefinerNew = (options = {}) => {
  const intl = useIntl()
  const opts = {
    enabled: true,
    groupBy: options.groupBy || null,
    ...options
  }

  const permissions = usePermissions()
  const customerSettings = useCustomerSettings()
  const { data: locations } = useLocations()
  const { data: foodwasteCategories } = useFoodwasteCategories({ enabled: permissions.foodwaste })
  const { tags: itemTags } = useTags({ type: 'item', remove_orphans: true })
  const cachedItems = useDashboardStore((state) => state.cachedItems)
  const currentDashboard = useDashboardStore((state) => state.currentDashboard)
  const setOverriddenFilters = useDashboardStore(state => state.setOverriddenFilters)
  const { update } = useDashboardManagement({ enabled: false })
  if (!opts.enabled) return { ready: false }

  const set = (obj) => {
    if (currentDashboard.ownAccessType === VIEWER) {
      setOverriddenFilters(currentDashboard.id, { ...getFilters(currentDashboard, true), ...obj })
      return
    }

    update.mutate({
      id: currentDashboard.id,
      filters: {
        ...currentDashboard.filters,
        ...obj
      }
    })
  }

  const ready = locations != null && (!permissions.foodwaste || (permissions.foodwaste && foodwasteCategories != null))

  const handleRangeRefine = (entry) => {
    const byHour = entry.date.includes('T')
    const byWeek = entry.date.includes('W')
    const byDay = !byHour && !byWeek

    // clicking on hourly view is not supported
    if (byDay) {
      set({
        dateRange: {
          option: CUSTOM_RANGE,
          value: [entry.date, entry.date]
        }
      })
    } else if (byWeek) {
      const weekBegin = parse(entry.date, DFNS_WEEK_FORMAT, new Date())
      const weekEnd = endOfWeek(weekBegin, { weekStartsOn: 1 })
      set({
        dateRange: {
          option: CUSTOM_RANGE,
          value: [formatISODate(weekBegin), formatISODate(weekEnd)]
        }
      })
    }
  }

  const handleRefine = (entry) => {
    if (!ready) {
      console.warn('Refiner is not ready!')
      return
    }

    const filterKey = FIELD_FILTER_MAP[opts.groupBy]

    if (entry.name === intl.formatMessage(globalMessages.other) && (!entry.group || entry.group.length === 0)) {
      console.error('Tried to refine on Other, but have no group attribute.')
      return
    }

    let matches
    switch (filterKey) {
      case Filter.LOCATION:
        if (entry.group) {
          matches = filter(locations, (l) => entry.group.includes(getNameWithParantheses('location', l, customerSettings)))
          if (entry.group.length !== matches.length) {
            console.warn(`Having ${entry.group.length} entries in OTHER group, but only found ${matches.length} matches in collection. This should not happen!`)
          }
        } else {
          matches = filter(locations, l => getNameWithParantheses('location', l, customerSettings) === entry.name)
        }
        if (matches && matches.length > 0) {
          set({ [filterKey]: matches.map(i => i.id.toString()) })
        }
        break
      case Filter.FOODWASTE_CATEGORY:
        if (entry.group) {
          matches = filter(foodwasteCategories, (i) => entry.group.includes(i.name))
          if (entry.group.length !== matches.length) {
            console.warn(`Having ${entry.group.length} entries in OTHER group, but only found ${matches.length} matches in collection. This should not happen!`)
          }
        } else {
          matches = filter(foodwasteCategories, { name: entry.name })
        }
        if (matches && matches.length > 0) {
          set({ [filterKey]: matches.map(i => i.id.toString()) })
        }
        break
      case Filter.ITEM:
        // when refining from the chart, we only have the combined display name. We have to find the item id from the cached items
        if (entry.group) {
          matches = filter(cachedItems, (i) => entry.group.includes(getNameWithParantheses('item', i)))
          if (entry.group.length !== matches.length) {
            console.warn(`Having ${entry.group.length} entries in OTHER group, but only found ${matches.length} matches in collection. This should not happen!`)
          }
        } else {
          matches = filter(cachedItems, i => getNameWithParantheses('item', i) === entry.name)
        }
        if (matches && matches.length > 0) {
          set({ [filterKey]: matches.map(i => i.id.toString()) })
        }
        break
      case Filter.OFFERING_GROUP_1_NAMES:
      case Filter.OFFERING_GROUP_2_NAMES:
      case Filter.ITEM_GROUP_1:
      case Filter.ITEM_GROUP_2:
        set({ [filterKey]: entry.group ? entry.group.map(v => v.toString()) : [entry.name.toString()] })
        break
      case Filter.ITEM_TAG:
        if (entry.group) {
          console.warn('Refining on item tag group is not supported yet.', entry.group)
        } else {
          matches = filter(itemTags, (i) => i.name === entry.name)
        }
        if (matches && matches.length > 0) {
          set({ [filterKey]: matches.map(i => i.id.toString()) })
        }
        break
    }
  }

  return {
    ready,
    handleRefine,
    handleRangeRefine
  }
}
