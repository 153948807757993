import React, { createContext, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import message from 'services/message'
import { useQueryFetcher } from 'hooks'
import notifyMessages from 'components/notifyMessages'
import { handleMutationError } from 'utils'
import { findIndex, omit } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useNotificationStore } from 'hooks/store/useNotificationStore'

const ScriptContext = createContext()

export const ScriptProvider = ({ children }) => {
  const intl = useIntl()
  const { fetch, token } = useQueryFetcher()
  const addNotification = useNotificationStore(state => state.addNotification)
  const [tasks, setTasks] = useState({})

  const { mutateAsync: invokeMutation } = useMutation({
    mutationFn: (data) => new Promise((resolve, reject) => {
      fetch(
        '/scripts/invoke/',
        {
          method: 'POST',
          token,
          success: (res) => resolve(res),
          failure: (errors) => handleMutationError(errors, reject),
          body: {
            response_method: 'websocket',
            ...data
          }
        }
      )
    })
  })

  const setScriptTask = (data) => {
    const t = [...(tasks[data.name] ? tasks[data.name] : [])]

    const idx = findIndex(t, { id: data.id })
    if (idx == -1) {
      setTasks({
        ...tasks,
        [data.name]: [
          ...t,
          data
        ]
      })
    } else {
      t[idx] = data
      setTasks({
        ...tasks,
        [data.name]: t
      })
    }
  }

  const invoke = (scriptName, args) => {
    invokeMutation({
      name: scriptName,
      args: args
    }).then((res) => {
      setScriptTask({
        name: scriptName,
        status: 'running',
        result: null,
        id: res.id
      })
    }).catch((err) => {
      message.error(intl.formatMessage(notifyMessages.scriptFailure, { scriptName }))
      setScriptTask({
        name: scriptName,
        status: 'failure',
        result: Array.isArray(err) ? err : (err.detail || err.message),
        id: uuidv4()
      })
    })
  }

  const handleResponse = (res) => {
    addNotification(
      res.status === 'success' ? 'success' : 'error',
      'scripts.response',
      res
    )
    if (res.status === 'success') {
      message.success(intl.formatMessage(notifyMessages.scriptCompleted, { scriptName: res.script_name }))
    } else {
      message.error(intl.formatMessage(notifyMessages.scriptFailure, { scriptName: res.script_name }))
    }
    let result
    try {
      result = JSON.parse(res.payload)
    } catch (e) {
      result = res.payload
    }
    setScriptTask({
      ...omit(res, ['payload', 'script_name', 'code']),
      name: res.script_name,
      result
    })
  }

  const value = {
    invoke,
    tasks,
    handleResponse
  }

  return <ScriptContext.Provider value={value}>{children}</ScriptContext.Provider>
}

export const useScriptContext = () => useContext(ScriptContext)
