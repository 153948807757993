import { defineMessages } from 'react-intl'

export default defineMessages({
  importingSuccess: {
    id: 'importData.importingSuccess',
    defaultMessage: 'Imported files is being processed...'
  },
  importingError: {
    id: 'importData.importingError',
    defaultMessage: 'Importing file failed. Please try again.'
  },
  restoringError: {
    id: 'importData.restoringError',
    defaultMessage: 'Restoring file failed. Please try again.'
  },
  removingError: {
    id: 'importData.removingError',
    defaultMessage: 'Removing file failed. Please try again.'
  },
  readyToImport: {
    id: 'notify.readyToImport',
    defaultMessage: 'Ready to import'
  },
  parseError: {
    id: 'importData.parseError',
    defaultMessage: 'Reading file failed. Please select a valid file.'
  },
  formatError: {
    id: 'importData.formatError',
    defaultMessage: 'The file is not in the correct format. Please select a file that was created using the Export feature.'
  },
  importing: {
    id: 'notify.importing',
    defaultMessage: 'Importing...'
  },
  imported: {
    id: 'notify.imported',
    defaultMessage: 'Imported'
  },
  queued: {
    id: 'notify.queued',
    defaultMessage: 'Queued'
  },
  failed: {
    id: 'notify.failed',
    defaultMessage: 'Failed'
  },
  empty: {
    id: 'notify.empty',
    defaultMessage: 'Empty File'
  },
  wrongFileDates: {
    id: 'notify.wrongFileDates',
    defaultMessage:
      'The summary file must be exported with the same start- and enddate.'
  },
  wrongPosLocation: {
    id: 'notify.wrongPosLocation',
    defaultMessage: 'File needs to be from a selected point of sale.'
  },
  fileNotSupported: {
    id: 'notify.fileNotSupported',
    defaultMessage: 'The uploaded file is not supported.'
  },
  wrongCsvFile: {
    id: 'notify.wrongCsvFile',
    defaultMessage: 'Wrong POS file type. Required CSV file type.'
  },
  wrongExcelFile: {
    id: 'notify.wrongExcelFile',
    defaultMessage: 'Wrong POS file type. Required Excel file type.'
  },
  cannotReadFile: {
    id: 'notify.cannotReadFile',
    defaultMessage: 'Can not read POS file.'
  },
  noMenulineMapping: {
    id: 'notify.noMenulineMapping',
    defaultMessage: 'You need to map offering groups'
  },
  chartError: {
    id: 'notify.chartError',
    defaultMessage: 'Error fetching chart data: [{identifier}|{type}] {message}'
  },
  fetchError: {
    id: 'notify.fetchError',
    defaultMessage: 'Error fetching data: [{type}] {message}'
  },
  mutateError: {
    id: 'notify.mutateError',
    defaultMessage: 'Error performing request: {message}'
  },
  scriptCompleted: {
    id: 'notify.scriptCompleted',
    defaultMessage: 'The script run {scriptName} has completed. See the script overview for the result.'
  },
  scriptFailure: {
    id: 'notify.scriptFailure',
    defaultMessage: 'The script run {scriptName} has failed. See the script overview for the result.'
  },
  demandRefreshed: {
    id: 'notify.demandRefreshed',
    defaultMessage: 'Sales potential estimation calculation has been updated.'
  }
})
